import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import {GridFormatters} from 'ui-core';

var OrdersGridHelper = {
	rowHeight: 110,
	headerRowHeight: 35
};

var TransactionStatusLookup = {};
for (var t in CONSTANTS.TransactionStatus) {
	TransactionStatusLookup[CONSTANTS.TransactionStatus[t]] = t;
}

var TransactionColor = {
	0: 'orange',
	1: 'green',
	2: 'blueviolet',
	3: 'red'
};

OrdersGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'id@80',
			formatter: GridFormatters.link('/order/%s'),
		},
		{
			key: 'createdAt@100s',
			name: 'Created',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'status@90s',
			formatter: statusFormatter
		},
		'email@180s',
		{
			key: 'shipping',
			formatter: shippingFormatter
		},
		{
			key: 'items',
			formatter: itemsFormatter
		},
		{
			key: 'invoice',
			formatter: invoiceFormatter
		},
		{
			key: 'pos',
			formatter: posFormatter
		},
		{
			key: 'marketplace@50',
			name: 'MP',
			formatter: marketplaceFormatter
		}
	];
};

var statusLookup = GridFormatters.lookup(CONSTANTS.OrderStatus, true);

function statusFormatter(data) {
	var statusVal = statusLookup(data);
	if (data.row?.statusDesc) {
		return (
			<div>
				<div>{statusVal}</div>
				<div style={{fontSize: '0.7em'}}>{data.row?.statusDesc}</div>
			</div>
		);
	}

	return statusVal;
}

function shippingFormatter({row}) {
	var res = null;
	if (typeof row.shipping === 'object' && row.shipping && row.shipping.address) {
		res = (
			<div style={{fontSize: '0.9em'}}>
				<div>{row.shipping.address.name}</div>
				<div>{row.shipping.address.address1}</div>
				{row.shipping.address.address2 ? <div>{row.shipping.address.address2}</div> : ''}
				<div>{row.shipping.address.city}</div>
				<div>{row.shipping.address.state} {row.shipping.address.zip}</div>
			</div>
		);
	}

	return res;
}

function invoiceFormatter({row}) {
	var res = null;
	if (typeof row.invoice === 'object' && row.invoice && row.invoice.amount) {
		res =
			<div style={{fontSize: '0.9em'}}>
				<div><span>Status: </span><span style={{color: TransactionColor[row.invoice.status] || 'black'}}>{TransactionStatusLookup[row.invoice.status] || ''}</span></div>
				<div>Amount: {row.invoice.amount}</div>
				{row.invoice.discount > 0 && <div>Discount: {row.invoice.discount}</div>}
				{row.invoice.shipping > 0 && <div>Shipping: {row.invoice.shipping}</div>}
				{row.invoice.tax > 0 && <div>Tax: {row.invoice.tax}</div>}
				<div>Balance: {row.invoice.balance}</div>
			</div>;
	}

	return res;
}

function itemsFormatter({row}) {
	var res = null;
	var items = row.items;
	if (Array.isArray(items) && items.length > 0) {
		res = items.map((item, i) =>
			<div key={i} style={{fontSize: '0.9em'}}>
				{item.quantity} X {item.description || `sku: ${item.sku}`}
			</div>
		);
	}

	return res;
}

function marketplaceFormatter({row}) {
	var mp = row.marketplace;
	if (!mp || !mp.id) {return '';}
	return (
		<div>
			<div>{mp.id}</div>
			{mp.orderId && <div>order: {mp.orderId}</div>}
			{mp.batchId && <div>batch: {mp.batchId}</div>}
		</div>
	);
}

function posFormatter({row}) {
	var pos = row.pos;
	if (!pos || !pos.id) {return '';}
	return (
		<div>{pos.name}</div>
	);
}

export default OrdersGridHelper;

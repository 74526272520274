import CONSTANTS from '../../lib/constants.js';
import { compileSchema, toEnumSchema } from 'ui-core';

/** @type {import('json-schema').JSONSchema7} */
let schema = {
	type: 'object',
	required: ['channelId'],
	properties: {
		channelId: toEnumSchema(CONSTANTS.PushNotificationChannels),
		title: { type: 'string' },
		message: { type: 'string' },
		image: { $ref: '#/definitions/imageUrl' }
	},
	dependencies: {
		channelId: {
			oneOf: [
				{
					required: ['experienceId'],
					properties: {
						channelId: { enum: [CONSTANTS.PushNotificationChannels.Live] },
						experienceId: {$ref: '#/definitions/MongoObjectID'}
					}
				},
				{
					properties: {
						channelId: { enum: [CONSTANTS.PushNotificationChannels.FeaturedExperiences] },
						experienceId: {$ref: '#/definitions/MongoObjectID'},
						expFeedId: {$ref: '#/definitions/MongoObjectID'}
					},
					if: {
						properties: {
							experienceId: {const: ''}
						}
					},
					then: {
						required: ['expFeedId'],
						properties: {
							expFeedId: {$ref: '#/definitions/MongoObjectID'}
						}
					}
				},
				{
					properties: {
						channelId: { enum: [CONSTANTS.PushNotificationChannels.Other] }
					}
				}
			]
		}
	}
};

let uiSchema = {
};

schema = compileSchema(schema);
export { schema, uiSchema };

var uiSchema = {
	id: {
		'ui:disabled': true
	},
	name: {
		'ui:placeholder': 'The PaintBar Newton'
	},
	contact: {
		'ui:title': 'Contact',
		name: {
			'ui:placeholder': 'Jenny Jen'
		}
	},
	location: {
		'ui:title': 'Location',
		address: {
			'ui:title': 'Address'
		}
	},
	inventory: {
		'ui:title': 'Inventory'
	}
};

export default uiSchema;

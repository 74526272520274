import React from 'react';
import { config, auth } from 'client-services';
import { AppOptionsProvider, Scaffold } from 'app-center-common';
import getRoutes from './routes.jsx';
import * as CustomMenuItems from '../../components/custom-menu-items';

class Main extends React.Component {
	constructor(props) {
		super(props);

		this.routes = getRoutes();
		this.menuItems = config.get('app:menu');
		this.menuItems.push({ name: 'divider' });
		this.menuItems.push({ name: 'settings', text: 'Settings', component: 'SettingsMenuItem', icon: 'settings' });
		this.menuItems.push({ name: 'logout', text: 'Logout', component: 'LogoutMenuItem', icon: 'power_settings_new' });
	}

	render() {
		return (
			<AppOptionsProvider options={{
				customMenuItems: CustomMenuItems,
				authService: auth
			}}>
				<Scaffold
					title="Cupixel BackOffice"
					menuItems={this.menuItems}
					contentRoutes={this.routes.contentRoutes}
					rootRoutes={this.routes.rootRoutes}
					getAvatar={() => (auth.session()?.user?.avatar ?? '')}
				/>
			</AppOptionsProvider>
		);
	}
}

export default Main;

import CONSTANTS from '../../../lib/constants.js';
import { compileSchema, toEnumSchema } from 'ui-core';

const UTM_REGEX = '^[a-zA-Z_+-\.]+$';

/** @type {import('json-schema').JSONSchema7} */
let schema = {
	type: 'object',
	properties: {
		type: {type: 'string', readOnly: true},
		utmInfo: {
			type: 'object',
			required: ['utm_source', 'utm_channel'],
			properties: {
				utm_source: {type: 'string', pattern: UTM_REGEX, default: 'support'},
				utm_channel: {type: 'string', pattern: UTM_REGEX, default: 'support'},
				utm_campaign: {type: 'string', pattern: UTM_REGEX},
				utm_marketplace: {type: 'string', pattern: UTM_REGEX},
			}
		},
		entitlement: {
			type: 'object',
			required: ['type'],
			properties: {
				type: { type: 'string', readOnly: true, default: CONSTANTS.PromoEntitlementType.SubscriptionOffering },
				params: {
					type: 'object',
					properties: {
						kind: toEnumSchema(CONSTANTS.PromoSubscriptionOfferingKind, CONSTANTS.PromoSubscriptionOfferingKind.BoxOwners)
					}
				}
			}
		},
	}
};

let uiSchema = {};

schema = compileSchema(schema);
export { schema, uiSchema };

.user-spotlight-page {
	.section {
		border: 1px solid #e0e0e0;
		margin-bottom: 30px;

		.header {
			font-size: 1.5em;
			background-color: #f0f0f0;
    		padding: 5px;
		}

		.content {
			padding: 10px;
			box-sizing: border-box;

			&.user-info {
				font-size: 0.9em;

				.data-row {
					display: flex;
    				flex-direction: row;
					flex-wrap: wrap;
    				border-bottom: 1px solid #e0e0e0;

					.data-prop {
						width: 200px;
						padding: 10px 0;
						margin-right: 10px;

						& > .p-header {
							font-weight: 500;
							color: #6f7480
						}

						& > .p-value {
							font-weight: 200;
							text-overflow: ellipsis;
    						overflow: hidden;
    						white-space: pre;
							color: #5a5e68;
						}

						& > .country {
							display: flex;
							align-items: center;

							& > img {
								margin-right: 5px;
							}
						}
					}
				}

				.data-row:last-child {
					border: none;
					.data-prop {
						padding-bottom: 0;
					}
				}
			}
		}
	}
}
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { messageBox, Grid } from 'ui-core';
import { BasePage } from 'app-center-common';
import { logger, config } from 'client-services';

class ReportPage extends React.Component {
	constructor(props) {
		super(props);

		this.onShowAllUsersConfigChanged = this.onShowAllUsersConfigChanged.bind(this);
		var showAllUsers = config.get('data:showAllUsers', this.onShowAllUsersConfigChanged);

		this.state = {
			total: 0,
			showAllUsers: showAllUsers,
			search: this.props.searchParams.get('q') || '',
			selectedItem: null
		};

		this.grid = null;

		this.loadMore = this.loadMore.bind(this);
		this.searchChanged = this.searchChanged.bind(this);
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.onSort = this.onSort.bind(this);
	}

	reset() {
		this.setState({ total: 0, selectedItem: null }, () => this.grid?.reset());
	}

	searchChanged(search) {
		this.setState({search}, () => this.grid.reset());
	}

	onShowAllUsersConfigChanged(cnl, showAllUsers) {
		logger.info('Got a change to showAllUsers config, value:', showAllUsers);
		this.setState({showAllUsers}, () => this.grid.reset());
	}

	onSort() {
		this.grid.reset();
	}

	onSelectionChange(selection) {
		var selectedItem = null;
		if (selection.length === 1) {
			selectedItem = selection[0] || null;
		}

		this.setState({selectedItem});
	}

	async loadMore(count, skip, sort) {
		try {
			let res = await this.props.loadMore(count, skip, sort, this.state.search, this.state.showAllUsers);
			if (res.total >= 0) {
				this.setState({ total: res.total });
			}
			return res.rows;
		} catch (ex) {
			logger.error('Error fetching data:', ex);
			messageBox('Error fetching report', ex.message);
			return [];
		};
	}

	componentWillUnmount() {
		config.unwatch('data:showAllUsers', this.onShowAllUsersConfigChanged);
	}

	render() {
		let { columns, keyCol, title, gridProps, renderGridToolbar, ...rest } = this.props;
		let { search, total, selectedItem } = this.state;
		return (
			<BasePage
				title={title}
				subTitle={total ? `(${total})` : ''}
				search={search}
				onSearch={this.searchChanged}
				{...rest}
			>
				<Grid
					ref={g => {this.grid = g;}}
					columns={columns}
					keyCol={keyCol}
					pageSize={50}
					selectOnClick={true}
					toolbarItems={renderGridToolbar(selectedItem)}
					onLoadMore={this.loadMore}
					onSort={this.onSort}
					onSelectionChange={this.onSelectionChange}
					{...gridProps}
				/>
			</BasePage>
		);
	}
}

ReportPage.propTypes = {
	searchParams: PropTypes.object,
	loadMore: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
	keyCol: PropTypes.string,
	title: PropTypes.string,
	gridProps: PropTypes.object,
	renderGridToolbar: PropTypes.func
};

ReportPage.defaultProps = {
	keyCol: 'id',
	title: '',
	gridProps: {},
	renderGridToolbar: () => []
};

function WrappedReportPage(props, ref) {
	let [searchParams] = useSearchParams();
	return <ReportPage ref={ref} searchParams={searchParams} {...props} />;
}

// let WrappedReportPage = React.forwardRef(function WrappedReportPage(props, ref) {
// 	let [searchParams] = useSearchParams();
// 	return <ReportPage ref={ref} searchParams={searchParams} {...props} />;
// });

// export default WrappedReportPage;
export default React.forwardRef(WrappedReportPage);

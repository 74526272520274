import { GridFormatters } from 'ui-core';

var BuyersGridHelper = {
	rowHeight: 45,
	headerRowHeight: 35
};

BuyersGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'email@120',
			formatter: GridFormatters.link('/orders?q=email:%s'),
		},
		{
			key: 'lastPurchase@180',
			formatter: GridFormatters.datetime()
		},
		{
			key: 'firstOrder@80-r',
			name: 'First',
			formatter: GridFormatters.checkbox()
		},
		{
			key: 'totalOrders@80',
			name: 'Orders'
		},
		'name@120',
		{
			key: 'city@200',
			name: 'Location',
			formatter: locationFormatter
		},
		{
			key: 'products',
			formatter: GridFormatters.array()
		},
		{
			key: 'marketplaces',
			name: 'MP',
			formatter: GridFormatters.array()
		}
	];
};

function locationFormatter({row}) {
	var city = row.city;
	var state = row.state;

	return `${city}${state ? `, ${state}` : ''}`;
}

export default BuyersGridHelper;

import { compileSchema } from 'ui-core';
import { utils } from 'client-services';

/** @type {import('json-schema').JSONSchema7} */
var schema = {
	type: 'object',
	required: ['code', 'discountAmount'],
	properties: {
		code: { type: 'string', pattern: '^[0-9a-zA-Z-_]{3,}$'},
		enabled: {type: 'boolean'},
		description: { type: 'string'},
		receiptLine: { type: 'string', description: 'e.g Christmas Sale'},
		discountType: { type: 'number', oneOf: [{ const: 0, title: 'Percent'}, {const: 1, title: 'Fixed'}]},
		discountScope: { type: 'number', oneOf: [{ const: 0, title: 'Cart' }, { const: 1, title: 'Item' }] },
		discountAmount: {type: 'integer', minimum: 0 },
		perProduct: { type: 'string', format: 'json', description: 'Override discount amount per product (json)'},
		usageLimit: { type: 'integer', minimum: 0 },
		startDate: { type: 'string', format: 'date-time', title: `Start Date (${utils.clientTimezoneString()})` },
		endDate: { type: 'string', format: 'date-time', title: `End Date (${utils.clientTimezoneString()})` }
	}
};

var uiSchema = {
	perProduct: {
		'ui:field': 'json'
	},
	description: {
		'ui:widget': 'textarea',
		'ui:options': {
			rows: 2
		}
	}
};

schema = compileSchema(schema);
export { schema, uiSchema };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { MaterialIcon, Checkbox } from 'ui-core';
import { makeStyles } from 'tss-react/mui';

import clsx from 'clsx';

function BOUserRolesEditor(props) {
	let { allRoles, activeRoles, fixedRoles, onCancel, onSave } = props;
	let [selectedRoles, setSelectedRoles] = useState(activeRoles);

	return (
		<Dialog open scroll="paper" fullWidth maxWidth="md">
			<DialogTitle>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{marginRight: 5}}>BO ROLES</span>
					<Tooltip
						title={
							<div>
								<p>Below is a list of roles, beneath each role there is a list of operations this role grants.</p>
								<p>A checkmark icon next to the role name indicates this roles is already assigned to the user.</p>
								<p>When assigning roles to a user chose the LEAST permissive roles that is possible.</p>
								<p>BE CAREFUL when assigning the `support` role to a user.</p>
							</div>
						}
						slotProps={{
							tooltip: {
								style: {
									backgroundColor: '#e3e2e2',
									color: '#646464',
									fontSize: '1em',
									fontWeight: 'normal',
								}
							},
						}}
					>
						<MaterialIcon name="help"/>
					</Tooltip>
				</div>
			</DialogTitle>
			<DialogContent>
				{Object.keys(allRoles).map(role =>
					<Role
						key={role}
						name={role}
						selected={selectedRoles.includes(role)}
						operations={Object.keys(allRoles[role]?.operations ?? {})}
						onChange={selected => {
							if (selected) {
								selectedRoles.push(role);
							} else {
								selectedRoles = selectedRoles.filter(r => r !== role);
							}

							setSelectedRoles([...selectedRoles]);
						}}
					/>
				)}
				{ fixedRoles.map(role => <div key={role} ><Checkbox label={role} disabled checked /></div>) }
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={() => onCancel?.()}>Cancel</Button>
				<Button color="secondary" onClick={() => onSave?.(selectedRoles)}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

BOUserRolesEditor.propTypes = {
	allRoles: PropTypes.object,
	activeRoles: PropTypes.arrayOf(PropTypes.string),
	fixedRoles: PropTypes.arrayOf(PropTypes.string),
	onSave: PropTypes.func,
	onCancel: PropTypes.func
};

let useClasses = makeStyles()(theme => ({
	root: {
		marginBottom: 15,

		'.role-title': {
			display: 'flex',
			alignItems: 'center',
			fontWeight: 'bold',

			'&.selected > i': {
				marginLeft: 5,
				fontSize: '0.9em',
			}
		},
		'.ops': {
			display: 'flex',
			flexWrap: 'wrap',
			paddingLeft: 10,
			marginTop: 5,
			rowGap: 5,

			'.op': {
				padding: '0 5px',
				fontSize: '0.9em',
				border: '1px solid lightgray',
				borderRadius: 5,
				marginRight: 5
			}
		}
	}
}));

function Role(props) {
	let { classes } = useClasses();
	let { name, selected, operations = [], onChange } = props;

	return (
		<div className={classes.root}>
			<div className={clsx('role-title', { selected })}>
				<Checkbox label={name} checked={selected} onCheck={(ev, checked) => onChange?.(checked)} />
			</div>
			{ operations.length > 0 && <div className="ops">{operations.sort().map(op => <span key={op} className="op">{op}</span>)}</div>}
		</div>
	);
}

Role.propTypes = {
	name: PropTypes.string,
	selected: PropTypes.bool,
	operations: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};

export default BOUserRolesEditor;

import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { MenuItem, Select } from '@mui/material';
import { messageBox } from 'ui-core';
import MembershipGridHelper from './membership-grid-helpers.jsx';
import ReportPage from '../report-page/report-page.jsx';
import { api, logger } from 'client-services';

let SEARCH_FIELDS = {
	email: {},
	plan: {enum: ['Monthly', 'Annual', 'AnnualPlus', 'AnnualNR']},
	owner: {format: 'uuid', description: 'user id'},
	provider: {enum: ['Apple', 'Google', 'Cupixel', 'System']},
	source: {title: 'Promo Source', enum: ['box', 'partner', 'support']},
	channel: {title: 'Promo Channel', enum: ['CupixelAcrylicSet', 'CupixelGreetingCardSet', 'SketchBox', 'annual', 'CupixelOldSketch', 'CupixelOldGreetingCard']},
	marketplace: {title: 'Promo Marketplace', enum: ['CPX', 'JAS', 'SketchBox']},
	promo: { title: 'Promo Code' },
	originalTransactionId: { title: 'Transaction Id', description: 'The original transaction id from Apple/Google' },
	id: {$ref: '#/definitions/MongoObjectID', description: 'membership id'},
};

class MembershipsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			membershipState: 'ActivePaying'
		};

		this.reportPage = null;
		this.columns = MembershipGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
		this.onSelectedStateChange = this.onSelectedStateChange.bind(this);
	}

	async loadMore(count, skip, sort, search, showAllUsers) {
		let state = this.state.membershipState;
		let extra = '';
		if (state === 'ActivePaying') {
			state = 'Active';
			extra = ' +isTrial:false';
		}

		var params = {
			count,
			skip,
			sort,
			q: search + (state === 'All' ? '' : ` +state:${state}`) + extra + (showAllUsers ? '' : ' +ug:1 +ug:2')
		};

		logger.trace('Fetching memberships, params:', params);
		let res = await api.membership.getMembershipReport(params).send();
		logger.trace('Got memberships:', res);
		return { rows: res.memberships, total: res.total };
	}

	onSelectedStateChange(e) {
		this.setState({ membershipState: e.target.value }, () => this.reportPage?.reset());
	}

	async onUnsubscribe(membership) {
		if (!membership) { return; }

		let msg = <div>User is subscribed with {membership.provider}.<br />Are you sure you want to remove his membership?<br />NOTE: This will NOT cancel the user subscription on Apple/Google !</div>;
		let res = await messageBox('WARNING', msg, [messageBox.Buttons.Cancel, 'Remove Membership']).promise;
		if (res === messageBox.Buttons.Cancel) {
			return;
		}

		let force = true;

		var mb = messageBox('Unsubscribing user...', '', '', true);

		try {
			await api.membership.removeUserMembership({ id: membership.owner, provider: CONSTANTS.SubscriptionProviders.System, force }).send();
			mb.setTitle('User unsubscribed successfully...', false);
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error unSubscribe user:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	renderInnerToolbar() {
		return (
			<div style={{ display: 'flex', overflow: 'auto', alignItems: 'center' }}>
				State: <Select variant="standard" value={this.state.membershipState} onChange={this.onSelectedStateChange} style={{marginLeft: 10}}>
					<MenuItem value="All">All</MenuItem>
					<MenuItem value="ActivePaying">Active (Paying)</MenuItem>
					<MenuItem value="Active">Active</MenuItem>
					<MenuItem value="Canceled">Canceled</MenuItem>
					<MenuItem value="Expired">Expired</MenuItem>
				</Select>
			</div>
		);
	}

	renderGridToolbar(selected) {
		let items = [];
		if (selected) {
			items.push({ key: 'unsubscribe', title: 'UnSubscribe', onClick: () => this.onUnsubscribe(selected), icon: 'credit_card_off' });
		}

		return items;
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				title="Memberships"
				toolbar={this.renderInnerToolbar()}
				toolbarOpen={true}
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
				gridProps={{
					rowHeight: MembershipGridHelper.rowHeight,
					headerRowHeight: MembershipGridHelper.headerRowHeight
				}}
			/>
		);
	}
}

export default MembershipsPage;

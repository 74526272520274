import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { BasePage } from 'app-center-common';
import {messageBox, compileSchema, ToolbarIcon, Card, Form} from 'ui-core';
import {utils, auth, api, logger} from 'client-services';
import States from './us-states.js';

import './order-page.css';

var OrderStatusLookup = {};
for (var s in CONSTANTS.OrderStatus) {
	OrderStatusLookup[CONSTANTS.OrderStatus[s]] = s;
}

var TransactionStatusLookup = {};
for (var t in CONSTANTS.TransactionStatus) {
	TransactionStatusLookup[CONSTANTS.TransactionStatus[t]] = t;
}

class OrderPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: null
		};

		this.onOrderShipped = this.onOrderShipped.bind(this);
		this.onEditShipping = this.onEditShipping.bind(this);
		this.openInvoice = this.openInvoice.bind(this);
		this.onChargeOrder = this.onChargeOrder.bind(this);
		this.onDispatchOrder = this.onDispatchOrder.bind(this);
		this.onMarkOrderPaid = this.onMarkOrderPaid.bind(this);
	}

	// <editor-fold desc="// Methods {...}">
	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	async onOrderShipped() {
		if (!this.state.order) {return;}
		var updateAndNotifyButton = 'Update and email  user';
		var tracking = (this.state.order.shipping && this.state.order.shipping.tracking) || '';
		var res = await messageBox.prompt('Update tracking number', ['tracking'], [messageBox.Buttons.Cancel, 'Update', updateAndNotifyButton], [tracking]);
		if (res.result === messageBox.Buttons.Cancel) {return;}
		var sendEmail = (res.result === updateAndNotifyButton);
		tracking = res.values.tracking;

		var mb = messageBox('Updating order...', '', '', true);
		try {
			let res = await api.store.updateOrderShipment({ id: this.state.order.id, sendEmail, tracking }).send();
			mb.setTitle('Success', false);
			this.setState({order: res.order});
		} catch (e) {
			logger.error('Error updating order:', e);
			mb.setTitle('Error updating order');
			mb.setBody(e.message, false);
		}
	}

	async onDispatchOrder() {
		if (!this.state.order) {return;}
		var o = this.state.order;
		if (o.status === CONSTANTS.OrderStatus.Shipped || o.status === CONSTANTS.OrderStatus.Dispatched || (o.warehouse && o.warehouse.dispatchId)) {
			var msg =
				<div>
					<div>Looks like this order was already dispatched or shipped !</div>
					<div>Dispatching this order <span className="emphasize-warn">WILL SHIP</span> this order to the user </div>
				</div>;
			let res = await messageBox('Are you sure?', msg, [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
			if (res !== messageBox.Buttons.Yes) {
				return;
			}
		}

		if (o.invoice && (o.invoice.status !== CONSTANTS.TransactionStatus.Success || o.invoice.balance > 0)) {
			let res = await messageBox('Order not paid!', 'Looks like this order was not fully paid, dispatch anyway?', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
			if (res !== messageBox.Buttons.Yes) {
				return;
			}
		}

		var mb = messageBox('Dispatching order...', '', '', true);
		try {
			let res = await api.store.dispatchToWarehouse({id: o.id, force: true}).send();
			mb.setTitle('Success', false);
			this.setState({order: res.order});
		} catch (e) {
			logger.error('Error dispatching order:', e);
			mb.setTitle('Error dispatching order');
			mb.setBody(e.message, false);
		}
	}

	onEditShipping() {
		var states = States.asObjectArray();
		var formData = (this.state.order.shipping && this.state.order.shipping.address) || {};
		formData.name = formData.name || '';
		formData.address1 = formData.address1 || '';
		formData.address2 = formData.address2 || '';
		formData.city = formData.city || '';
		formData.state = formData.state || states[0].code;
		formData.zip = formData.zip || '';
		formData.country = formData.country || '';

		var mb;
		async function formClosed(data) {
			mb.close(50);
			if (!data) {return;}

			logger.trace('Edit order shipping, params:', data);
			mb = messageBox('Updating order...', '', '', true);
			try {
				var res = await api.store.updateOrder({id: this.state.order.id, shipping: data}).send();
				mb.setTitle('Success', false);
				this.setState({order: res.order});
			} catch (e) {
				logger.error('Error updating order:', e);
				mb.setTitle('Error updating order');
				mb.setBody(e.message, false);
			}
		}

		var schema = compileSchema({
			type: 'object',
			required: ['name', 'address1', 'city', 'state', 'zip', 'country'],
			properties: {
				name: {type: 'string', minLength: 1},
				address1: {type: 'string', minLength: 1, title: 'Address Line 1'},
				address2: { type: 'string', title: 'Address Line 2' },
				city: {type: 'string', minLength: 1},
				state: { type: 'string', oneOf: states.map(st => ({const: st.code, title: st.name}))},
				zip: {type: 'string', minLength: 1},
				country: { type: 'string', pattern: '^[a-zA-Z]{2}$' }
			}
		});

		var form = (
			<Form
				formData={formData}
				schema={schema}
				submitText="Edit Shipping"
				buttonsAlign="right"
				submit={formClosed.bind(this)}
			/>
		);

		mb = messageBox('Edit Shipping', form, []);
	}

	openInvoice() {
		var order = this.state.order;
		var coupon = this.state.order.invoice.coupon;
		var templateParams = {
			orderId: order.id,
			items: Array.isArray(order.items) ? order.items.map(i => ({description: i.description, quantity: i.quantity > 1 ? i.quantity : 0, price: i.price.toFixed(2)})) : [],
			discount: order.invoice.discount ? order.invoice.discount.toFixed(2) : 0,
			discountDesc: coupon && coupon.receiptLine ? coupon.receiptLine : 'Discount',
			shipping: order.invoice.shipping ? order.invoice.shipping.toFixed(2) : 0,
			tax: order.invoice.tax ? order.invoice.tax.toFixed(2) : 0,
			total: '0.00',
			currency: order.invoice.currency === 'CAD' ? 'C$' : '$',
			date: new Date(order.createdAt).toLocaleString('en-US', { month: 'short', year: 'numeric', day: 'numeric' })
		};

		var transactions = Array.isArray(order.invoice.transactions) ? order.invoice.transactions : [];
		var ccs = transactions.filter(t => t.paymentMethod && t.paymentMethod.method === CONSTANTS.PaymentMethods.CreditCard);
		var cc = ccs[0];
		if (cc && cc.paymentMethod) {
			templateParams.total = cc.amount.toFixed(2);
			templateParams.paymentMethod = `${cc.paymentMethod.brand} - ${cc.paymentMethod.last4}`;
			if (cc.paymentMethod.expMonth && cc.paymentMethod.expYear) {
				templateParams.paymentMethod += ` / Exp ${cc.paymentMethod.expMonth}/${cc.paymentMethod.expYear}`;
			}
		}

		var gcs = transactions.filter(t => t.paymentMethod && t.paymentMethod.method === CONSTANTS.PaymentMethods.GiftCard);
		var gc = gcs[0];
		if (gc && gc.amount) {
			templateParams.giftCard = gc.amount.toFixed(2);
			templateParams.giftCardId = gc.paymentMethod ? gc.paymentMethod.id : '';
		}

		var templateParamsStr = JSON.stringify(templateParams);
		window.open('/receipt.html?data=' + templateParamsStr);
	}

	async onChargeOrder() {
		var order = this.state.order;

		var transactions = (order.invoice && Array.isArray(order.invoice.transactions)) ? order.invoice.transactions : [];
		var deferredTransactions = transactions.filter(t => t.status === CONSTANTS.TransactionStatus.Deferred && t.paymentProviderParams && t.paymentProviderParams.customerId);
		if (deferredTransactions.length < 1) {
			messageBox('No payment details found on current order !');
			return;
		}

		var res;
		if (order.status !== CONSTANTS.OrderStatus.Pending) {
			var title = `Order status is \`${OrderStatusLookup[order.status]}\``;
			var body = <div>Usually orders to charge should be in status `Pending`.<br />Are you sure you want to charge this order?</div>;
			res = await messageBox(title, body, [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
			if (res !== messageBox.Buttons.Yes) {
				return;
			}
		}

		res = await messageBox(`Charge $${order.invoice.balance} ?`, '', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) {
			return;
		}

		var mb = messageBox('Charging...');
		try {
			res = await api.store.chargeOrder({id: this.state.order.id}).send();
			this.setState({order: res.order}, () => {
				if (res.checks) {
					var errors = ['cvc', 'address', 'zip'].filter(p => res.checks[p] === CONSTANTS.ChargeValidationsResult.Fail);
					if (errors.length > 0) {
						mb.setTitle('Charge validation warnings');
						mb.setBody(<div><div>The charge was successful but the following fields had validation warnings:</div><div>{errors.join(', ')}</div></div>);
						return;
					}
				}

				mb.close();
			});
		} catch (e) {
			logger.error('Error charging order:', e);
			mb.setTitle('Error charging order');
			mb.setBody(e.message, false);
		}
	}

	async onMarkOrderPaid() {
		var order = this.state.order;
		if (!order.invoice) { return; }
		if (order.invoice.status === CONSTANTS.TransactionStatus.Success && order.invoice.balance <= 0) {
			messageBox('Order already paid');
			return;
		}

		let res = await messageBox('Are you sure?', <span>This will <span className="emphasize-warn">NOT</span> charge the user !!</span>, [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) {
			return;
		}

		var invoice = {
			status: CONSTANTS.TransactionStatus.Success,
			balance: 0,
			transactions: [{
				status: CONSTANTS.TransactionStatus.Success,
				amount: order.invoice.balance,
				paymentProvider: CONSTANTS.Marketplace.BO,
				paymentProviderParams: (auth.session().user && auth.session().user.id) ? { customerId: auth.session().user.id } : undefined
			}]
		};

		var mb = messageBox('Updating order...', '', '', true);
		try {
			res = await api.store.updateOrder({id: this.state.order.id, invoice}).send();
			mb.setTitle('Success', false);
			this.setState({order: res.order});
		} catch (e) {
			logger.error('Error updating order:', e);
			mb.setTitle('Error updating order');
			mb.setBody(e.message, false);
		}
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	async componentDidMount() {
		var id = this.props.params.orderId;
		if (!id) {
			return;
		}

		logger.trace('Fetching order id:', id);
		try {
			var res = await api.store.getOrder({id}).send();
			logger.trace('Got order:', res);
			var order = res.order;
			if (!order) {
				logger.error('Server returned response with no order:', res);
				alert('Server returned empty response - check console');
				return;
			}

			if (order.invoice && order.invoice.coupon) {
				res = await api.store.getCoupon({id: order.invoice.coupon}).send();
				order.invoice.coupon = res.coupon;
			}

			this.setState({order});
		} catch (e) {
			logger.error('Error fetching order:', e);
			alert('Error - Check dev console');
		}
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderShipping(addr) {
		if (!addr || !addr.address) {return;}
		var address = addr.address;
		return (
			<div className="address">
				<div className="title">
					<span>Shipping</span>
					<ToolbarIcon className="edit-addr" name="edit" title="Edit" onClick={this.onEditShipping} />
				</div>
				<div>{address.name}</div>
				<div>{address.address1}</div>
				{address.address2 ? <div>{address.address2}</div> : ''}
				<div>{address.city}, {address.state} {address.zip}</div>
				<div>{address.country}</div>
			</div>
		);
	}

	renderBilling(addr) {
		if (!addr || !addr.address) {return;}
		var address = addr.address;
		return (
			<div className="address billing">
				<div className="title">Billing</div>
				<div>{address.name}</div>
				<div>{address.address1}</div>
				{address.address2 ? <div>{address.address2}</div> : ''}
				<div>{address.city}, {address.state} {address.zip}</div>
				<div>{address.country}</div>
			</div>
		);
	}

	renderItems(items) {
		return (
			<div className="table">
				<div>
					<div>SKU</div>
					<div>Desc</div>
					<div>Price</div>
					<div>Qty</div>
				</div>
				{items.map((item, i) =>
					<div key={i} className="item">
						<div>{item.sku}</div>
						<div>{item.description}</div>
						<div>{item.price}</div>
						<div>{item.quantity}</div>
					</div>
				)}
			</div>
		);
	}

	renderChargeOrder(order) {
		if (!order.invoice || order.invoice.status !== CONSTANTS.TransactionStatus.Deferred) {return '';}

		return (
			<div className="action">
				<Button variant="contained" disableElevation onClick={this.onChargeOrder}>{'Charge $' + order.invoice.balance}</Button>
			</div>
		);
	}

	renderPayOrder(order) {
		if (!order.invoice || (order.invoice.status === CONSTANTS.TransactionStatus.Success && order.invoice.balance <= 0)) {return '';}

		return (
			<div className="action">
				<Button variant="contained" disableElevation onClick={this.onMarkOrderPaid}>Mark as paid</Button>
			</div>
		);
	}

	renderInvoice(invoice) {
		if (!invoice) {return;}
		var curr = utils.toCurrencySymbol(invoice.currency);
		var transactions = Array.isArray(invoice.transactions) ? invoice.transactions : [];
		return (
			<div className="details">
				<div>
					<div>Status</div>
					<div>{TransactionStatusLookup[invoice.status]}</div>
				</div>
				<div>
					<div>Amount</div>
					<div>{curr}{invoice.amount}</div>
				</div>
				{invoice.discount > 0 && <div>
					<div>Discount</div>
					<div>{curr}{invoice.discount} (Coupon: {invoice.coupon ? invoice.coupon.code : 'None'})</div>
				</div>}
				<div>
					<div>Shipping</div>
					<div>{curr}{invoice.shipping || 0}</div>
				</div>
				<div>
					<div>Tax</div>
					<div>{curr}{invoice.tax}</div>
				</div>
				<div>
					<div>Total</div>
					<div>{curr}{invoice.total}</div>
				</div>
				<div>
					<div>Balance (remaining)</div>
					<div>{curr}{invoice.balance}</div>
				</div>
				<div>
					<div>Transactions:</div>
					<div>
						{transactions.map((tr, i) => <div key={i} className="transaction">{this.renderTransaction(tr)}</div>)}
					</div>
				</div>
			</div>
		);
	}

	renderTransaction(tr) {
		return (
			<>
				<div>Provider: {tr.paymentProvider}</div>
				{tr.paymentMethod && this.renderPaymentMethod(tr.paymentMethod)}
				<div>Amount: {tr.amount || 0}</div>
				<div>Status: {TransactionStatusLookup[tr.status]}</div>
				{tr.paymentProviderChargeId && <div>Charge: {tr.paymentProviderChargeId}</div>}
				{tr.paymentProviderParams && <div>Customer: {tr.paymentProviderParams.customerId}</div>}
			</>
		);
	}

	renderPaymentMethod(pm) {
		if (pm.method === CONSTANTS.PaymentMethods.CreditCard) {
			return <div>{pm.method}: {pm.brand} - {pm.last4} - {pm.expMonth}/{pm.expYear}</div>;
		}

		if (pm.method === CONSTANTS.PaymentMethods.GiftCard) {
			return <div>{pm.method}: {pm.id}</div>;
		}

		if (pm.method === CONSTANTS.PaymentMethods.Voucher) {
			return <div>${pm.method}: {pm.code}</div>;
		}
	}

	renderMarketplace(order) {
		var mp = order.marketplace;
		if (!mp.id) {return null;}
		return (
			<React.Fragment>
				<div className="details">
					<div>
						<div>Name</div>
						<div>{mp.id}</div>
					</div>
					<div>
						<div>Order ID</div>
						<div>{mp.orderId || ''}</div>
					</div>
					<div>
						<div>Batch ID</div>
						<div>{mp.batchId || ''}</div>
					</div>
				</div>
				{mp.order && <div>
					<Card title="Raw Order" style={{ display: 'inline-block', boxShadow: 'none' }}>
						<div className="details">
							{Object.keys(mp.order).map(k =>
								<div key={k}>
									<div>{k}</div>
									<div>{mp.order[k]}</div>
								</div>
							)}
						</div>
					</Card>
				</div>}
			</React.Fragment>
		);
	}

	renderWarehouse(order) {
		var wh = order.warehouse;
		if (!wh) {return null;}
		return (
			<div className="details">
				{wh.orderId && <div>
					<div>Order ID</div>
					<div>{wh.orderId}</div>
				</div>}
				{wh.dispatchedAt && <div>
					<div>Dispatch At</div>
					<div>{utils.toDateString(wh.dispatchedAt)} UTC</div>
				</div>}
				{wh.dispatchTarget && <div>
					<div>Dispatch Target</div>
					<div>{wh.dispatchTarget}</div>
				</div>}
				{wh.dispatchId && <div>
					<div>Dispatch ID</div>
					<div>{wh.dispatchId}</div>
				</div>}

				<div>
					<Button variant="contained" disableElevation onClick={this.onDispatchOrder}>Dispatch Order</Button>
				</div>

			</div>
		);
	}

	renderMarketingInfo(order) {
		var mi = order.marketingInfo || {};
		return (
			<div className="details">
				{Object.keys(mi).map(k =>
					<div key={k}>
						<div>{k}</div>
						<div>{mi[k]}</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		var order = this.state.order;
		if (!order) {return 'Loading...';}
		var trackingUrl = order.shipping.tracking ? `http://parcelsapp.com/en/tracking/${order.shipping.tracking}` : '';
		return (
			<BasePage className="order-page" title="Order Page">
				<div className="heading">Order: {order.id}</div>
				<div className="details">
					<div>
						<div>POS:</div>
						<div>{order.pos ? order.pos.name : ''}</div>
					</div>
					<div>
						<div>Email:</div>
						<div>{order.email}</div>
					</div>
					<div>
						<div>User ID:</div>
						<div>{order.user ? order.user.id : '[Empty]'}</div>
					</div>
					<div>
						<div>Status:</div>
						<div>{OrderStatusLookup[order.status]}</div>
					</div>
					<div>
						<div>Created (UTC):</div>
						<div>{utils.toDateString(order.createdAt)}</div>
					</div>
				</div>

				<div className="addresses">
					<div className="heading">Shipping / Billing</div>
					<div className="content">
						{this.renderShipping(order.shipping)}
						{this.renderBilling(order.billing)}
					</div>
					<div className="details">
						<div>
							<div>Carrier</div>
							<div>{order.shipping.carrier || '[Empty]'}</div>
						</div>
						<div>
							<div>Service</div>
							<div>{order.shipping.service || '[Empty]'}</div>
						</div>
						<div>
							<div>Shipped (UTC):</div>
							<div>{utils.toDateString(order.shipping.shippedAt) || '[Empty]'}</div>
						</div>
						<div>
							<div>Tracking</div>
							<div>
								{order.shipping.tracking || '[Empty]'}
								{trackingUrl && <a href={trackingUrl} target="_blank" rel="noopener noreferrer" style={{marginLeft: 15}}>Track</a> }
							</div>
						</div>
						<div>
							<Button variant="contained" disableElevation onClick={this.onOrderShipped}>Ship Order</Button>
						</div>
					</div>
				</div>

				<div className="items">
					<div className="heading">Items</div>
					<div>
						{this.renderItems(order.items || [])}
					</div>
				</div>

				<div className="invoice">
					<div className="heading">Invoice <a className="link" href="#" onClick={this.openInvoice}>[view]</a> </div>
					<div className="actions">
						{this.renderChargeOrder(order)}
						{this.renderPayOrder(order)}
					</div>
					{this.renderInvoice(order.invoice)}
				</div>

				<div className="marketplace">
					<div className="heading">Marketplace</div>
					{this.renderMarketplace(order)}
				</div>

				<div className="warehouse">
					<div className="heading">Warehouse</div>
					{this.renderWarehouse(order)}
				</div>

				<div className="marketing-info">
					<div className="heading">Marketing Info</div>
					{this.renderMarketingInfo(order)}
				</div>

			</BasePage>
		);
	}

	// </editor-fold> // Render
}

OrderPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedOrderPage(props) {
	let params = useParams();
	return <OrderPage params={params} {...props} />;
};

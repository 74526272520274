import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { Link } from 'react-router-dom';
import { GridFormatters } from 'ui-core';
import { AmplitudeLink } from '../common-grid-links.jsx';
import { utils } from 'client-services/index.js';

var MembershipGridHelper = {
	rowHeight: 60,
	headerRowHeight: 35
};

MembershipGridHelper.getColumns = function getColumns(ignoreCols = []) {
	return [
		{
			key: 'state@50',
			name: '',
			frozen: true,
			formatter: stateRenderer
		},
		{
			key: 'email@160',
			formatter: emailRenderer

		},
		{
			key: 'provider',
			formatter: providerRenderer
		},
		{
			key: 'plan@100'
		},
		{
			key: 'isTrial@70',
			name: 'Trial',
			formatter: trialRenderer
		},
		{
			key: 'renewals@85s'
		},
		{ key: 'invitees@85s'},
		{
			key: 'startDate@120s',
			name: 'Start',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'activationDate@120s',
			name: 'Activation',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'cancellationDate@120s',
			name: 'Cancellation',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'expirationDate@145s',
			name: 'Expiration',
			formatter: expirationRenderer
		},
		{
			key: 'utmInfo@100',
			name: 'Source',
			formatter: utmInfoRenderer
		},
		{
			key: 'createdAt@120s',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'amp@50-r',
			name: 'Amplitude',
			formatter: amplitudeRenderer,
		},
		{
			key: 'owner@60',
			name: 'User',
			formatter: GridFormatters.copyToClipboard()
		},
		{
			key: 'id@60',
			formatter: GridFormatters.link('/membership/%s', {target: '_blank'})
		},
	].filter(col => !ignoreCols.includes(col.key.split('@')[0]));
};

function stateRenderer({ row }) {
	let color = 'grey';
	switch (row.state) {
		case CONSTANTS.MembershipStatus.Active:
			color = 'green';
			break;
		case CONSTANTS.MembershipStatus.Cancelled:
			color = 'orange';
			break;
		case CONSTANTS.MembershipStatus.Expired:
			color = 'red';
			break;
	}

	return (
		<Link to={`/member/${row.owner}`} target="_blank" onClick={e => e.stopPropagation()}>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<div style={{width: 25, height: 25, backgroundColor: color, borderRadius: '50%'}}></div>
			</div>
		</Link>
	);
}

function emailRenderer({ row, column }) {
	let email = GridFormatters.email();
	return (
		<div>
			<div>{email({ row, column })}</div>
			<div style={{fontSize: '0.8em'}}>{row.username}</div>
		</div>
	);
}

function providerRenderer({ row }) {
	return (
		<div>
			<div>{row.provider}</div>
			{row.providerParams?.productId && <div style={{fontSize: '0.8em'}}>{row.providerParams.productId}</div>}
			{typeof row.publishedPrice !== 'undefined' && <div style={{ fontSize: '0.8em' }}>${row.publishedPrice}</div>}
		</div>
	);
}

function trialRenderer({ row }) {
	let { isTrial, wasTrial, freeTrialPeriod } = row;
	if (!isTrial && !wasTrial) { return ''; }
	return (
		<div style={{textAlign: 'center'}}>
			<div>{freeTrialPeriod}</div>
			{ !isTrial && wasTrial && <div style={{fontSize: '0.8em'}}>Lapsed</div> }
		</div>
	);
}

function expirationRenderer({ row }) {
	let expiration = new Date(row.expirationDate || 0).getTime();
	let grace = new Date(row.gracePeriodExpirationDate || 0).getTime();
	if (!expiration && !grace) { return ''; }
	let val = new Date(Math.max(expiration, grace));
	return (
		<div>
			<div>{utils.toDateString(val, true, false)}</div>
			{ (val.getTime() === grace) && <div style={{fontSize: '0.7em'}}>Grace</div> }
		</div>
	);
}

function utmInfoRenderer({ row }) {
	let source = row.utmInfo?.utm_source ?? '';
	let channel = row.utmInfo?.utm_channel ?? '';
	let promo = row.utmInfo?.utm_code ?? '';
	return (
		<div style={{fontSize: '0.9em'}}>
			{source && <div>{source}</div>}
			{channel && <div>{channel}</div>}
			{promo && <div>{promo}</div>}
		</div>
	);
}

function amplitudeRenderer({ row }) {
	return <AmplitudeLink userGUID={row.owner} />;
}

export default MembershipGridHelper;

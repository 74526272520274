import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { messageBox, snackbar } from 'ui-core';
import ReportPage from '../report-page/report-page.jsx';
import BOUsersGridHelper from './bo-users-grid-helpers.jsx';
import BOUserRolesEditor from './bo-user-roles-editor.jsx';
import { logger, api } from 'client-services';

let SEARCH_FIELDS = {
	email: {},
	name: {},
	id: { format: 'uuid' },
};

class BOUsersGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: null
		};

		this.reportPage = null;
		this.columns = BOUsersGridHelper.getColumns();
		this.roles = null;

		this.loadMore = this.loadMore.bind(this);
	}

	// #region Methods

	async loadMore(count, skip, sort, search) {
		var params = {
			count,
			skip,
			sort,
			q: search
		};

		logger.trace('Fetching bo users, params:', params);
		let res = await api.user.getBOUsers(params).send();
		logger.trace('Got bo users:', res);
		return { rows: res.users, total: res.total };
	}

	async registerBOUser() {
		let schema = {
			type: 'object',
			required: ['email'],
			properties: {
				email: { type: 'string', format: 'email' }
			}
		};

		let res = await messageBox.promptForm('Register BO User', {}, schema, [messageBox.Buttons.Cancel, 'Register']);
		if (res.result !== 'Register') { return; }

		logger.trace('Registering new BO user, params:', res.data);
		let mb = messageBox('Registering BO user...', '', '', true);
		try {
			await api.auth.registerBOUser(res.data).send();
			mb.close();
			snackbar('BO user registered');
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error registering BO user:', ex);
			mb.setTitle('Error registering BO user');
			mb.setBody(ex.message, false);
		}
	}

	updateUser(params) {
		var mb = messageBox('Updating BO user...', '', '', true);
		api.user.updateBOUser(params).send().then(res => {
			mb.close(10);
			snackbar('BO User updated');
			this.reportPage.reset();
		}).catch(e => {
			logger.error('Error updating BO user:', e);
			mb.setTitle('Error updating BO user');
			mb.setBody(e.message, false);
		});
	}

	setUserProp(user, prop, value) {
		if (!user) { return; }

		var params = {
			id: user.id,
			[prop]: value
		};

		this.updateUser(params);
	}

	async onUserRoles(user) {
		if (!user || !user.email || !user.id) {
			messageBox('Invalid user selected or he has no email');
			return;
		}

		await this.ensureRoles();
		if (!this.roles) { return; }

		let allRoles = Object.keys(this.roles).sort();
		let fixedRoles = user.roles.filter(r => !allRoles.includes(r));

		let mb;
		let body = (
			<BOUserRolesEditor
				allRoles={this.roles}
				activeRoles={user.roles}
				fixedRoles={fixedRoles}
				onCancel={() => mb.close(10)}
				onSave={async roles => {
					await mb.close(10);
					this.saveRoles(user, roles);
				}}
			/>
		);

		mb = messageBox('', body, []);
	}

	async saveRoles(user, roles) {
		let mb = messageBox('Saving...', '', null, true);
		try {
			// make sure we send only the roles we are allowed to update, roles that exists that
			// we are not allowed to update will stay.
			let allowedRoles = Object.keys(this.roles);
			roles = roles.filter(r => allowedRoles.includes(r));

			await api.user.setBOUserRoles({id: user.id, roles}).send();
			mb.close();
			snackbar('BO User updated');
			this.reportPage.reset();
		} catch (e) {
			logger.error('Error updating roles:', e);
			mb.setTitle('Error updating roles');
			mb.setBody(e.message, false);
		}
	}

	async ensureRoles() {
		if (this.roles) { return; }
		let res = await api.user.getBOAppRoles().send();
		if (typeof res?.roles !== 'object' || !res?.roles) {
			messageBox('Error', 'Invalid response when fetching BO roles');
			return;
		}

		this.roles = res.roles;
	}

	// #endregion Methods

	// #region Render

	renderGridToolbar(user) {
		var items = [];
		if (!user) {
			items.push({ key: 'addUser', title: 'Register BO User', onClick: () => this.registerBOUser(), icon: 'person_add'});
			return items;
		}

		if (user.status === CONSTANTS.UserStatus.Active) {
			items.push({key: 'block', title: 'Lock', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Locked), icon: 'person_off'});
		} else if (user.status === CONSTANTS.UserStatus.Locked) {
			items.push({key: 'unblock', title: 'UnLock', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Active), icon: 'person'});
		} else if (user.status === CONSTANTS.UserStatus.Deleted) {
			items.push({key: 'undelete', title: 'UnDelete', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Active), icon: 'restore_from_trash'});
		}

		items.push({key: 'roles', title: 'User Roles', onClick: () => this.onUserRoles(user), icon: 'category'});

		return items;
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				title="BO Users"
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
				gridProps={{
					rowHeight: BOUsersGridHelper.rowHeight,
					headerRowHeight: BOUsersGridHelper.headerRowHeight
				}}
			/>
		);
	}

	// #endregion Render
}

export default BOUsersGrid;

import { compileSchema } from 'ui-core';

/** @type {import('json-schema').JSONSchema7} */
var schema = {
	type: 'object',
	required: ['name', 'contact'],
	properties: {
		id: {
			type: 'string'
		},
		name: {
			type: 'string',
			minLength: 1
		},
		contact: {
			type: 'object',
			required: [
				'name',
				'email'
			],
			properties: {
				name: {
					type: 'string',
					minLength: 1
				},
				email: {
					type: 'string',
					minLength: 1,
					format: 'email'
				}
			}
		},
		location: {
			type: 'object',
			properties: {
				address: {
					$ref: '#/definitions/Address'
				}
			}
		},
		inventory: {
			type: 'array',
			items: {
				$ref: '#/definitions/Item'
			}
		}
	},
	definitions: {
		Address: {
			type: 'object',
			properties: {
				name: {
					type: 'string'
				},
				address1: {
					type: 'string'
				},
				address2: {
					type: 'string'
				},
				city: {
					type: 'string'
				},
				state: {
					type: 'string'
				},
				zip: {
					type: 'string',
					pattern: '^($|[0-9A-Z]{1,}($|-[0-9A-Z]{1,}))'
				},
				country: {
					type: 'string',
					pattern: '^($|[A-Z]{2}$)'
				}
			}
		},
		Item: {
			type: 'object',
			required: ['sku', 'quantity'],
			properties: {
				sku: {
					type: 'string',
					enum: []
				},
				quantity: {
					type: 'integer',
					minimum: 0
				}
			}
		}
	}
};

schema = compileSchema(schema);
export default schema;

import React from 'react';
import {ListItem} from 'ui-core';
import { config } from 'client-services';
import {Switch} from '@mui/material';

class SettingsMenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showAllUsers: config.get('data:showAllUsers'),
		};

		this.onShowAllUsers = this.onShowAllUsers.bind(this);
	}

	onShowAllUsers(event) {
		config.set('data:showAllUsers', event.target.checked);
		this.setState({ showAllUsers: event.target.checked});
	}

	render() {
		var {showAllUsers} = this.state;
		return <ListItem
			{...this.props}
			nestedItems={[
				<ListItem key="1" primaryText="All users" secondaryAction={<Switch edge="end" checked={showAllUsers} onChange={this.onShowAllUsers} />}/>,
			]}
		/>;
	}
}

export default SettingsMenuItem;

import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import { messageBox, AddButtonFAB, Form } from 'ui-core';
import PromosGridHelper from './promos-grid-helper.jsx';
import ReportPage from '../../report-page/report-page.jsx';
import { schema, uiSchema } from './personal-promo.schema.js';
import { api, logger } from 'client-services';

let SEARCH_FIELDS = {
	code: {},
	utmSource: {},
	user: {format: 'uuid'},
	id: { },
};

class PersonalPromosPage extends React.Component {
	constructor(props) {
		super(props);

		this.reportPage = null;
		this.columns = PromosGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
	}

	openEditPromoModal() {
		var resolve;
		let p = new Promise(res => { resolve = res; });

		var mb;
		function formClosed(data) {
			mb.close(50);
			resolve(data);
		}


		let formData = {
			type: CONSTANTS.MembershipPromoType.Personal
		};

		var form = (
			<Form
				formData={formData}
				schema={schema}
				uiSchema={uiSchema}
				submitText="Add"
				buttonsAlign="right"
				submit={formClosed.bind(this)}
				showErrorMessageBox
			/>
		);

		mb = messageBox('Promo details', form, []);

		return p;
	}

	async insertPromo() {
		let promo = await this.openEditPromoModal();
		if (!promo) {return;}

		var mb = messageBox('Inserting promo...', '', null, true);
		try {
			let res = await api.membership.insertPromoCode(promo).send();
			logger.trace('promo upsert successfully, response:', res);
			if (!res?.promo?.code) {
				logger.error('Got invalid response, no promo.code, res:', res);
				throw new Error('Got invalid response, no promo code...');
			}

			window.location.href = `/promo/personal?q=code:${res?.promo.code}`;
		} catch (ex) {
			logger.error('Error upserting promo:', ex);
			mb.setTitle('Error', false);

			if (ex.code === 'DUPLICATE') {
				mb.setBody('Error updating promo. DUPLICATE CODE.');
			} else {
				mb.setBody(ex.message);
			}
		}
	}

	async loadMore(count, skip, sort, search, showAllUsers) {
		var params = {
			count,
			skip,
			sort,
			q: `+type:${CONSTANTS.MembershipPromoType.Personal} ${search}`
		};

		logger.trace('Fetching memberships promos, params:', params);
		let res = await api.membership.getPromoCodes(params).send();
		logger.trace('Got promos:', res);
		return { rows: res.promoCodes, total: res.total };
	}

	// #region Render

	render() {
		return (
			<>
				<ReportPage
					ref={e => { this.reportPage = e; }}
					loadMore={this.loadMore}
					columns={this.columns}
					title="Membership Promos"
					toolbarOpen={true}
					showSearch={true}
					searchFields={SEARCH_FIELDS}
					gridProps={{
						rowHeight: PromosGridHelper.rowHeight,
						headerRowHeight: PromosGridHelper.headerRowHeight,
						sortColumn: 'redeemDate',
						sortDirection: -1
					}}
				/>
				<AddButtonFAB onClick={() => this.insertPromo()} />
			</>
		);
	}

	// #endregion Render
}

export default PersonalPromosPage;

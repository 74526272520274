import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { GridFormatters } from 'ui-core';

var BOUsersGridHelper = {
	rowHeight: 65,
	headerRowHeight: 35
};

BOUsersGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'id@80',
			formatter: idFormatter
		},
		{
			key: 'email@170s',
			formatter: emailFormatter
		},
		'name@150s',
		{
			key: 'status@80',
			formatter: GridFormatters.lookup(CONSTANTS.UserStatus, true)
		},
		{
			key: 'roles',
			formatter: rolesFormatter,
			// formatter: GridFormatters.array()
		},
		{
			key: 'lastLoginDate@120s',
			name: 'Last Login',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'createdAt@120s',
			name: 'Created',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'avatar@50-r',
			name: '',
			formatter: GridFormatters.image({maxHeight: BOUsersGridHelper.rowHeight})
		}
	];
};

function rolesFormatter(data) {
	let roles = (data.row?.roles || []).sort();

	let rowStyle = {
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'auto',
		rowGap: 5,
		padding: '5px 0'
	};

	let itemStyle = {
		// backgroundColor: 'lightgray',
		border: '1px solid lightgray',
		padding: '0 5px',
		marginRight: 3,
		borderRadius: 5
	};

	return (
		<div style={rowStyle}>
			{roles.map(r => <div style={itemStyle} key={r}>{r}</div>)}
		</div>
	);
}

function emailFormatter(data) {
	var style = null;
	let emailVerified = data.row?.emailVerified;
	if (typeof emailVerified !== 'undefined' && !emailVerified) {
		style = {color: 'grey'};
	}

	return GridFormatters.email(style)(data);
}

function idFormatter(data) {
	var style = null;
	let ug = data.row?.ug;
	if (typeof ug !== 'undefined' && ug === 0) {
		style = {color: 'grey'};
	}

	return GridFormatters.copyToClipboard(style)(data);
}

export default BOUsersGridHelper;

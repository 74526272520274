import CONSTANTS from '../../../lib/constants.js';
import {GridFormatters} from 'ui-core';

var CouponsGridHelper = {
	rowHeight: 35,
	headerRowHeight: 35
};

CouponsGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'id@80',
			formatter: GridFormatters.copyToClipboard(),
		},
		'code@s',
		{
			key: 'enabled@80-r',
			formatter: GridFormatters.checkbox()
		},
		{
			key: 'discountType@80',
			formatter: discountFormatter
		},
		{
			key: 'discountScope@80',
			name: 'Scope',
			formatter: GridFormatters.lookup(CONSTANTS.CouponDiscountScope, true)
		},
		{
			key: 'perProduct@100',
			formatter: GridFormatters.json()
		},
		{
			key: 'usageCount@100s',
			formatter: usageFormatter
		},
		{
			key: 'startDate@s',
			formatter: GridFormatters.datetime()
		},
		{
			key: 'endDate@s',
			formatter: GridFormatters.datetime()
		},
		'receiptLine@s',
		'description@s',
		{
			key: 'createdAt@s',
			formatter: GridFormatters.datetime()
		}
	];
};

function discountFormatter({row}) {
	var coupon = row;
	var symbol;
	switch (coupon.discountType) {
		case CONSTANTS.CouponDiscountType.Percent:
			symbol = '%';
			break;
		case CONSTANTS.CouponDiscountType.Fixed:
			symbol = '$';
			break;
		default:
			symbol = '';
	}

	return coupon.discountAmount + symbol;
}

function usageFormatter({row}) {
	var coupon = row;
	return `${coupon.usageCount} / ${coupon.usageLimit}`;
}

export default CouponsGridHelper;

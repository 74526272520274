import React from 'react';
import { GridFormatters } from 'ui-core';
import { AmplitudeLink, UserSpotlightLink } from '../../common-grid-links.jsx';

var PromosGridHelper = {
	rowHeight: 45,
	headerRowHeight: 35
};

PromosGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'code@105s',
			formatter: GridFormatters.copyToClipboard(),
		},
		{
			key: 'user@s',
			formatter: GridFormatters.copyToClipboard()
		},
		{
			key: 'hitCount@80s',
			name: 'hits'
		},
		{
			key: 'usageCount@80s',
			name: 'usage'
		},
		{
			key: 'redeemDate@130s',
			formatter: GridFormatters.datetime(true, true)
		},
		{
			key: 'utmInfo.utm_source',
			name: 'Source',
			formatter: GridFormatters.subProp('utmInfo.utm_source'),
		},
		{
			key: 'kind@110',
			formatter: kindRenderer,
		},
		{
			key: 'spotlight@50-r',
			name: '',
			formatter: userSpotlightRenderer,
		},
		{
			key: 'amp@50-r',
			name: '',
			formatter: amplitudeRenderer,
		},
		{
			key: 'id@50',
			formatter: GridFormatters.copyToClipboard()
		},
	];
};

function kindRenderer({ row }) {
	return (
		<div>
			<div>{ row.entitlement.params.kind }</div>
			<div style={{fontSize: '0.8em'}}>{ row.entitlement.type }</div>
		</div>
	);
}

function amplitudeRenderer({ row }) {
	return row.user && < AmplitudeLink userGUID = { row.user } />;
}

function userSpotlightRenderer({ row }) {
	return row.user && <UserSpotlightLink userGUID={row.user} />;
}

export default PromosGridHelper;

import React from 'react';
import { GridFormatters } from 'ui-core';

var PromosGridHelper = {
	rowHeight: 45,
	headerRowHeight: 35
};

PromosGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'id@50',
			formatter: GridFormatters.copyToClipboard()
		},
		'name@150s',
		{
			key: 'code@105s',
			formatter: GridFormatters.copyToClipboard(),
		},
		{
			key: 'enabled@80',
			formatter: GridFormatters.checkbox(),
		},
		{
			key: 'startDate@110s',
			name: 'Start',
			formatter: GridFormatters.datetime(true, true)
		},
		{
			key: 'endDate@110s',
			name: 'End',
			formatter: GridFormatters.datetime(true, true)
		},
		{
			key: 'usageLimit@s',
			name: 'limit'
		},
		{
			key: 'usageCount@80s',
			name: 'usage'
		},
		{
			key: 'utmInfo.utm_source@90s',
			name: 'Source',
			formatter: GridFormatters.subProp('utmInfo.utm_source'),
		},
		{
			key: 'utmInfo.utm_channel@90s',
			name: 'Channel',
			formatter: GridFormatters.subProp('utmInfo.utm_channel'),
		},
		{
			key: 'utmInfo.utm_campaign@90s',
			name: 'Campaign',
			formatter: GridFormatters.subProp('utmInfo.utm_campaign'),
		},
		{
			key: 'utmInfo.utm_marketplace@90s',
			name: 'Marketplace',
			formatter: GridFormatters.subProp('utmInfo.utm_marketplace'),
		},
		{
			key: 'kind@110',
			formatter: kindRenderer,
		},
	];
};

function kindRenderer({ row }) {
	return (
		<div>
			<div>{ row.entitlement.params.kind }</div>
			<div style={{fontSize: '0.8em'}}>{ row.entitlement.type }</div>
		</div>
	);
}

export default PromosGridHelper;

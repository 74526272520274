import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import { messageBox, Grid, GridFormatters } from 'ui-core';
import { logger, api } from 'client-services';
import MembershipsGrid from './memberships-grid.jsx';

import './membership-info-page.css';

class MembershipInfoPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			membership: null,
			invitees: []
		};

		this.inviteesColumns = [
			'email',
			'username',
			{ key: 'startDate', name: 'Joined', formatter: GridFormatters.datetime(true) },
			{ key: 'user', formatter: GridFormatters.copyToClipboard()}
		];
	}

	// #region Methods

	// #endregion Methods

	// #region Lifecycle

	async componentDidMount() {
		var id = this.props.params.id;
		if (!id) {
			return;
		}

		let mb = messageBox('Fetching membership...', '', null, true);
		try {
			logger.trace('Fetching membership info, membership:', id);
			let res = await api.membership.getMembershipInfo({ id }).send();
			logger.trace('Got membership info:', res);

			let membershipInfo = res?.membershipInfo;
			if (!membershipInfo) {
				mb.setTitle('Oops', false);
				mb.setBody('Membership not found');
				return;
			}

			let email = membershipInfo.email;
			let invitees = Array.isArray(membershipInfo.inviteesInfo) ? membershipInfo.inviteesInfo : [];

			this.setState({ email, invitees, membership: membershipInfo });

			mb.close();
		} catch (ex) {
			logger.error('Error fetching membership info:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// #endregion Lifecycle

	// #region Render

	renderMembership(membership) {
		return (
			<div className="section">
				<div className="title">Membership Info</div>
				<MembershipsGrid
					rows={1}
					memberships={[membership]}
				/>
			</div>
		);
	}

	renderInvitees(invitees) {
		return (
			<div className="section">
				<div className="title">Invitees</div>
				<Grid
					columns={this.inviteesColumns}
					rows={invitees}
				/>
			</div>
		);
	}

	render() {
		let { membership, invitees} = this.state;
		if (!membership) { return 'Loading...'; }

		return (
			<BasePage
				className="membership-info-page"
				title="Membership Info"
				subTitle={`(${membership.id})`}
			>
				{this.renderMembership(membership)}
				{invitees.length > 0 && this.renderInvitees(invitees)}
			</BasePage>
		);
	}

	// #endregion Render
}

MembershipInfoPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedMembershipInfoPage(props) {
	let params = useParams();
	return <MembershipInfoPage params={params} {...props} />;
};

import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import { AmplitudeLink } from '../common-grid-links.jsx';
import CreationViews from './creation-views.jsx';
import {utils, api, logger} from 'client-services';

class ProjectPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			project: null,
			views: [],
			totalTime: 0
		};
	}

	// #region Component Lifecycle

	async componentDidMount() {
		var pid = this.props.params.pid;
		if (!pid) {
			return;
		}

		let project, views;
		let totalTime = 0;
		logger.trace('Fetching project:', pid);
		try {
			let res = await api.project.getProjectDetails({ id: pid }).send();
			logger.trace('Got project:', res);
			project = res?.project;
			views = res?.project?.creationViews || [];
			views.forEach(v => {
				let diff = utils.dateDiff(v.start, v.end, 's');
				totalTime += (diff || 0);
			});
		} catch (e) {
			logger.error('Error fetching project:', e);
			alert('Error - Check dev console');
			return;
		}

		this.setState({ project, views, totalTime });
	}

	// #endregion Component Lifecycle

	// #region Render

	render() {
		let { project, views, totalTime } = this.state;
		if (!project) { return 'Loading...'; }
		return (
			<BasePage>
				<h1>Project: {project.name}</h1>
				<div>id: {project.id}</div>
				<div><AmplitudeLink userGUID={project.owner} /> {project.email} ({ project.owner })</div>
				<h1>Creation Views ({ utils.secondsToString(totalTime) })</h1>
				<CreationViews views={views} />
			</BasePage>
		);
	}

	// #endregion Render
}

ProjectPage.propTypes = {
	params: PropTypes.object
};

ProjectPage.defaultProps = {};

export default function WrappedProjectPage(props) {
	let params = useParams();
	return <ProjectPage params={params} {...props} />;
};

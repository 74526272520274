import React from 'react';
import { BasePage, LookerEmbeddedReport } from 'app-center-common';
import { config } from 'client-services';

const PAGE_STYLE = {
	padding: 0,
};

let cfgMarketingDashboardUrl = config.get('data:lookerDashboards:marketing');

function MarketingDashboard(props) {
	return (
		<BasePage
			title="Marketing"
			contentStyle={PAGE_STYLE}
			appSidebarOpen={false}
		>
			<LookerEmbeddedReport url={cfgMarketingDashboardUrl} />
		</BasePage>
	);
}

export default MarketingDashboard;

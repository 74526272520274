.sales-dash {
	padding: 10px;
}

.sales-dash .title {
	display: inline-block;
	padding: 15px;
	color: white;
	margin: 40px 0 15px 0;
}

.sales-dash .report-header {
	font-size: 1.5em;
}

.sales-dash .two-col {
	display: flex;
	flex-direction: row;
}

.sales-dash .two-col.even > div {
	flex: 0.5;
}

@media screen and (max-width: 600px) {
	.sales-dash .two-col {
		flex-direction: column;
	}
}

@media screen and (max-width: 1000px) {
	.sales-dash .two-col.even {
		flex-direction: column;
	}
}

.sales-dash .two-col .legend {
	margin-left: 10px;
	font-size: 0.8em
}

.sales-dash .two-col .grid-wrap {
	flex: 1;
}

.sales-dash .grid-wrap .total {
	background-color: pink;
}

.pos-page .pos-list {
	padding: 5px;
}

.pos-page .pos-list .pos {
	margin-bottom: 20px;
}

.pos-page .pos-list .pos .group {
	margin-bottom: 15px;
}

.pos-page .pos-list .pos .group .title {
	font-weight: bold;
	font-size: 1em;
}

.pos-page .pos-list .pos .group table {
	border-collapse: separate;
}

.pos-page .pos-list .pos .group table th {
	background-color: #f2f2f2;
	border: 1px solid #ddd;
	padding: 8px;
}

.pos-page .pos-list .pos .group table td {
	padding: 8px;
	border: 1px solid #ddd;
}

.pos-page .subdued {
	color: #aaa;
}

var CONSTANTS = {
	UserStatus: {
		Active: 0,
		Locked: 1,
		Deleted: 2,
	},

	ExperienceStatus: {
		Draft: 0,
		Public: 1
	},

	UserGroup: {
		Ignore: 0,
		Regular: 1,
		Kid: 2
	},

	OrderStatus: {
		New: 0,
		Pending: 1,
		Processing: 2,
		Shipped: 3,
		Delivered: 4,
		Rejected: 5,
		Dispatched: 6,
		Returned: 7
	},

	Carrier: {
		UPS: 'UPS',
		USPS: 'USPS',
		FEDEX: 'FEDEX'
	},

	ReturnReason: {
		DidNotLikeIt: 'DIDNT_LIKE_IT',
		DifficultToOperate: 'DIFFICULT_TO_OPERATE',
		MissingParts: 'MISSING_PARTS',
		Defective: 'DEFECTIVE',
		PriceValue: 'PRICE_VALUE',
		DamagedInShipping: 'DAMAGED_IN_SHIPPING',
		WrongItem: 'WRONG_ITEM',
		ItemFailedAfterUse: 'ITEM_FAILED_AFTER_USE',
		ColorNotAsShown: 'COLOR_NOT_AS_SHOWN',
		ProductDurability: 'PRODUCT_DURABILITY',
		NoShippingAvailable: 'NO_SHIPPING_AVAILABLE',
		Other: 'OTHER'
	},

	TransactionStatus: {
		Pending: 0,
		Success: 1,
		Deferred: 2,
		Failure: 3
	},

	PaymentMethods: {
		CreditCard: 'CreditCard',
		Voucher: 'Voucher',
		GiftCard: 'GiftCard'
	},

	ChargeValidationsResult: {
		NA: 'NA',
		Pass: 'Pass',
		Fail: 'Fail'
	},

	CouponDiscountType: {
		Percent: 0,
		Fixed: 1
	},

	CouponDiscountScope: {
		Cart: 0,
		Item: 1
	},

	Marketplace: {
		Cupixel: 'CPX',
		HSN: 'HSN',
		BO: 'BO',
		Shopify: 'SPFY',
		Amazon: 'AMZN',
		Etsy: 'ETSY',
		GILT: 'GILT',
		FEVERUP: 'FEVERUP',
		Grommet: 'GRMT'
	},

	SubscriptionProviders: {
		System: 'System',
		Google: 'Google',
		Apple: 'Apple',
	},

	MembershipStatus: {
		Active: 'Active',
		Cancelled: 'Cancelled',
		Expired: 'Expired'
	},

	MembershipPromoType: {
		Personal: 'Personal',
		Shared: 'Shared'
	},

	PromoEntitlementType: {
		Subscription: 'Subscription',
		SubscriptionOffering: 'SubscriptionOffering'
	},

	PromoSubscriptionOfferingKind: {
		BoxOwners: 'BoxOwners',
		Marketing: 'Marketing'
	},

	PushNotificationTopics: {
		Live: 'LIVE_EXP_REMINDER',
		General: 'GENERAL',
	},

	PushNotificationChannels: {
		Live: 'LIVE_EXP_REMINDER',
		FeaturedExperiences: 'FEATURED_EXPERIENCES',
		Other: 'OTHER',
	}
};

export default CONSTANTS;
import React from 'react';
// import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';
import { BasePage } from 'app-center-common';
import { messageBox } from 'ui-core';
import { api, logger } from 'client-services';

class PromoSearchPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			code: '',
			promo: ''
		};

		this.onSearch = this.onSearch.bind(this);
	}

	// #region Methods

	// #endregion Methods

	// #region EventHandlers

	async onSearch() {
		let { code } = this.state;
		if (!code) { return; }
		let valid = /[a-z0-9]{8}/i.test(code);
		if (!valid) {
			let res = await messageBox('Promo seems invalid, search anyway?', '', [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
			if (res !== messageBox.Buttons.Yes) { return; }
		}

		this.setState({ promo: '' });

		logger.trace('Fetching memberships promo:', code);
		let mb = messageBox('Loading...');
		let res = await api.membership.getPromoCode({code}).send();

		let promo = res?.promoCode;
		if (!promo) {
			mb.setTitle('Promo not found', false);
			return;
		}

		if (promo.user) {
			res = await api.user.getUsers({ q: `id:${promo.user}` }).send().catch(ex => {
				logger.error('Error fetching user: %s, error:', promo.user, ex);
				alert('Error fetching user email');
				return null;
			});

			let email = res?.users?.[0]?.email;
			promo.email = email;
		}

		this.setState({ promo: JSON.stringify(promo, null, '    ') });
		mb.close();
	}

	// #endregion EventHandlers

	// #region Component Lifecycle

	// #endregion Component Lifecycle

	// #region Render

	render() {
		let { code, promo } = this.state;
		return (
			<BasePage
				title={'Search Promo'}
			>
				<div style={{display: 'flex', flexDirection: 'row'}}>
					<TextField label="Promo" value={code} onChange={e => this.setState({ code: e.currentTarget.value })} />
					<Button variant="contained" onClick={this.onSearch} style={{alignSelf: 'center', marginLeft: 10}}>Search</Button>
				</div>
				<div style={{whiteSpace: 'pre', fontFamily: 'monospace', marginTop: 20}}>
					{promo}
				</div>
			</BasePage>
		);
	}

	// #endregion Render
}

PromoSearchPage.propTypes = {
};

PromoSearchPage.defaultProps = {
};

export default PromoSearchPage;

import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'app-center-common';
import {messageBox, snackbar, Grid, JSONModal} from 'ui-core';

import ExperimentsGridHelper from './experiments-grid-helper.jsx';
import {api, logger, utils} from 'client-services';

class Experiments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSelected: false,
			showJsonModal: false,
			editExperiment: null
		};

		this.grid = null;
		this.columns = ExperimentsGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.onAdd = this.onAdd.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onJSONModalClosed = this.onJSONModalClosed.bind(this);
	}

	onSelectionChange(selection) {
		this.setState({isSelected: selection.length > 0});
	}

	onAdd() {
		var exp = {
			name: '',
			conditions: {
				all: [
					{
						fact: '',
						path: '',
						operator: '',
						value: ''
					}
				]
			},
			output: {
				success: true,
				failure: false
			},
			input: []
		};

		this.setState({ showJsonModal: true, editExperiment: exp });
	}

	onEdit(grid) {
		var selected = grid.getSelection();
		if (selected.length < 1) {return;}
		var exp = utils.extend(true, {}, selected[0]);
		this.setState({ showJsonModal: true, editExperiment: exp });
	}

	onDelete(grid) {
		var selected = grid.getSelection();
		if (selected.length < 1) {return;}
		var exp = selected[0];
		messageBox(`Are you sure you want to delete ${exp.name} ?`, '', [messageBox.Buttons.Cancel, 'Delete']).promise.then(res => {
			if (res !== 'Delete') {return;}
			logger.trace('Deleting experiment `%s`', exp.id);
			api.experiment.deleteExperiment({id: exp.id}).send().then(res => {
				logger.trace('Experiment `%s` deleted', exp.id);
				grid.reset();
			}).catch(e => {
				logger.error('Error deleting experiment: %s, err:', exp.id, e);
				messageBox('Error deleting experiment', e.message, messageBox.Buttons.OK);
			});
		});
	}

	onJSONModalClosed(exp) {
		if (!exp) {
			this.setState({showJsonModal: false, editExperiment: null});
			return;
		}

		var req = exp.id ? api.experiment.updateExperiment(exp) : api.experiment.insertExperiment(exp);

		logger.trace('Updating experiment:', exp);
		var modal = messageBox('Update Experiment', 'Uploading...', messageBox.Buttons.OK, true);
		req.send().then(res => {
			logger.trace('Got update experiment response:', res);
			modal.close();
			snackbar('Experiment updated successfully');
			this.onJSONModalClosed(null);
			this.grid.reset();
		}).catch(e => {
			logger.error('Error updating experiment:', e);
			modal.setTitle('Error updating experiment', false);
			modal.setBody(e.message);
		});
	}

	async loadMore(count, skip) {
		var params = { count, skip };

		logger.trace('Fetching experiments, params:', params);
		try {
			let res = await api.experiment.getExperiments(params).send();
			logger.trace('Got experiments:', res);
			return res.experiments;
		} catch (e) {
			logger.error('Error fetching experiments:', e);
			messageBox('Error', 'Error fetching experiments');
			return [];
		};
	}

	renderContent() {
		return (
			<Grid
				ref={r => { this.grid = r; }}
				columns={this.columns}
				keyCol="id"
				pageSize={30}
				onLoadMore={this.loadMore}
				onSelectionChange={this.onSelectionChange}
				headerRowHeight={ExperimentsGridHelper.headerRowHeight}
				rowHeight={ExperimentsGridHelper.rowHeight}
				selectOnClick={true}
				showCheckbox={true}
				toolbarItems={[
					{key: 'add', title: 'Add', onClick: this.onAdd, icon: 'playlist_add'},
					{key: 'edit', title: 'Edit', onClick: this.onEdit, icon: 'edit', disabled: !this.state.isSelected},
					{key: 'remove', title: 'Remove', onClick: this.onDelete, icon: 'delete', disabled: !this.state.isSelected},
				]}
			/>
		);
	}

	render() {
		return (
			<BasePage title="Experiments">
				{this.renderContent()}
				<JSONModal editable={true} open={this.state.showJsonModal} data={this.state.editExperiment} onClose={this.onJSONModalClosed} />
			</BasePage>
		);
	}
}

Experiments.contextTypes = {
	setAppBarComponents: PropTypes.func
};

export default Experiments;

import React from 'react';
import { messageBox, DateTimeRangePicker } from 'ui-core';
import { api, logger } from 'client-services';
import ReportPage from '../../report-page/report-page.jsx';
import BuyersGridHelper from './buyers-grid-helper.jsx';

class BuyersReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dates: {
				from: null,
				to: null
			}
		};

		this.reportPage = null;
		this.columns = BuyersGridHelper.getColumns();

		this.datesSelected = this.datesSelected.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	async loadMore(count, skip, sort, search, showAllUsers) {
		var { from, to } = this.state.dates;
		from = from || undefined;
		to = to || undefined;

		var params = {
			from,
			to,
			count,
			skip,
		};

		logger.trace('Fetching buyers report:', params);
		var res = await api.store.buyersReport(params).send();
		if (!res || !Array.isArray(res.buyers)) {
			messageBox('Error fetching report', 'Try to refresh the page');
			return { rows: [] };
		}

		logger.trace('Got Buyers report:', res);
		res.buyers.forEach(b => {
			b.firstOrder = b.uniqueOrders === 1;
		});

		return { rows: res.buyers };
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	datesSelected(dates) {
		this.setState({ dates }, () => {
			this.reportPage.reset();
		});
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">
	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderInnerToolbar() {
		return (
			<div style={{ display: 'flex', overflow: 'auto', alignItems: 'center' }}>
				<DateTimeRangePicker popover selected={this.datesSelected} from={this.state.dates.from} to={this.state.dates.to} />
			</div>
		);
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				keyCol="email"
				title="Memberships"
				toolbar={this.renderInnerToolbar()}
				toolbarOpen={true}
				gridProps={{
					rowHeight: BuyersGridHelper.rowHeight,
					headerRowHeight: BuyersGridHelper.headerRowHeight
				}}
			/>
		);
	}

	// </editor-fold> // Render
}

BuyersReport.propTypes = {};
BuyersReport.defaultProps = {};

export default BuyersReport;

import { GridFormatters } from 'ui-core';

var ExperimentsGridHelper = {
	rowHeight: 35,
	headerRowHeight: 35
};

ExperimentsGridHelper.getColumns = function getColumns() {
	return [
		'id@60',
		'name@120',
		'description@200',
		{
			key: 'enabled@80',
			formatter: GridFormatters.checkbox(),
		},
		{
			key: 'conditions',
			formatter: GridFormatters.json()
		},
		{
			key: 'output',
			formatter: GridFormatters.json()
		},
		{
			key: 'input',
			formatter: GridFormatters.json()
		}
	];
};

export default ExperimentsGridHelper;

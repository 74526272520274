import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { MenuItem, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { messageBox, snackbar, DateTimeRangePicker } from 'ui-core';
import ReportPage from '../../report-page/report-page.jsx';
import OrdersGridHelper from './orders-grid-helpers.jsx';
import { http, api, logger, config } from 'client-services';

let SEARCH_FIELDS = {
	id: {title: 'Order Id'},
	email: {},
	user: { title: 'User Id', format: 'uuid' },
	sku: {},
	mp: { title: 'Marketplace' },
	pos: { title: 'POS' }
};

class OrdersGrid extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchStatus: 'All',
			datePicker: {
				from: null,
				to: null
			}
		};

		this.reportPage = null;
		this.columns = OrdersGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
		this.onOrdersStatusChanged = this.onOrdersStatusChanged.bind(this);
		this.orderStatusChanged = this.orderStatusChanged.bind(this);
		this.dateRangeChanged = this.dateRangeChanged.bind(this);
	}

	// #region Methods

	async loadMore(count, skip, sort, search, showAllUsers) {
		logger.trace('Fetching orders, count: %s, skip: %s:', count, skip);
		let searchStatus = this.state.searchStatus;
		var params = {
			count,
			skip,
			sort,
			q: search + (searchStatus === 'All' ? '' : ` +status:${searchStatus}`) + (showAllUsers ? '' : ' +ug:1 +ug:2')
		};

		if (this.state.datePicker.from) {
			params.from = this.state.datePicker.from;
		}

		if (this.state.datePicker.to) {
			params.to = this.state.datePicker.to;
		}

		let res = await api.store.getOrders(params).send();
		logger.trace('Got orders:', res);
		return { rows: res.orders, total: res.total };
	}

	async promptReturnReason() {
		var schema = {
			type: 'object',
			required: ['returnReason'],
			properties: {
				returnReason: {
					type: 'string',
					title: 'Reason',
					enum: Object.values(CONSTANTS.ReturnReason)
				}
			}
		};

		var res = await messageBox.promptForm('Return order', {}, schema, [messageBox.Buttons.Cancel, 'Return']);
		if (res.result === messageBox.Buttons.Cancel) { return null; }
		return res.data.returnReason;
	}

	async reportReturnToHSN(order) {
		if (order.marketplace?.id !== CONSTANTS.Marketplace.HSN) { return; }
		var res = await messageBox('Report return to HSN ?', null, [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
		if (res === messageBox.Buttons.No) { return; }

		var mb = messageBox('Reporting order return to HSN...');
		var url = config.get('data:slsBaseUrl');
		if (!url) {
			mb.setTitle('Error');
			mb.setBody('Config is missing value for data:slsBaseUrl');
			return;
		}

		if (!url.endsWith('/')) { url += '/'; }
		url += `hsn/order/return?orderId=${order.id}`;
		try {
			let res = await http.send('GET', url);
			mb.setTitle('Response');
			mb.setBody(<pre>{JSON.stringify(res, null, '  ')}</pre>);
		} catch (ex) {
			logger.error('Error dispatching lambda at url: %s, error:', url, ex);
			mb.setTitle('Error');
			mb.setBody(ex.message);
		}
	}

	// #endregion Methods

	// #region EventHandlers

	onOrdersStatusChanged(e) {
		this.setState({ searchStatus: e.target.value }, () => this.reportPage?.reset());
	}

	dateRangeChanged(dates) {
		this.setState({ datePicker: dates, showDatePicker: false }, () => {
			this.reportPage.reset();
		});
	}

	async orderStatusChanged(order, status) {
		if (!order) { return; }
		var req, sendEmail, tracking;
		if (status === CONSTANTS.OrderStatus.Shipped) {
			let res = await messageBox.prompt('Send shipping notice to user?', ['tracking'], [messageBox.Buttons.Cancel, messageBox.Buttons.No, messageBox.Buttons.Yes]);
			if (res.result === messageBox.Buttons.Cancel) { return; }
			sendEmail = (res.result === messageBox.Buttons.Yes);
			tracking = res.values.tracking;
			req = api.store.updateOrderShipment({ id: order.id, sendEmail, tracking });
		} else if (status === CONSTANTS.OrderStatus.Returned) {
			let res = await this.promptReturnReason();
			if (!res) { return; }
			req = api.store.updateOrder({ id: order.id, status, statusDesc: res });
		} else {
			req = api.store.updateOrder({ id: order.id, status });
		}

		var mb = messageBox('Updating order...', '', '', true);
		try {
			let res = await req.send();
			mb.close();
			snackbar('Order updated !');
			this.reportPage.reset();
			if (res.order?.status === CONSTANTS.OrderStatus.Returned) {
				this.reportReturnToHSN(res.order);
			}
		} catch (e) {
			logger.error('Error updating order:', e);
			mb.setTitle('Error updating order');
			mb.setBody(e.message, false);
		}
	}

	// #endregion EventHandlers

	// #region Render

	renderInnerToolbar() {
		let statuses = Object.keys(CONSTANTS.OrderStatus);
		return (
			<div style={{ display: 'flex', overflow: 'auto', alignItems: 'center' }}>
				Status: <Select variant="standard" value={this.state.searchStatus} onChange={this.onOrdersStatusChanged} style={{marginLeft: 10}}>
					<MenuItem value="All">All</MenuItem>
					{statuses.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
				</Select>
				<DateTimeRangePicker popover selected={this.dateRangeChanged} from={this.state.datePicker.from} to={this.state.datePicker.to} />
			</div>
		);
	}

	renderGridToolbar(selected) {
		if (!selected) {return []; }

		let items = [
			{
				key: 'new',
				title: 'New',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.New),
				icon: 'low_priority'
			},
			{
				key: 'pending',
				title: 'Pending',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Pending),
				icon: 'attach_money'
			},
			{
				key: 'process',
				title: 'Processing',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Processing),
				icon: 'autorenew'
			},
			{
				key: 'shipped',
				title: 'Shipped',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Shipped),
				icon: 'local_shipping'
			},
			{
				key: 'delivered',
				title: 'Delivered',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Delivered),
				icon: 'done_all'
			},
			{
				key: 'returned',
				title: 'Returned',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Returned),
				icon: 'assignment_return'
			},
			{
				key: 'rejected',
				title: 'Rejected',
				onClick: () => this.orderStatusChanged(selected, CONSTANTS.OrderStatus.Rejected),
				icon: 'cancel_presentation'
			}
		];

		return items;
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				title="Orders"
				toolbar={this.renderInnerToolbar()}
				toolbarOpen={true}
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
				gridProps={{
					rowHeight: OrdersGridHelper.rowHeight,
					headerRowHeight: OrdersGridHelper.headerRowHeight
				}}
			/>
		);
	}

	// #endregion Render
}

OrdersGrid.propTypes = {
	searchParams: PropTypes.object
};

export default function WrappedOrders(props) {
	let [searchParams] = useSearchParams();
	return <OrdersGrid searchParams={searchParams} {...props} />;
};

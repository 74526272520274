.emphasize-warn {
	font-weight: bold;
	text-decoration: underline;
	color: red;
}

.order-page-tools .button {
	color: white !important;
}

.order-page .table {
	display: table;
}

.order-page .table > div {
	display: table-row;
}

.order-page .table > div:first-child {
	background-color: #f9f9f9;
}

.order-page .table > div > div {
	display: table-cell;
	border: 1px solid;
	padding: 5px;
}

.order-page .heading {
	font-size: 1.5em;
	margin: 20px 0;
	border-bottom: 1px solid #ccc;
}

.order-page .details > div {
	margin-top: 10px;
}

.order-page .details > div > div:first-child {
	font-weight: bold;
}

.order-page .addresses .content {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.order-page .address .title {
	text-align: center;
	font-weight: bold;
	margin-bottom: 10px;
}

.order-page .address .edit-addr {
	vertical-align: middle;
	margin-left: 10px;
}

.order-page .address.billing {
	margin-left: 20px;
	border-left: 1px solid #ccc;
	padding-left: 10px;
}

.order-page .invoice .link {
	text-decoration: none;
	font-size: 0.6em;
}

.order-page .invoice .actions {
	display: flex;
	flex-direction: row;
}

.order-page .invoice .actions .action {
	margin-right: 15px;
}

.order-page .invoice .transaction {
	padding: 5px;
	margin-top: 10px;
}
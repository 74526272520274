import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { messageBox, Grid, GridFormatters } from 'ui-core';
import { BasePage } from 'app-center-common';
import MembershipsGrid from '../membership/memberships-grid.jsx';
import arraySort from '../../lib/arraySort.js';
import { api, logger, utils } from 'client-services';

import './user-spotlight-page.css';

class UserSpotlightPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			/** @type {UserSpotlightData} */
			data: null
		};
	}

	// #region Methods

	/**
	 * @param {ServerUserSpotlightData} data
	 * @returns {UserSpotlightData}
	 */
	toModel(data) {
		/** @type {UserSpotlightData} */
		let res = {};
		// copy props just because code inspector doesn't like assignment
		for (let p in data) {
			res[p] = data[p];
		}

		res.createdAt = utils.toDateString(data.createdAt, true, true);
		res.lastLoginDate = utils.toDateString(data.lastLoginDate, true, true);

		res.spacePostsCount = data.spacePosts?.posts || 0;
		res.spaceCommentsCount = data.spacePosts?.comments || 0;

		if (Array.isArray(data.projects)) {
			res.projects = data.projects.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
			res.projectsCount = data.projects.length;
			res.totalCreationTime = utils.secondsToString(data.projects.map(p => p.totalCreationTime || 0).reduce((a, b) => a + b, 0));
			res.totalCreationViews = data.projects.map(p => p.creationViews || 0).reduce((a, b) => a + b, 0);

			data.projects.forEach(p => {
				p.totalCreationTime = utils.secondsToString(p.totalCreationTime);
			});
		}

		if (Array.isArray(data.memberships)) {
			res.memberships = data.memberships.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
			res.totalMemberships = data.memberships.length;

			if (data.memberships.length > 0) {
				let mem = data.memberships[data.memberships.length - 1];
				res.lastMembershipProvider = mem.provider;
				res.lastMembershipStart = utils.toDateString(mem.startDate, true, true);
				res.lastMembershipState = mem.state;
				res.lastMembershipExpiration = mem.expirationDate ? utils.toDateString(mem.expirationDate, true, true) : '';
				res.lastMembershipSource = '';
				if (mem.utmInfo?.utm_source) {
					res.lastMembershipSource = [mem.utmInfo.utm_source, mem.utmInfo.utm_marketplace, mem.utmInfo.utm_channel].filter(s => !!s).join('\n');
				}
			}
		}

		return res;
	}

	// #endregion Methods

	// #region EventHandlers

	// #endregion EventHandlers

	// #region Component Lifecycle

	async componentDidMount() {
		var id = this.props.params.userId;
		if (!id) {
			return;
		}

		let mb = messageBox('Fetching user data...');
		logger.trace('Fetching user spotlight for user:', id);
		try {
			let res = await api.user.getUserSpotlight({ id }).send();
			if (!res?.userSpotlight) {
				throw new Error('Got invalid response');
			}

			let data = this.toModel(res.userSpotlight);
			this.setState({ data });

			mb.close();
		} catch (ex) {
			logger.error('Error fetching user `%s` spotlight, error:', id, ex);
			mb.setTitle('Error');
			mb.setBody(ex.message);
		}
	}

	// #endregion Component Lifecycle

	// #region Render

	/**
	 * @param {UserSpotlightData} data
	 */
	renderUserInfo(data) {
		let dict = {
			general: ['id|ID', 'email|Email', 'username|Username', 'createdAt|Created', 'lastLoginDate|Last Login'],
			projects: ['projectsCount|Projects', 'totalCreationViews|Creation Views', 'totalCreationTime|Creation Time', 'personalPhotos|Personal Photos'],
			spaces: ['spacePostsCount|Space Posts', 'spaceCommentsCount|Space Comments', 'totalMemberships|Memberships', 'joiningGift|Gift'],
			memberships: ['lastMembershipProvider|Latest Membership', 'lastMembershipSource|Source', 'lastMembershipStart|Started', 'lastMembershipState|State', 'lastMembershipExpiration|Expiration']
		};

		return (
			<div className="section">
				<div className="header">General</div>
				<div className="content user-info">
					{Object.keys(dict).map(cat => (
						<div key={cat} className="data-row">
							{dict[cat].map(prop => {
								let key = prop.split('|')[0];
								let header = prop.split('|')[1] || key;
								let val = data[key];
								return (
									<div key={key} className="data-prop">
										<div className="p-header">{header}</div>
										<div className="p-value">{val}</div>
										{key === 'id' && data.country &&
											<div className="country">
												<img src={`https://flagsapi.com/${data.country.toUpperCase()}/shiny/16.png`} />
												{data.country}
											</div>}
									</div>
								);
							})}
						</div>
					))}
				</div>
			</div>
		);
	}

	/**
	 * @param {UserSpotlightData} data
	 */
	renderMembershipsGrid(data) {
		return (
			<div className="section">
				<div className="header">Memberships</div>
				<div className="content">
					<MembershipsGrid
						rows={3}
						memberships={data.memberships}
						ignoreCols={['email']}
					/>
				</div>
			</div>
		);
	}

	/**
	 * @param {UserSpotlightData} data
	 */
	renderProjectsGrid(data) {
		let rowHeight = 35;
		let cols = [
			'name@s',
			{ key: 'createdAt@s', formatter: GridFormatters.datetime()},
			'totalCreationTime@s',
			'creationViews@s',
			{ key: 'id', formatter: GridFormatters.copyToClipboard()},
		];
		return (
			<div className="section">
				<div className="header">Projects</div>
				<div className="content">
					<div style={{height: rowHeight * 6 + 2}}>
						<Grid
							columns={cols}
							rows={data.projects}
							onSort={(col, dir) => {
								arraySort(data.projects, dir, col);
								this.setState({});
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { data } = this.state;
		if (!data) { return null; }

		return (
			<BasePage
				title={'User Spotlight'}
				subTitle={data.email}
				className="user-spotlight-page"
			>
				<div>
					{this.renderUserInfo(data)}
					{this.renderMembershipsGrid(data)}
					{this.renderProjectsGrid(data)}
				</div>
			</BasePage>
		);
	}

	// #endregion Render
}

UserSpotlightPage.propTypes = {
	params: PropTypes.object
};

UserSpotlightPage.defaultProps = {
};

export default function WrappedUserSpotlightPage(props) {
	let params = useParams();
	return <UserSpotlightPage params={params} {...props} />;
}

/**
 * @typedef {Object} ServerUserSpotlightData
 * @property {String} email
 * @property {String} username
 * @property {String} createdAt
 * @property {String} lastLoginDate
 * @property {String} country
 * @property {Array} memberships
 * @property {{posts: Number, comments: Number}} spacePosts
 * @property {Array} projects
 * @property {Number} personalPhotos
 * @property {String} joiningGift
*/

/**
 * @typedef {Object} ExtraUserSpotlightData
 * @property {Number} projectsCount
 * @property {Number} totalCreationViews
 * @property {String} totalCreationTime
 * @property {Number} spacePostsCount
 * @property {Number} spaceCommentsCount
 * @property {Number} totalMemberships
 * @property {String} lastMembershipSource
 * @property {String} lastMembershipProvider
 * @property {String} lastMembershipStart
 * @property {String} lastMembershipState
 * @property {String} lastMembershipExpiration
*/

/**
 * @typedef {ServerUserSpotlightData & ExtraUserSpotlightData} UserSpotlightData
 */
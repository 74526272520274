import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import { Button } from '@mui/material';
import {messageBox, compileSchema, Form, Grid} from 'ui-core';
import {BasePage} from 'app-center-common';
import {api, logger, utils} from 'client-services';

var OrderStatusDict = {};
Object.keys(CONSTANTS.OrderStatus).forEach(k => { OrderStatusDict[CONSTANTS.OrderStatus[k]] = k; });

class ShippingReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			data: {
				orderId: '',
				batchId: ''
			}
		};

		this.columns = [
			{key: 'id', name: 'ID', resizable: true},
			{key: 'email', name: 'Email', resizable: true},
			{key: 'createdAt', name: 'Created At', resizable: true},
			{key: 'status', name: 'Status', resizable: true},
			{key: 'name', name: 'Name', resizable: true},
			{key: 'shippedAt', name: 'Shipped At', resizable: true},
			{key: 'tracking', name: 'Tracking', resizable: true},
			{key: 'address', name: 'Address', resizable: true}
		];

		this.reportForm = null;
		this.onFetchReport = this.onFetchReport.bind(this);
		this.onExportToCSV = this.onExportToCSV.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	exportToCSV(orders) {
		if (orders.length < 1) {return '';}
		var headers = Object.getOwnPropertyNames(orders[0]).join(',');
		var data = orders.map(o => Object.values(o).join(',')).join('\n');
		return headers + '\n' + data;
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	async onFetchReport(data) {
		if (!data) {return;}
		var orders;
		var mb = messageBox('Fetching report...', null, messageBox.Buttons.OK, true);
		try {
			var params = {count: 1000, skip: 0, q: ''};
			if (data.orderId) {
				params.q += `+id:${data.orderId}`;
			}

			if (data.batchId) {
				params.q += `+mpbatch:${data.batchId}`;
			}

			logger.trace('Fetching orders with params:', params);
			var res = await api.store.getOrders(params).send();
			logger.trace('Got orders response:', res);
			orders = res.orders.map(o => {
				var res = {
					id: o.id,
					email: o.email,
					createdAt: o.createdAt,
					status: OrderStatusDict[o.status] || '',
				};

				if (o.shipping && o.shipping.address) {
					var addr = o.shipping.address;
					res.name = addr.name || '';
					res.shippedAt = o.shipping.shippedAt || '';
					res.tracking = o.shipping.tracking || '';
					res.address = `"${addr.address1}\n${addr.address2 ? `${addr.address2}\n` : ''}${addr.city}, ${addr.state}\n${addr.zip}\n${addr.country}"`;
				}

				return res;
			});
		} catch (ex) {
			logger.error('Error fetching orders:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}

		if (orders.length < 1) {
			mb.setTitle('No orders found', false);
		} else {
			this.setState({ orders });
			mb.close();
		}
	}

	onExportToCSV() {
		var csv = this.exportToCSV(this.state.orders);
		var blob = new Blob([csv]);
		utils.downloadBlob(blob, 'shipping-report.csv');
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">
	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	render() {
		var schema = compileSchema({
			type: 'object',
			properties: {
				orderId: { type: 'string' },
				batchId: { type: 'string', description: 'Batch ID of imported orders'}
			}
		});

		return (
			<BasePage title="Shipping Report">
				<Form
					ref={f => { this.reportForm = f; }}
					formData={this.state.data}
					schema={schema}
					showButtons={false}
					fullHeight={false}
					fullWidth={false}
					submit={this.onFetchReport}
				/>
				<div style={{marginBottom: 10}}>
					<Button variant="contained" color="primary" onClick={() => this.reportForm.submit()}>Fetch</Button>
					<Button variant="contained" color="primary" style={{ marginLeft: 10 }} disabled={this.state.orders.length < 1} onClick={this.onExportToCSV}>Export CSV</Button>
				</div>
				<Grid
					columns={this.columns}
					rows={this.state.orders}
				/>
			</BasePage>
		);
	}

	// </editor-fold> // Render
}

ShippingReport.propTypes = {};
ShippingReport.defaultProps = {};

export default ShippingReport;

import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import {BasePage} from 'app-center-common';
import {messageBox, Form, ToolbarIcon} from 'ui-core';
import {api, auth, logger, utils} from 'client-services';

import OrderSchema from './order.schema.js';
import OrderSchemaUI from './order-ui.schema.js';

class AddOrderPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			order: {}
		};

		this.form = null;

		this.onSubmit = this.onSubmit.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	async loadCatalog() {
		var res = await api.store.getCatalog().send();
		if (!res || !res.catalog || typeof res.catalog !== 'object') {
			throw new Error('Invalid catalog response');
		}

		var skus = Object.keys(res.catalog);
		if (skus.length < 1) {
			throw new Error('Catalog has no SKUs defined...');
		}

		// Set the skus as valid enum in the schema
		OrderSchema.definitions.Item.properties.sku.enum = skus;
		OrderSchema.definitions.Item.properties.sku.enumNames = skus.map(sk => `${sk} - ${res.catalog[sk].desc} (${res.catalog[sk].price})`);
		// OrderSchema.properties.items.items.properties.sku.enum = skus;
		// OrderSchema.properties.items.items.properties.sku.enumNames = skus.map(sk => `${sk} - ${res.catalog[sk].desc} (${res.catalog[sk].price})`);
	}

	async loadMarketplaceAndPOS() {
		// Set marketplace enum
		OrderSchema.properties.marketplace.enum = Object.values(CONSTANTS.Marketplace);
		OrderSchema.properties.marketplace.enumNames = Object.keys(CONSTANTS.Marketplace);
		OrderSchema.properties.marketplace.default = CONSTANTS.Marketplace.BO;

		var res = await api.store.getPointsOfSale().send();
		if (res && Array.isArray(res.pointsOfSale)) {
			// Set the pos as valid enum in the schema
			OrderSchema.properties.pos.enum = [''].concat(res.pointsOfSale.map(pos => pos.id));
			OrderSchema.properties.pos.enumNames = ['---'].concat(res.pointsOfSale.map(pos => pos.name));
		}
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	async onSubmit(formData) {
		if (!formData) {return;}
		var order = utils.extend(true, {}, formData);

		var params = {
			pos: order.pos,
			email: order.email,
			currency: order.currency,
			items: order.items,
			discount: order.invoice.discount || 0,
			freeShipping: order.shipping.freeShipping,
			marketplace: formData.marketplace || CONSTANTS.Marketplace.BO,
			marketplaceOrderId: formData.marketplaceOrderId || undefined
		};

		if (order.shipping.type === 'Address') {
			params.shipping = order.shipping.address;
		}

		if (order.shipping.service !== 'Default') {
			params.carrier = 'UPS';
			params.service = order.shipping.service;
		}

		switch (order.billing.type) {
			case 'SameAsShipping':
				if (params.shipping) { params.billing = params.shipping; }
				break;
			case 'Address':
				params.billing = order.billing.address;
				break;
		}

		var mb = messageBox('Creating order...  ', '', messageBox.Buttons.OK, true);
		try {
			logger.trace('Creating order with params', params);
			var res = await api.store.placeOrder(params).send();
			if (!res || !res.order || !res.order.id) {
				logger.error('Invalid place order response, no error nor order object, got:', res);
				throw new Error('Invalid response, no error nor order object received');
			}

			if (!res.order.invoice || typeof res.order.invoice.balance !== 'number') {
				logger.error('Invalid place order response, no invoice nor invoice.balance, got:', res);
				throw new Error('Invalid response, order is missing invoice or invoice.balance');
			}

			var updParams = {};
			if (formData.invoice.markAsPaid) {
				var invoice = {
					status: CONSTANTS.TransactionStatus.Success,
					balance: 0,
					transactions: [{
						status: CONSTANTS.TransactionStatus.Success,
						amount: res.order.invoice.balance,
						paymentProvider: CONSTANTS.Marketplace.BO,
						paymentProviderParams: (auth.session().user && auth.session().user.id) ? {customerId: auth.session().user.id} : undefined
					}]
				};

				updParams.invoice = invoice;
			}

			if (formData.shipping.markAsDelivered) {
				updParams.status = CONSTANTS.OrderStatus.Delivered;
			}

			if (Object.keys(updParams).length > 0) {
				mb.setTitle('Marking order as paid...');
				updParams.id = res.order.id;
				await api.store.updateOrder(updParams).send();
			}

			if (formData.updatePOSInventory && order.pos && Array.isArray(res.order.items) && res.order.items.length > 0) {
				mb.setTitle('Updating inventory...');
				await api.store.updatePOSInventory({id: order.pos, items: res.order.items}).send();
			}

			logger.trace('order created:', res.order);
			window.location.href = `/order/${res.order.id}`;
		} catch (e) {
			logger.error('Error creating order:', e);
			mb.setTitle('Error', false);
			mb.setBody(e.message);
		}
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	async componentDidMount() {
		var mb = messageBox('Loading data...', null, null, true);
		try {
			await this.loadCatalog();
			await this.loadMarketplaceAndPOS();
			this.setState({ready: true});
			mb.close();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderToolbar() {
		return (
			<ToolbarIcon name="save" title="Save" onClick={() => this.form && this.form.submit()} />
		);
	}

	render() {
		if (!this.state.ready) {return '';}
		return (
			<BasePage
				title="Add Order"
				className="stage-content"
				toolbarOpen
				toolbar={this.renderToolbar()}
			>
				<Form
					ref={f => {this.form = f;}}
					schema={OrderSchema}
					uiSchema={OrderSchemaUI}
					showButtons={false}
					formData={this.state.order}
					submit={this.onSubmit}
					outlinedFields
				/>
			</BasePage>
		);
	}

	// </editor-fold> // Render
}

AddOrderPage.propTypes = {};
AddOrderPage.defaultProps = {};

AddOrderPage.contextTypes = {
	setAppBarComponents: PropTypes.func
};


export default AddOrderPage;

import React from 'react';
import PropTypes from 'prop-types';
import { messageBox } from 'ui-core';
import { utils, api, logger } from 'client-services';
import { makeStyles } from 'tss-react/mui';

var useClasses = makeStyles()(theme => ({
	viewsTable: {
		border: '1px solid black',
		borderCollapse: 'collapse',
		'& td': {
			border: '1px solid black',
			borderCollapse: 'collapse',
			padding: 5,
			fontSize: '0.9em'
		}
	}
}));

function CreationViews(props) {
	let { classes } = useClasses();
	let { views } = props;

	views.forEach(v => {
		let diff = utils.dateDiff(v.start, v.end, 's');
		v.duration = isNaN(diff) ? '' : utils.secondsToString(diff, 'mm:ss');
	});

	return (
		<div>
			<table className={classes.viewsTable}>
				<tbody>
					<tr>
						<td></td>
						<td>Start</td>
						<td>End</td>
						<td>Duration (mm:ss)</td>
					</tr>
					{props.views.map((v, i) =>
						<tr key={v.id}>
							<td>{i + 1}</td>
							<td>{utils.toDateString(v.start)}</td>
							<td>{utils.toDateString(v.end)}</td>
							<td>{v.duration} </td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

CreationViews.propTypes = {
	views: PropTypes.arrayOf(PropTypes.object)
};

CreationViews.show = async function show(pid) {
	let mb = messageBox('Loading', '', messageBox.Buttons.OK, true);

	logger.trace('Fetching project creation views, pid:', pid);
	let res = await api.project.projectViews({ id: pid }).send();
	logger.trace('Got project views:', res);
	let views = Array.isArray(res?.views) ? res.views : [];

	let body = <CreationViews views={views} />;
	mb.setTitle('Creation Views', false);
	mb.setBody(body);
};

export default CreationViews;

import React from 'react';
import { Login, withProtected } from 'app-center-common';
import TestPage from '../test-page.jsx';
import UsersGrid from '../../components/users/users-grid.jsx';
import UserSpotlightPage from '../../components/user-spotlight/user-spotlight-page.jsx';
import BOUsersGrid from '../../components/bo-users/bo-users-grid.jsx';
import { SalesReport, ShippingReport, POSPage, AddOrderPage, OrderPage, Orders, BuyersReport, CouponsPage, ImportOrdersPage, ImportShipmentsPage } from '../../components/store';
import ProjectsPage from '../../components/projects/projects-page.jsx';
import ProjectPage from '../../components/projects/project-page.jsx';
import { SalesDashboard, MarketingDashboard } from '../../components/dashboards';
import MembershipsPage from '../../components/membership/memberships-page.jsx';
import MemberPage from '../../components/membership/member-page.jsx';
import MembershipInfoPage from '../../components/membership/membership-info-page.jsx';
import Experiments from '../../components/experiments/experiments.jsx';
import AppConfigPage from '../../components/app-config-page';
import SharedPromosPage from '../../components/membership-promos/shared/shared-promos-page.jsx';
import PushNotificationPage from '../../components/push-notifications/push-notification-page.jsx';
import PromoSearchPage from '../../components/membership-promos/promo-search-page.jsx';
import PersonalPromosPage from '../../components/membership-promos/personal/personal-promos-page.jsx';

function getRoutes() {
	let rootRoutes = [
		{ path: 'login', element: <Login /> },
		{ path: 'test', element: withProtected(<TestPage />) }
	];

	let contentRoutes = [
		{ index: true, element: withProtected(<div>&lt;== Select item from the menu</div>) },
		{ path: '/projects', element: withProtected(<ProjectsPage />)},
		{ path: '/memberships', element: withProtected(<MembershipsPage />)},
		{ path: '/member/:userId', element: withProtected(<MemberPage />)},
		{ path: '/membership/:id', element: withProtected(<MembershipInfoPage />)},
		{ path: '/users/app', element: withProtected(<UsersGrid />) },
		{ path: '/users/bo', element: withProtected(<BOUsersGrid />) },
		{ path: '/user/:userId/spotlight', element: withProtected(<UserSpotlightPage />) },
		{ path: '/orders', element: withProtected(<Orders />)},
		{ path: '/reports/sales', element: withProtected(<SalesReport />)},
		{ path: '/reports/shipping', element: withProtected(<ShippingReport />)},
		{ path: '/reports/buyers', element: withProtected(<BuyersReport />)},
		{ path: '/order/new', element: withProtected(<AddOrderPage />)},
		{ path: '/order/:orderId', element: withProtected(<OrderPage />)},
		{ path: '/store/coupons', element: withProtected(<CouponsPage />)},
		{ path: '/store/pos', element: withProtected(<POSPage />)},
		{ path: '/store/import/orders', element: withProtected(<ImportOrdersPage />)},
		{ path: '/store/import/shipments', element: withProtected(<ImportShipmentsPage />)},
		{ path: '/experiments', element: withProtected(<Experiments />)},
		{ path: '/project/:pid', element: withProtected(<ProjectPage />)},
		{ path: '/app/config', element: withProtected(<AppConfigPage />)},
		{ path: '/dashboard/sales', element: withProtected(<SalesDashboard />)},
		{ path: '/dashboard/marketing', element: withProtected(<MarketingDashboard />)},
		{ path: '/notifications', element: withProtected(<PushNotificationPage />)},
		{ path: '/promo/search', element: withProtected(<PromoSearchPage />)},
		{ path: '/promo/personal', element: withProtected(<PersonalPromosPage />)},
		{ path: '/promo/shared', element: withProtected(<SharedPromosPage />)},
	];

	return { rootRoutes, contentRoutes };
}

export default getRoutes;
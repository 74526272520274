.push-notification-page {
	display: flex;
	flex-direction: row;

	.form-data {
		flex: 0.4;
	}

	.preview {
		flex: 0.6;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.notification {
			width: 300px;
			border: 1px solid;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 5px;
			border-radius: 20px;

			.logo > img {
				height: 24px;
			}

			.content {
				font-size: 0.85em;
				margin-left: 10px;
				padding: 5px 0;
				overflow: auto;

				& > div {
					padding: 2px 0;
				}

				.header {
					font-size: 0.9em;
				}

				.title {
					font-weight: bold;
				}
			}

			.message {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.message.opened {
				white-space: unset;
			}

			.image > img {
				width: 45px;
				max-height: 100%;
				margin: 0 5px;
			}
		}
	}
}

import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import { messageBox, AddButtonFAB, Form, ToolbarIcon } from 'ui-core';
import PromosGridHelper from './promos-grid-helper.jsx';
import ReportPage from '../../report-page/report-page.jsx';
import { schema, uiSchema } from './shared-promo.schema.js';
import { api, logger } from 'client-services';

let SEARCH_FIELDS = {
	code: {},
	name: {},
	id: { },
};

class SharedPromosPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeOnly: false
		};

		this.reportPage = null;
		this.columns = PromosGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
	}

	openEditPromoModal(promo) {
		var resolve;
		let p = new Promise(res => { resolve = res; });
		var edit = !!promo;
		promo = promo || {};

		var mb;
		function formClosed(data) {
			mb.close(50);
			resolve(data);
		}

		let formData = Object.assign({}, promo);
		var form = (
			<Form
				formData={formData}
				schema={schema}
				uiSchema={edit ? {} : uiSchema}
				submitText={edit ? 'Update' : 'Add'}
				buttonsAlign="right"
				submit={formClosed.bind(this)}
				showErrorMessageBox
			/>
		);

		mb = messageBox('Promo details', form, []);

		return p;
	}

	async upsertPromo(src) {
		let promo = await this.openEditPromoModal(src);
		if (!promo) {return;}

		if (src?.hitCount > 0 || src?.usageCount > 0) {
			if (promo.code !== src.code) {
				messageBox('Cannot update CODE if promo has been used');
				return;
			}

			for (let p in promo.utmInfo) {
				if (promo.utmInfo[p] !== src.utmInfo[p]) {
					messageBox('Cannot update UTM if promo has been used');
					return;
				}
			}
		}

		var mb = messageBox('Upserting promo...', '', null, true);
		try {
			let func = promo.id ? api.membership.updatePromoCode : api.membership.insertPromoCode;
			let res = await func(promo).send();
			logger.trace('promo upsert successfully, response:', res);
			mb.close(10);
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error upserting promo:', ex);
			mb.setTitle('Error', false);

			if (ex.code === 'DUPLICATE') {
				mb.setBody('Error updating promo. DUPLICATE CODE.');
			} else {
				mb.setBody(ex.message);
			}
		}
	}

	async loadMore(count, skip, sort, search, showAllUsers) {
		let { activeOnly } = this.state;
		var params = {
			count,
			skip,
			sort,
			q: `+type:${CONSTANTS.MembershipPromoType.Shared} ${search}`,
			activeOnly
		};

		logger.trace('Fetching memberships promos, params:', params);
		let res = await api.membership.getPromoCodes(params).send();

		// convert dates to the expected format in schema
		res.promoCodes.forEach(p => {
			['startDate', 'endDate'].forEach(prop => {
				if (!p[prop]) { return; }
				let d = new Date(p[prop]);
				if (!d.getTime()) { return; }
				p[prop] = d.toISOString().split('T')[0];
			});
		});
		logger.trace('Got promos:', res);
		return { rows: res.promoCodes, total: res.total };
	}

	// #region Render

	renderGridToolbar(selected) {
		let items = [];

		if (selected) {
			items.push({ key: 'edit', title: 'Edit', onClick: () => this.upsertPromo(selected), icon: 'edit' });
		}

		return items;
	}

	renderInnerToolbar() {
		let { activeOnly } = this.state;
		let icon = activeOnly ? 'toggle_on' : 'toggle_off';
		return (
			<div style={{ display: 'flex', overflow: 'auto', alignItems: 'center' }}>
				<span>Active Only</span>
				<ToolbarIcon name={icon} size="large" onClick={() => {
					this.setState({ activeOnly: !activeOnly }, () => this.reportPage.reset());
				}} />
			</div>
		);
	}

	render() {
		return (
			<>
				<ReportPage
					ref={e => { this.reportPage = e; }}
					loadMore={this.loadMore}
					columns={this.columns}
					title="Membership Promos"
					toolbar={this.renderInnerToolbar()}
					toolbarOpen={true}
					renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
					showSearch={true}
					searchFields={SEARCH_FIELDS}
					gridProps={{
						rowHeight: PromosGridHelper.rowHeight,
						headerRowHeight: PromosGridHelper.headerRowHeight,
						sortColumn: 'name',
						sortDirection: 1
					}}
				/>
				<AddButtonFAB onClick={() => this.upsertPromo()} />
			</>
		);
	}

	// #endregion Render
}

export default SharedPromosPage;

import React from 'react';

const STYLES = {
	main: {
		width: '100%',
		height: '100%'
	}
};

function TestPage() {
	return (
		<div style={STYLES.main}>
			TEST PAGE
		</div>
	);
}

export default TestPage;

import CONSTANTS from '../../../lib/constants.js';
import { compileSchema, toEnumSchema } from 'ui-core';

const UTM_REGEX = '^[a-zA-Z_+-\.]+$';

/** @type {import('json-schema').JSONSchema7} */
let schema = {
	type: 'object',
	required: ['type', 'name', 'startDate', 'entitlement'],
	properties: {
		name: { type: 'string' },
		type: { type: 'string', readOnly: true, default: CONSTANTS.MembershipPromoType.Shared},
		enabled: { type: 'boolean', default: false },
		usageLimit: { type: 'integer', minimum: 0 },
		startDate: { type: 'string', format: 'date'},
		endDate: { type: ['null', 'string'], format: 'date'},
		utmInfo: {
			type: 'object',
			required: ['utm_source', 'utm_channel'],
			properties: {
				utm_source: {type: 'string', pattern: UTM_REGEX},
				utm_channel: {type: 'string', pattern: UTM_REGEX},
				utm_campaign: {type: 'string', pattern: UTM_REGEX},
				utm_marketplace: {type: 'string', pattern: UTM_REGEX},
			}
		},
		entitlement: {
			type: 'object',
			required: ['type'],
			properties: {
				type: { type: 'string', readOnly: true, default: CONSTANTS.PromoEntitlementType.SubscriptionOffering },
				params: {
					type: 'object',
					properties: {
						kind: toEnumSchema(CONSTANTS.PromoSubscriptionOfferingKind, CONSTANTS.PromoSubscriptionOfferingKind.Marketing)
					}
				}
			}
		},
		code: { type: 'string' },
		id: { type: 'string', readOnly: true },
	}
};

let uiSchema = {
	code: {'ui:widget': 'hidden'}
};

schema = compileSchema(schema);
export { schema, uiSchema };
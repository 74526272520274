import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../../lib/constants.js';
import { useSearchParams } from 'react-router-dom';
import {messageBox, Form, ToolbarIcon} from 'ui-core';
import ReportPage from '../../report-page/report-page.jsx';
import CouponsGridHelper from './coupons-grid-helpers.jsx';
import { api, logger } from 'client-services';
import { schema, uiSchema } from './coupon.schema.js';


let SEARCH_FIELDS = {
	code: {},
	id: {$ref: '#/definitions/MongoObjectID', description: 'project id'},
};

class CouponsPage extends React.Component {
	constructor(props) {
		super(props);

		this.columns = CouponsGridHelper.getColumns();
		this.reportPage = null;

		this.loadMore = this.loadMore.bind(this);
		this.onCouponEdit = this.onCouponEdit.bind(this);
		this.onAddCoupon = this.onAddCoupon.bind(this);
		this.onDeleteCoupon = this.onDeleteCoupon.bind(this);
	}

	// <editor-folder desc="// Methods {...}"> // Methods

	async loadMore(count, skip, sort, search, showAllUsers) {
		logger.trace('Fetching coupons, count: %s, skip: %s:', count, skip);
		this.fetching = true;
		var params = {
			count,
			skip,
			sort,
			q: search
		};

		logger.trace('Fetching coupons:', params);
		let res = await api.store.getCoupons(params).send();
		logger.trace('Got coupons:', res);
		return { rows: res.coupons, total: res.total };
	}

	openEditCouponModal(coupon) {
		var resolve;
		let p = new Promise(res => { resolve = res; });
		var edit = !!coupon;
		coupon = coupon || {};

		var mb;
		function formClosed(data) {
			if (data) {
				if (data.discountType === CONSTANTS.CouponDiscountType.Percent && data.discountAmount > 100) {
					messageBox('Percentage discount cannot exceed 100%');
					return;
				}

				if (typeof data.startDate === 'string' && data.startDate) {
					data.startDate = new Date(data.startDate);
				}

				if (typeof data.endDate === 'string' && data.endDate) {
					data.endDate = new Date(data.endDate);
				}

				if (data.startDate && data.endDate && data.endDate < data.startDate) {
					messageBox('End data cannot be before start date');
					return;
				}

				var pp = data.perProduct.trim();
				if (pp === '') {
					delete data.perProduct;
				} else {
					try {
						pp = JSON.parse(data.perProduct);
						Object.keys(pp).forEach(sku => {
							if (!(/^[0-9]+$/.test(sku))) {
								throw new Error(`Invalid SKU on Discount Per Product: ${sku}`);
							}
						});

						Object.values(pp).forEach(price => {
							if (typeof price !== 'number' || isNaN(price) || price < 0 || (data.discountType === CONSTANTS.CouponDiscountType.Percent && price > 100)) {
								throw new Error(`Invalid Price on Discount Per Product: ${price}`);
							}
						});

						data.perProduct = pp;
					} catch (ex) {
						messageBox(ex.message);
						return;
					}
				}

				if (!data.description) {delete data.description;}
				if (!data.usageLimit) {delete data.usageLimit;}
			}

			mb.close(50);
			resolve(data);
		}

		var formData = {
			code: coupon.code || '',
			enabled: typeof coupon.enabled === 'boolean' ? coupon.enabled : true,
			description: coupon.description || '',
			receiptLine: coupon.receiptLine || '',
			discountType: typeof coupon.discountType === 'number' ? coupon.discountType : CONSTANTS.CouponDiscountType.Fixed,
			discountScope: typeof coupon.discountScope === 'number' ? coupon.discountScope : CONSTANTS.CouponDiscountScope.Cart,
			discountAmount: typeof coupon.discountAmount === 'number' ? coupon.discountAmount : 1,
			perProduct: typeof coupon.perProduct === 'object' && coupon.perProduct ? JSON.stringify(coupon.perProduct, null, '  ') : '',
			usageLimit: coupon.usageLimit || 0,
			startDate: coupon.startDate || '',
			endDate: coupon.endDate || ''
		};

		var form = (
			<Form
				formData={formData}
				schema={schema}
				uiSchema={uiSchema}
				submitText={edit ? 'Update' : 'Add'}
				buttonsAlign="right"
				submit={formClosed.bind(this)}
				showErrorMessageBox
			/>
		);

		mb = messageBox('Coupon details', form, []);

		return p;
	}

	// </editor-folder> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	async onAddCoupon() {
		var coupon = await this.openEditCouponModal();
		if (!coupon) {return;}
		var mb = messageBox('Adding coupon...', '', null, true);
		api.store.insertCoupon(coupon).send().then(res => {
			logger.trace('Coupon inserted, response:', res);
			mb.close(10);
			this.reportPage.reset();
		}).catch(e => {
			logger.error('Error inserting coupon:', e);
			mb.setTitle('Error inserting coupon', false);
			mb.setBody(e.message);
		});
	}

	async onDeleteCoupon(coupon) {
		if (!coupon) { return; }
		var id = coupon.id;

		var res = await messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) {return;}

		logger.trace('Deleting coupon with id:', id);
		var mb = messageBox('Deleting coupon...', '', null, true);
		try {
			await api.store.deleteCoupon({id}).send();
			mb.setTitle('Success');
			mb.close(500);
			this.reportPage.reset();
		} catch (e) {
			logger.error('Error deleting coupon:', e);
			mb.setTitle('Error deleting coupon', false);
			mb.setBody(e.message);
		}
	}

	async onCouponEdit(coupon) {
		if (!coupon) { return; }
		var id = coupon.id;
		coupon = await this.openEditCouponModal(coupon);
		if (!coupon) {return;}
		coupon.id = id;
		var mb = messageBox('Updating coupon...', '', null, true);
		api.store.updateCoupon(coupon).send().then(res => {
			logger.trace('Coupon updated, response:', res);
			mb.close(10);
			this.reportPage.reset();
		}).catch(e => {
			logger.error('Error updating coupon:', e);
			mb.setTitle('Error updating coupon', false);
			mb.setBody(e.message);
		});
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Render {...}">

	renderInnerToolbar() {
		return (
			<ToolbarIcon name="playlist_add" title="Add" onClick={this.onAddCoupon} />
		);
	}

	renderGridToolbar(selected) {
		var items = [];
		if (selected) {
			items = [
				{
					key: 'edit',
					title: 'Edit',
					onClick: () => this.onCouponEdit(selected),
					icon: 'edit'
				},
				{
					key: 'delete',
					title: 'Delete',
					onClick: () => this.onDeleteCoupon(selected),
					icon: 'delete'
				}
			];
		}

		return items;
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				title="Coupons"
				toolbar={this.renderInnerToolbar()}
				toolbarOpen={true}
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
				gridProps={{
					rowHeight: CouponsGridHelper.rowHeight,
					headerRowHeight: CouponsGridHelper.headerRowHeight
				}}
			/>
		);
	}

	// </editor-fold> // Render
}

CouponsPage.propTypes = {
	searchParams: PropTypes.object
};

export default function WrappedCouponsPage(props) {
	let [searchParams] = useSearchParams();
	return <CouponsPage searchParams={searchParams} {...props} />;
};

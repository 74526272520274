import React from 'react';
import {messageBox, snackbar, Card, Form, AddButtonFAB, ToolbarIcon} from 'ui-core';
import { BasePage } from 'app-center-common';
import {api, logger, utils} from 'client-services';

import POSSchema from './pos.schema';
import POSSchemaUI from './pos-ui.schema';
import './pos-page.css';

var POSViewSchemaUI = utils.extend(true, {}, POSSchemaUI);
POSViewSchemaUI.inventory = POSViewSchemaUI.inventory || {};
POSViewSchemaUI.inventory['ui:options'] = {addable: false, removable: false, orderable: false};

class POSPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pos: []
		};

		this.catalog = {};
		this.upsertPOS = this.upsertPOS.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	async loadCatalog() {
		var res = await api.store.getCatalog().send();
		if (!res || !res.catalog || typeof res.catalog !== 'object') {
			throw new Error('Invalid catalog response');
		}

		this.catalog = res.catalog;
		var skus = Object.keys(res.catalog);
		if (skus.length < 1) {
			throw new Error('Catalog has no SKUs defined...');
		}

		// Set the skus as valid enum in the schema
		POSSchema.definitions.Item.properties.sku.enum = skus;
		POSSchema.definitions.Item.properties.sku.enumNames = skus.map(sk => `${sk} - ${res.catalog[sk].desc}`);
	}

	async loadPOS() {
		logger.trace('Calling `api.store.getPointsOfSale`');
		var res = await api.store.getPointsOfSale().send();
		logger.trace('Got points-of-sale response:', res);
		if (!res || !Array.isArray(res.pointsOfSale)) {
			logger.error('Invalid points-of-sale response, no response nor pointsOfSale array');
			throw new Error('Invalid points-of-sale response');
		}

		// Convert inventory from object to array
		res.pointsOfSale.forEach(pos => {
			var inv = pos.inventory || {};
			pos.inventory = Object.keys(inv).map(sku => ({sku, quantity: inv[sku]}));
		});

		this.setState({pos: res.pointsOfSale});
	}

	showPOSEditor(pos = {}) {
		return new Promise(res => {
			var mb;
			var form =
				<Form
					formData={pos}
					schema={POSSchema}
					uiSchema={POSSchemaUI}
					submit={formData => {
						mb.close(10);
						if (formData) {
							// Convert the inventory array to object
							var invArr = formData.inventory || [];
							formData.inventory = {};
							invArr.forEach(elem => {
								formData.inventory[elem.sku] = elem.quantity;
							});
						}

						res(formData);
					}}
				>
					<div>
						<button className="btn btn-info" type="submit" style={{marginRight: 15}}>Submit</button>
						<button className="btn btn-danger" type="button" onClick={() => mb.close(10).then(res)}>Cancel</button>
					</div>
				</Form>;

			mb = messageBox('Add POS', form, []);
		});
	}

	async upsertPOS(pos) {
		if (!pos) {return;}
		var mb = messageBox('Upserting POS...', null, null, true);
		try {
			var res;
			if (pos.id) {
				logger.trace('Calling api.store.updatePOS with params:', pos);
				res = await api.store.updatePOS(pos).send();
			} else {
				logger.trace('Calling api.store.insertPOS with params:', pos);
				res = await api.store.insertPOS(pos).send();
			}

			logger.trace('Got response:', res);
			await this.loadPOS();
			mb.close();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	async onDelPOS(pos) {
		if (!pos || !pos.id) {return;}
		logger.trace('Deleting POS with id:', pos.id);

		var res = await messageBox('Are you sure?', '', [messageBox.Buttons.Cancel, messageBox.Buttons.Yes]).promise;
		if (res !== messageBox.Buttons.Yes) {return;}

		try {
			await api.store.deletePOS({id: pos.id}).send();
			snackbar('POS Deleted');
			this.loadPOS();
		} catch (err) {
			messageBox('Error', err.message);
		}
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	componentDidMount() {
		var mb = messageBox('Loading Catalog...', null, null, true);
		this.loadCatalog().then(() => {
			mb.setTitle('Fetching points of sale...');
			return this.loadPOS();
		}).then(() => {
			mb.close();
		}).catch(ex => {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		});
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderPOS(pointsOfSale) {
		return (
			<div className="pos-list">
				{pointsOfSale.map(pos =>
					<Card key={pos.id} className="pos" title={pos.name}
						actions={
							<>
								<ToolbarIcon name="edit" onClick={() => this.showPOSEditor(pos).then(this.upsertPOS)} />
								<ToolbarIcon name="delete" onClick={() => this.onDelPOS(pos)} style={{ color: 'red' }} right />
							</>
						}
					>
						<div className="group">
							<div className="title">Contact</div>
							<div>{pos.contact.name} - {pos.contact.email}</div>
						</div>

						<div className="group">
							<div className="title">Location</div>
							<div>{pos.location.address.name}</div>
							<div>{pos.location.address.address1}</div>
							<div>{pos.location.address.address2}</div>
							<div>{pos.location.address.city}, {pos.location.address.state}, {pos.location.address.zip}</div>
							<div>{pos.location.address.country}</div>
						</div>

						<div className="group">
							<div className="title">Inventory</div>
							<table>
								<tbody>
									<tr>
										<th>SKU</th>
										<th>Desc</th>
										<th>Qty</th>
									</tr>
									{pos.inventory.map(inv => {
										var desc = this.catalog[inv.sku] ? this.catalog[inv.sku].desc : '';
										return <tr key={inv.sku}>
											<td>{inv.sku}</td>
											<td>{desc}</td>
											<td>{inv.quantity}</td>
										</tr>;
									})}
								</tbody>
							</table>
						</div>

						<div className="group subdued">
							<div className="title">ID</div>
							<div>{pos.id}</div>
						</div>
					</Card>
				)}
			</div>
		);
	}

	render() {
		return (
			<BasePage title="POS" className='pos-page'>
				{this.state.pos.length < 1 && <div>No points of sale found...</div>}
				{this.state.pos.length > 0 && this.renderPOS(this.state.pos)}
				<AddButtonFAB onClick={() => this.showPOSEditor().then(this.upsertPOS)} />
			</BasePage>
		);
	}

	// </editor-fold> // Render
}

export default POSPage;

/**
 * Sort an array
 * @param {Array} items - Elements to sort
 * @param {1|-1} dir - Sort direction
 * @param {String} prop - Property of an element to sort by if the element is an object
 */
export default function arraySort(items, dir, prop) {
	items.sort((a, b) => {
		let v1 = a;
		let v2 = b;
		if (typeof a === 'object' && a && prop) {
			v1 = a[prop];
		}

		if (typeof b === 'object' && b && prop) {
			v2 = b[prop];
		}

		let res = 0;
		if (v1 > v2) {
			res = 1;
		} else if (v1 < v2) {
			res = -1;
		}

		return dir * res;
	});
}

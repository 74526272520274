import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MaterialIcon } from 'ui-core';
import { config } from 'client-services';

let AMPLITUDE_SEARCH = config.get('data:amplitudeSearchLink');

function AmplitudeLink(props) {
	var link = `${AMPLITUDE_SEARCH}${props.userGUID}`;
	return (
		<a href={link} target="_blank" rel="noopener noreferrer" style={{verticalAlign: 'middle', alignSelf: 'center'}}>
			<img src="https://res.cloudinary.com/cupixel/image/upload/assets/amplitude_logo.svg" />
		</a>
	);
}

AmplitudeLink.propTypes = {
	userGUID: PropTypes.string.isRequired
};

function UserSpotlightLink(props) {
	return (
		<Link to={`/user/${props.userGUID}/spotlight`} target="_blank" style={{color: '#acacac', textAlign: 'center'}}>
			<MaterialIcon name="highlight" style={{fontSize: 32}} />
		</Link>
	);
}

UserSpotlightLink.propTypes = {
	userGUID: PropTypes.string.isRequired
};

export { AmplitudeLink, UserSpotlightLink };

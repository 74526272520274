import React from 'react';
import {GridFormatters} from 'ui-core';

var OrdersGridHelper = {
	rowHeight: 110,
	headerRowHeight: 35
};

OrdersGridHelper.getColumns = function getColumns() {
	return [
		'email',
		{
			key: 'shipping@200',
			formatter: shippingFormatter
		},
		{
			key: 'items@200',
			formatter: itemsFormatter
		},
		{
			key: 'amount@100',
			formatter: data => data.row.invoice.amount
		},
		{
			key: 'discount@100',
			formatter: data => data.row.invoice.discount
		},
		{
			key: 'shippingCost@120',
			formatter: data => data.row.invoice.shipping
		},
		{
			key: 'errors',
			formatter: GridFormatters.array()
		}
	];
};

function itemsFormatter({row}) {
	/** @type {Array.<JSX.Element> | string} */
	var res = '';
	var items = row.items;
	if (Array.isArray(items) && items.length > 0) {
		res = items.map((item, i) =>
			<div key={i} style={{fontSize: '0.9em'}}>
				{item.quantity} X {item.sku} (${item.price})
			</div>
		);
	}

	return res;
}

function shippingFormatter({ row }) {
	let shipping = row.shipping;

	/** @type {JSX.Element | string} */
	var res = '';
	if (typeof shipping === 'object' && shipping) {
		var addr = shipping.address;
		res = (
			<div style={{fontSize: '0.9em'}}>
				<div>{addr.name}</div>
				<div>{addr.address1}</div>
				{addr.address2 ? <div>{addr.address2}</div> : ''}
				<div>{addr.city}</div>
				<div>{addr.state} {addr.zip} {addr.country}</div>
			</div>
		);
	}

	return res;
}

export default OrdersGridHelper;

.import-shipments-page .title {
	font-size: 1.5em;
    padding: 10px 0;
}

.import-shipments-page .orders {
	margin-top: 10px;
}

.import-shipments-page table,
.import-shipments-page tr,
.import-shipments-page td {
	border: 1px solid black;
	border-collapse: collapse;
	padding: 4px;
}

.import-shipments-page .status {
	margin-left: 5px;
	color: red;
}
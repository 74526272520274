import * as XLSX from 'xlsx';
var CSVUtils = {};

CSVUtils.readCSVFile = function readCSVFile(file) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = function onload(e) {
			var data = e.target.result;
			var workbook = XLSX.read(data, {
				type: 'binary'
			});

			var res = [];
			if (workbook.SheetNames.length > 0) {
				res = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
				res = res.map(o => {
					var oo = {};
					for (var p in o) {
						oo[p.trim().toLowerCase()] = o[p];
					}

					return oo;
				});
			}

			resolve(res);
		};

		reader.onerror = function onerror(ex) {
			reject(ex);
		};

		reader.readAsBinaryString(file);
	});
};

export default CSVUtils;

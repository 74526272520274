import React from 'react';
import PropTypes from 'prop-types';
import { MaterialIcon } from 'ui-core';
import { utils } from 'client-services';
import clsx from 'clsx';

function EventDetails(props) {
	let e = props.event;
	let title;
	let icon = 'list';
	let iconType = '';
	let subTitles = [];
	let handled = !e.isErrorState;

	switch (e.type) {
		case 'INITIAL_PURCHASE':
			icon = e.isTrial ? 'hourglass_top' : 'attach_money';
			iconType = 'good';
			title = <span>Started {e.isTrial ? 'a trial of' : ''} <b>{e.productId}</b> with <b>{e.provider}</b></span>;
			subTitles.push(`Purchased: ${utils.toDateString(e.purchasedAtMS)}`);
			subTitles.push(`Expiration: ${utils.toDateString(e.expirationAtMS)}`);
			break;
		case 'APP_INITIAL_PURCHASE':
			icon = 'smartphone';
			iconType = 'good';
			title = <span>Subscribed in app for {e.isTrial ? 'a trial of' : ''} <b>{e.productId}</b> with <b>{e.provider}</b></span>;
			break;
		case 'SYSTEM_INITIAL_PURCHASE':
			icon = 'auto_fix';
			title = 'Subscribed from BO';
			subTitles.push(`Expiration: ${utils.toDateString(e.expirationAtMS) || 'None'}`);
			break;
		case 'PROMO_MEMBERSHIP_ACTIVATION':
			icon = 'redeem';
			iconType = 'good';
			title = <span>Activated membership of <b>{e.productId}</b> from promo {e.redeemCode}</span>;
			subTitles.push(`Expiration: ${utils.toDateString(e.expirationAtMS) || 'None'}`);
			break;
		case 'RENEWAL':
			icon = 'autorenew';
			title = <span>{e.isTrialConversion ? 'Converted from trial to' : 'Renewed their subscription of'} <b>{e.productId}</b></span>;
			subTitles.push(`Renewal: ${utils.toDateString(e.purchasedAtMS)}`);
			subTitles.push(`Expiration: ${utils.toDateString(e.expirationAtMS)}`);
			break;
		case 'SUBSCRIPTION_EXTENDED':
			icon = 'update';
			title = <span>Expiration extended to: ${utils.toDateString(e.expirationAtMS)}</span>;
			break;
		case 'CANCELLATION':
			if (e.cancelReason === 'CUSTOMER_SUPPORT') {
				icon = 'attach_money';
				iconType = 'bad';
				title = <span>Was issued refund of <b>{e.productId}</b></span>;
			} else {
				icon = 'warning';
				iconType = 'warn';
				title = <span>Had their subscription of <b>{e.productId}</b> Canceled due to: {e.cancelReason}</span>;
			}
			subTitles.push(`Expiration: ${utils.toDateString(e.expirationAtMS)}`);
			break;
		case 'UNCANCELLATION':
			icon = 'refresh';
			iconType = 'good';
			title = <span>Un-Canceled <b>{e.productId}</b></span>;
			break;
		case 'EXPIRATION':
			icon = 'close';
			iconType = 'bad';
			title = <span>Had their subscription of <b>{e.productId}</b> Expire due to: {e.expirationReason}</span>;
			break;
		case 'SYSTEM_EXPIRATION':
			icon = 'close';
			iconType = 'bad';
			title = <span>Had their subscription expired by System.<b>{e.productId ? ' Product: ' + e.productId : ''}</b></span>;
			break;
		case 'BILLING_ISSUE':
			icon = 'credit_card_off';
			iconType = 'warn';
			title = <span>Had a billing issue of <b>{e.productId}</b></span>;
			subTitles.push(`Grace: ${utils.toDateString(e.gracePeriodExpirationAtMS) || 'None'}`);
			break;
		case 'SUBSCRIPTION_PAUSED':
			icon = 'pause';
			iconType = 'warn';
			title = <span>Had their subscription of <b>{e.productId}</b> Paused</span>;
			subTitles.push(`Auto Resume: ${utils.toDateString(e.autoResumeAtMS)} || None`);
			break;
		case 'PRODUCT_CHANGE':
			icon = 'sync_alt';
			iconType = 'warn';
			title = <span>Changed their subscription product from <b>{e.productId}</b> to <b>{e.newProductId}</b></span>;
			break;
		case 'SYSTEM_JOINING_GIFT_REQUESTED':
			icon = 'featured_seasonal_and_gifts';
			title = <span>Joining gift requested</span>;
			subTitles.push(`Gift SKU: ${e.giftSKU || 'NONE'}`);
			break;
		case 'SYSTEM_JOINING_GIFT_SENT':
			icon = 'local_shipping';
			title = <span>Joining gift sent</span>;
			subTitles.push(`Gift SKU: ${e.giftSKU || 'NONE'}`);
			break;
		case 'SYSTEM_JOINING_GIFT_REMINDER':
			icon = 'notifications';
			title = <span>Joining gift reminder</span>;
			break;
		case 'SYSTEM_UNCANCELLATION_EXPERIMENT':
			icon = 'science';
			title = <span>Sent uncancellation gift upgrade offer email</span>;
			break;
		case 'SYSTEM_NOTIFICATION_EMAIL':
			icon = 'outgoing_mail';
			title = <span>{e.comment}</span>;
			break;
		default:
			icon = 'question_mark';
			title = `Unsupported event. Type: ${e.type}`;
	}

	return (
		<div className="event">
			<div className={clsx('icon', iconType)}>
				<MaterialIcon name={icon} onClick={props.onClick} />
			</div>
			<div>
				<div>{title}</div>
				<div className="details">
					<div className="processed-on">
						<span>{utils.toDateString(e.eventTimeStampMS || e.createdAt)}</span>
						{!handled && <span className="error"> (Event not processed due to error)</span>}
					</div>
					{subTitles.map((t, i) => <div key={i}>{t}</div>)}
				</div>
			</div>
		</div>
	);
}

EventDetails.propTypes = {
	event: PropTypes.object,
	onClick: PropTypes.func
};

export default EventDetails;

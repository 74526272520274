import { compileSchema } from 'ui-core';

/** @type {import('json-schema').JSONSchema7} */
var schema = {
	title: 'New Order',
	type: 'object',
	required: ['email', 'currency'],
	properties: {
		marketplace: {
			type: 'string',
			enum: []
		},
		marketplaceOrderId: {
			type: 'string'
		},
		pos: {
			type: 'string',
			enum: []
		},
		email: {
			type: 'string',
			format: 'email'
		},
		currency: {
			type: 'string',
			default: 'USD',
			pattern: '^[A-Z]{3}$'
		},
		shipping: {
			type: 'object',
			properties: {
				type: {
					type: 'string',
					enum: ['None', 'Address'],
					default: 'Address'
				},
				markAsDelivered: {
					type: 'boolean',
					default: false
				},
				freeShipping: {
					type: 'boolean',
					default: false
				},
				service: {
					type: 'string',
					oneOf: [
						{ enum: ['Default'], title: 'Default' },
						{ enum: ['Surepost'], title: 'UPS - Surepost' },
						{ enum: ['Ground'], title: 'UPS - Ground' },
						{ enum: ['SecondDay'], title: 'UPS - 2nd Day Air' },
						{ enum: ['NextDay'], title: 'UPS - Next Day Air' }
					],
					default: 'Default'
				}
			},
			dependencies: {
				type: {
					oneOf: [
						{
							properties: {
								type: {
									enum: ['None']
								}
							}
						},
						{
							properties: {
								type: {
									enum: ['Address']
								},
								address: {
									$ref: '#/definitions/Address'
								}
							}
						}
					]
				}
			}
		},
		billing: {
			type: 'object',
			properties: {
				type: {
					type: 'string',
					enum: ['None', 'SameAsShipping', 'Address'],
					default: 'None'
				}
			},
			dependencies: {
				type: {
					oneOf: [
						{
							properties: {
								type: {
									enum: ['None', 'SameAsShipping']
								}
							}
						},
						{
							properties: {
								type: {
									enum: ['Address']
								},
								address: {
									$ref: '#/definitions/Address'
								}
							}
						}
					]
				}
			}
		},
		invoice: {
			type: 'object',
			properties: {
				discount: {
					type: 'number',
					minimum: 0,
					default: 0
				},
				markAsPaid: {
					type: 'boolean',
					default: false
				}
			}
		},
		items: {
			type: 'array',
			minItems: 1,
			items: {
				$ref: '#/definitions/Item'
			}
		},
		updatePOSInventory: {
			type: 'boolean',
			default: false,
			title: 'Update POS Inventory'
		}
	},
	definitions: {
		Address: {
			type: 'object',
			required: ['name', 'address1', 'city', 'state', 'zip', 'country'],
			properties: {
				name: {
					type: 'string'
				},
				address1: {
					type: 'string'
				},
				address2: {
					type: 'string'
				},
				city: {
					type: 'string'
				},
				state: {
					type: 'string'
				},
				zip: {
					type: 'string',
					pattern: '^[0-9A-Z]{1,}($|-[0-9A-Z]{1,})'
				},
				country: {
					type: 'string',
					pattern: '^[A-Z]{2}$'
				}
			}
		},
		Item: {
			type: 'object',
			required: ['sku', 'quantity'],
			properties: {
				sku: {
					type: 'string',
					enum: []
				},
				quantity: {
					type: 'integer',
					minimum: 1
				}
			}
		}
	}
};

schema = compileSchema(schema);

export default schema;

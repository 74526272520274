import React from 'react';
import {BasePage} from 'app-center-common';
import { messageBox, DateTimeRangePicker} from 'ui-core';
import { Button } from '@mui/material';
import {api, logger, utils} from 'client-services';

import CONSTANTS from '../../../lib/constants.js';

var OrderStatusDict = {};
Object.keys(CONSTANTS.OrderStatus).forEach(k => { OrderStatusDict[CONSTANTS.OrderStatus[k]] = k; });

class SalesReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				from: '',
				to: ''
			}
		};

		this.datePicker = null;
		this.onRun = this.onRun.bind(this);
	}

	// #region Methods

	exportToCSV(orders) {
		if (orders.length < 1) {return '';}
		orders = orders.map(o => {
			var total = 0;
			var giftCard = 0;

			if (o.invoice && Array.isArray(o.invoice.transactions)) {
				var transactions = o.invoice.transactions;
				var ccs = transactions.filter(t => t.paymentMethod && t.paymentMethod.method === CONSTANTS.PaymentMethods.CreditCard);
				total = ccs.map(cc => cc.amount || 0).reduce((x, y) => x + y, 0);

				var gcs = transactions.filter(t => t.paymentMethod && t.paymentMethod.method === CONSTANTS.PaymentMethods.GiftCard);
				giftCard = gcs.map(gc => gc.amount || 0).reduce((x, y) => x + y, 0);
			}

			var res = {
				id: o.id,
				createdAt: o.createdAt,
				status: OrderStatusDict[o.status] || '',
				email: o.email,
				name: '',
				address: '',
				items: `"${o.items.map(i => `${i.quantity} X ${i.sku}${i.description ? ` (${i.description})` : ''} - ${i.price}`).join('\n')}"`,
				currency: (o.invoice && o.invoice.currency) || '',
				amount: (o.invoice && o.invoice.amount) || 0,
				discount: -1 * ((o.invoice && o.invoice.discount) || 0),
				shipping: (o.invoice && o.invoice.shipping) || 0,
				tax: (o.invoice && o.invoice.tax) || 0,
				giftCard: -1 * giftCard,
				total: total,
				balance: (o.invoice && o.invoice.balance) || 0,
				pos: (o.pos && o.pos.name) || '',
				marketplace: o.marketplace?.id ?? '',
				marketplaceId: o.marketplace?.orderId ?? ''
			};

			if (o.shipping && o.shipping.address) {
				var addr = o.shipping.address;
				res.name = addr.name || '';
				res.address = `"${addr.address1}\n${addr.address2 ? `${addr.address2}\n` : ''}${addr.city}, ${addr.state}\n${addr.zip}\n${addr.country}"`;
			}

			return res;
		});

		var headers = Object.getOwnPropertyNames(orders[0]).join(',');
		var data = orders.map(o => Object.values(o).join(',')).join('\n');
		return headers + '\n' + data;
	}

	// #endregion Methods

	// #region EventHandlers

	async onRun() {
		if (!this.datePicker) { return; }
		var data = this.datePicker.getData();
		var orders = [];
		var mb = messageBox('Fetching report...', null, messageBox.Buttons.OK, true);
		try {
			var pageSize = 1000;
			var maxIters = 10;
			for (var i = 0; i < maxIters; ++i) {
				var params = { count: pageSize, skip: i * pageSize, q: '+ug:1 +ug:2 +ug:-1' }; // ug:-1 is for orders with no user
				if (data.from) {
					params.from = data.from;
				}

				if (data.to) {
					params.to = data.to;
				}

				logger.trace('Fetching orders with params:', params);
				var res = await api.store.getOrders(params).send();
				logger.trace('Got orders response:', res);
				orders.push(...res.orders);
				if (res.orders < pageSize) { break; }
			}
		} catch (ex) {
			logger.error('Error fetching orders:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}

		if (orders.length < 1) {
			mb.setTitle('No orders found');
		} else {
			var msg = 'Exporting to CSV...';
			if (orders.length === pageSize * maxIters) {
				msg += (<br /> + `NOTE: fetched max orders (${orders.length}), there might be more...`);
			}

			mb.setTitle(msg);
			var csv = this.exportToCSV(orders);
			var blob = new Blob([csv]);
			utils.downloadBlob(blob, 'orders.csv');
			mb.close();
		}
	}

	// #endregion EventHandlers

	// #region Render

	render() {
		return (
			<BasePage title="Sales Report">
				<DateTimeRangePicker ref={p => { this.datePicker = p;}}/>
				<Button variant="contained" color="primary" onClick={this.onRun}>Download</Button>
			</BasePage>
		);
	}

	// #endregion Render
}

SalesReport.propTypes = {};
SalesReport.defaultProps = {};

export default SalesReport;

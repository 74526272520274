import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { messageBox, DateTimeRangePicker, Grid} from 'ui-core';
import { BasePage } from 'app-center-common';
import {api, logger, utils} from 'client-services';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
} from 'chart.js';
import {Line, Pie} from 'react-chartjs-2';

import './sales-dashboard.css';

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend
);

const COL = 80;
const COL_LG = 120;
const COLORS = ['rgb(0,0,255)',	'rgb(255,0,155)', 'rgb(0,128,0)', 'rgb(0,206,209)', 'rgb(65,105,225)', 'rgb(75,255,0)', 'rgb(51,153,255)', 'rgb(255,158,10)', 'rgb(242,78,255)', 'rgb(47,79,79)'];

class SalesDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			salesData: null,
			catalog: {},
			dates: {
				from: null,
				to: null
			}
		};

		/** @type {Array.<*>} */
		this.marketplaceOrdersColumns = [
			{key: 'marketplace', name: 'Marketplace', width: COL_LG},
			{key: 'count', name: 'Count', width: COL_LG}
		];

		/** @type {Array.<*>} */
		this.totalBoxesColumns = [
			{key: 'sku', name: 'SKU', width: 2 * COL_LG, resizable: true},
			{key: 'count', name: 'Count', width: COL, resizable: true}
		];

		/** @type {Array.<*>} */
		this.marketplaceOrdersDailyColumns = [
			{key: 'date', name: 'Date', width: COL_LG, frozen: true, resizable: true},
			{key: 'total', name: 'Total', width: COL, frozen: true, resizable: true, cellClass: 'total'}
		];

		/** @type {Array.<*>} */
		this.marketplaceProductsColumns = [
			{key: 'marketplace', name: 'Marketplace', width: COL_LG, frozen: true, resizable: true},
			{key: 'total', name: 'Total', width: COL, frozen: true, resizable: true, cellClass: 'total'}
		];

		/** @type {Array.<*>} */
		this.marketplaceProductsDailyColumns = [
			{key: 'date', name: 'Date', width: COL_LG, frozen: true, resizable: true},
			{key: 'total', name: 'Total', width: COL, frozen: true, resizable: true, cellClass: 'total'}
		];

		this.marketplaceOrdersDailyChartData = null;
		this.marketplaceOrdersChartData = null;
		this.totalBoxesChartData = null;

		/** @type {import('chart.js').ChartOptions} */
		this.lineChartOptions = {
			responsive: true,
			plugins: {
				legend: {
					position: 'right',
					labels: { boxWidth: 10 }
				}
			}
		};

		this.dateChanged = this.dateChanged.bind(this);
	}

	// <editor-fold desc="// Methods {...}">

	sortByMarketplace(arr) {
		return arr.sort((mp1, mp2) => mp1.marketplace > mp2.marketplace ? 1 : (mp1.marketplace < mp2.marketplace ? -1 : 0));
	}

	async loadData(refreshCatalog) {
		var catalog = this.state.catalog;
		if (!catalog || refreshCatalog) {
			catalog = await this.loadCatalog();
		}

		logger.trace('Fetching sales dashboard');
		var params = {from: this.state.dates.from || undefined, to: this.state.dates.to || undefined};
		var res = await api.store.salesDashboard(params).send();
		if (!res) {
			throw new Error('Invalid server response');
		}

		if (Array.isArray(res.marketplaceOrders)) {
			res.marketplaceOrders = this.sortByMarketplace(res.marketplaceOrders);
			this.marketplaceOrdersChartData = this.gridDataToChartDataset(this.marketplaceOrdersColumns, res.marketplaceOrders, false, 'Pie');
		}

		if (Array.isArray(res.totalBoxes)) {
			res.totalBoxes.forEach(r => {
				r.sku = (catalog[r.sku] ? catalog[r.sku].desc : r.sku);
			});

			this.totalBoxesChartData = this.gridDataToChartDataset(this.totalBoxesColumns, res.totalBoxes, false, 'Pie');
		}

		if (Array.isArray(res.marketplaceOrdersDaily)) {
			res.dailyFromDate = new Date();
			res.dailyToDate = new Date(0);

			// Remove columns that are based on response data
			if (this.marketplaceOrdersDailyColumns.length > 2) {
				this.marketplaceOrdersDailyColumns = this.marketplaceOrdersDailyColumns.slice(0, 2);
			}

			var mps = new Set();
			res.marketplaceOrdersDaily = res.marketplaceOrdersDaily.map(d => {
				var date = new Date(d.date);
				if (date < res.dailyFromDate) {res.dailyFromDate = date; }
				if (date > res.dailyToDate) {res.dailyToDate = date; }

				let row = { date: utils.toDateString(date, false, true), total: 0 };
				d.marketplace.forEach((mp, idx) => {
					if (!mps.has(mp.id)) {
						mps.add(mp.id);
						this.marketplaceOrdersDailyColumns.push({
							key: mp.id,
							name: mp.id,
							width: COL
						});
					}

					row.total += mp.count;
					row[mp.id] = mp.count;
				});

				return row;
			});

			this.marketplaceOrdersDailyChartData = this.gridDataToChartDataset(this.marketplaceOrdersDailyColumns, res.marketplaceOrdersDaily, true);
			console.log(this.marketplaceOrdersDailyChartData);
		}

		if (Array.isArray(res.marketplaceProducts)) {
			// Remove columns that are based on response data
			if (this.marketplaceProductsColumns.length > 2) {
				this.marketplaceProductsColumns = this.marketplaceProductsColumns.slice(0, 2);
			}

			let skus = new Set();
			res.marketplaceProducts = res.marketplaceProducts.map(pp => {
				let row = {marketplace: pp.marketplace, total: 0};
				pp.sku.forEach(s => {
					if (!skus.has(s.id)) {
						skus.add(s.id);
						this.marketplaceProductsColumns.push({
							key: s.id,
							name: s.id,
							width: COL
						});
					}

					row.total += s.count;
					row[s.id] = s.count;
				});

				return row;
			});

			res.marketplaceProducts = this.sortByMarketplace(res.marketplaceProducts);
		}

		if (Array.isArray(res.marketplaceProductsDaily)) {
			// Remove columns that are based on response data
			if (this.marketplaceProductsDailyColumns.length > 2) {
				this.marketplaceProductsDailyColumns = this.marketplaceProductsDailyColumns.slice(0, 2);
			}

			let skus = new Set();
			res.marketplaceProductsDaily = res.marketplaceProductsDaily.map(d => {
				var date = new Date(d.date);
				let row = { date: utils.toDateString(date, false, true), total: 0 };
				d.sku.forEach(sku => {
					if (!skus.has(sku.id)) {
						skus.add(sku.id);
						this.marketplaceProductsDailyColumns.push({
							key: sku.id,
							name: sku.id,
							width: COL
						});
					}

					row.total += sku.count;
					row[sku.id] = sku.count;
				});

				return row;
			});
		}

		return {salesData: res, catalog};
	}

	async loadCatalog() {
		var res = await api.store.getCatalog().send();
		if (!res || !res.catalog || typeof res.catalog !== 'object') {
			throw new Error('Invalid catalog response');
		}

		return res.catalog;
	}

	gridDataToChartDataset(columns, rows, reverseRows = false, chartType = 'Line') {
		var result = {
			labels: [],
			datasets: []
		};

		rows = reverseRows ? [].concat(rows).reverse() : rows;

		// Each grid column is a dateset except the first column which is the "categories" (i.e X axis)
		for (let i = 1; i < columns.length; ++i) {
			var dataset;
			switch (chartType) {
				case 'Line':
					dataset = this.getLineChartDataset(columns[i].name, (i - 1), i > 1);
					break;
				case 'Pie':
					dataset = this.getPieChartDataset(rows.length);
					break;
				default:
					throw new Error(`Invalid chart type: ${chartType}`);
			}

			result.datasets.push(dataset);
		}

		for (var i = 0; i < rows.length; ++i) {
			result.labels.push(rows[i][columns[0].key]); // again, first column is category
			for (var j = 1; j < columns.length; ++j) {
				var val = rows[i][columns[j].key] || 0;
				result.datasets[j - 1].data.push(val);
			}
		}

		return result;
	}

	getLineChartDataset(label, colorIdx = 0, transparent = true) {
		var color = COLORS[colorIdx % COLORS.length];
		return {
			label,
			borderColor: transparent ? color.replace(')', ',0.4)') : color,
			fill: false,
			borderWidth: 2,
			pointRadius: 2,
			lineTension: 0.4,
			data: []
		};
	}

	getPieChartDataset(numLabels) {
		var bgs = COLORS.slice(0, numLabels);
		while (bgs.length < numLabels) {
			bgs = bgs.concat(COLORS.slice(0, numLabels - bgs.length));
		}

		return {
			backgroundColor: bgs,
			data: []
		};
	}

	// </editor-fold> // Methods

	// <editor-fold desc="// EventHandlers {...}">

	dateChanged(dates) {
		this.setState({dates}, () => {
			var mb = messageBox('Loading...', null, null, true);
			this.loadData(false).then(({salesData, catalog}) => {
				this.setState({salesData, catalog});
				mb.close();
			}).catch(ex => {
				logger.error(ex);
				mb.setTitle('Error', false);
				mb.setBody(ex.message);
			});
		});
	}

	// </editor-fold> // EventHandlers

	// <editor-fold desc="// Lifecycle {...}">

	componentDidMount() {
		var mb = messageBox('Loading...', null, null, true);
		this.loadData(true).then(({salesData, catalog}) => {
			this.setState({salesData, catalog});
			mb.close();
		}).catch(ex => {
			logger.error(ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		});
	}

	// </editor-fold> // Lifecycle

	// <editor-fold desc="// Render {...}">

	renderInnerToolbar() {
		return (
			<div style={{ display: 'flex', overflow: 'auto', alignItems: 'center' }}>
				<DateTimeRangePicker popover selected={this.dateChanged} from={this.state.dates.from} to={this.state.dates.to} />
			</div>
		);
	}

	renderHelp() {
		return (
			<div>
				<p>Total Orders - The total number of orders sold in the report period. An Order can have multiple products in it</p>
				<p>Total Boxes - The total number of boxes sold in the report period. If there was an order for a Bundle
					product then it is counted as 2 boxes (or whatever the number of boxes that are in that Bundle)</p>
				<p>Total Products - A breakdown of the total products sold in the report period. Product is different from Box
				as a Bundle product is counted as 1 product but may be several boxes</p>
				<p>Example: say we have an <b>order</b> with 2 <b>Products:</b> Sketch Box and Sketch & Refill Bundle,
				so this is counted in the report as: 1 Order, 2 Products, 3 Boxes (2 Sketch boxes and 1 refill box)</p>
				<p>Recent Daily Orders/Products - Data from the last 365 days of the report period. If the report period
				is less than a year then the Recent data and Total data should be the same</p>
			</div>
		);
	}

	renderMarketplaceOrders(data) {
		if (!Array.isArray(data.marketplaceOrders)) {return '';}
		return (
			<div className="per-mp">
				<div className="title" style={{ backgroundColor: this.props.theme.palette.secondary.main}}>
					Total Orders: {typeof data.totalOrders !== 'undefined' ? data.totalOrders : 'Data not found'}
				</div>
				<div className="two-col even">
					<div className="grid-wrap">
						<Grid
							columns={this.marketplaceOrdersColumns}
							rows={data.marketplaceOrders}
						/>
					</div>
					<div>
						<Pie data={this.marketplaceOrdersChartData} options={this.lineChartOptions} />
					</div>
				</div>
			</div>
		);
	}

	renderTotalBoxes(data) {
		if (!Array.isArray(data.totalBoxes)) {return '';}
		var total = data.totalBoxes.map(r => r.count).reduce((x, y) => x + y, 0);
		return (
			<div className="per-mp">
				<div className="title" style={{ backgroundColor: this.props.theme.palette.secondary.main}}>
					Total Boxes: {total}
				</div>
				<div className="two-col even">
					<div className="grid-wrap">
						<Grid
							columns={this.totalBoxesColumns}
							rows={data.totalBoxes}
						/>
					</div>
					<div>
						<Pie data={this.totalBoxesChartData} options={this.lineChartOptions} />
					</div>
				</div>
			</div>
		);
	}

	renderMarketplaceProducts(data) {
		if (!Array.isArray(data.marketplaceProducts)) {return '';}
		return (
			<div>
				<div className="title" style={{ backgroundColor: this.props.theme.palette.secondary.main}}>
					Total Products: {typeof data.totalProducts !== 'undefined' ? data.totalProducts : 'Data not found'}
				</div>
				<div className="two-col highlight-last-cell">
					<div className="grid-wrap">
						<Grid
							columns={this.marketplaceProductsColumns}
							rows={data.marketplaceProducts}
						/>
					</div>
					<div className="legend">
						{Object.keys(this.state.catalog).map(sku => <div key={sku}>{sku} - {this.state.catalog[sku].desc}</div>)}
					</div>
				</div>
			</div>
		);
	}

	renderMarketplaceOrdersDaily(data) {
		if (!Array.isArray(data.marketplaceOrdersDaily)) {return '';}
		var fromDate = utils.toDateString(data.dailyFromDate, false, true);
		var toDate = utils.toDateString(data.dailyToDate, false, true);
		return (
			<div className="highlight-last-cell">
				<div className="title" style={{ backgroundColor: this.props.theme.palette.secondary.main}}>
					<span>Recent Daily Orders</span><span style={{fontSize: '0.9em'}}> ({fromDate} To {toDate})</span>
				</div>
				<div className="two-col even">
					<div className="grid-wrap">
						<Grid
							columns={this.marketplaceOrdersDailyColumns}
							rows={data.marketplaceOrdersDaily}
							rowHeight={35}
						/>
					</div>
					<div>
						<Line data={this.marketplaceOrdersDailyChartData} options={this.lineChartOptions} />
					</div>
				</div>
			</div>
		);
	}

	renderMarketplaceProductsDaily(data) {
		if (!Array.isArray(data.marketplaceProductsDaily)) {return '';}
		var fromDate = utils.toDateString(data.dailyFromDate, false, true);
		var toDate = utils.toDateString(data.dailyToDate, false, true);
		return (
			<div>
				<div className="title" style={{ backgroundColor: this.props.theme.palette.secondary.main}}>
					<span>Recent Daily Products</span><span style={{fontSize: '0.9em'}}> ({fromDate} To {toDate})</span>
				</div>
				<div className="two-col highlight-last-cell">
					<div className="grid-wrap">
						<Grid
							columns={this.marketplaceProductsDailyColumns}
							rows={data.marketplaceProductsDaily}
						/>
					</div>
					<div className="legend">
						{Object.keys(this.state.catalog).map(sku => <div key={sku}>{sku} - {this.state.catalog[sku].desc}</div>)}
					</div>
				</div>
			</div>
		);
	}

	renderReportTitle() {
		var period = 'Lifetime';
		var {dates} = this.state;
		if (dates.from && dates.to) {
			period = `${utils.toDateString(dates.from, false, true)} - ${utils.toDateString(dates.to, false, true)}`;
		} else if (dates.from) {
			period = `${utils.toDateString(dates.from, false, true)} - Today`;
		} else if (dates.to) {
			period = `Until ${utils.toDateString(dates.from, false, true)}`;
		}

		return (
			<div className="report-header" style={{ color: this.props.theme.palette.secondary.main}}>Report Period: {period}</div>
		);
	}

	render() {
		if (!this.state.salesData) {return 'Loading...';}
		var data = this.state.salesData;
		return (
			<BasePage
				className="sales-dash"
				title="Sales"
				toolbar={this.renderInnerToolbar()}
				toolbarOpen={true}
				helpContent={this.renderHelp()}
			>
				{this.renderReportTitle()}
				{this.renderMarketplaceOrders(data)}
				{this.renderTotalBoxes(data)}
				{this.renderMarketplaceProducts(data)}
				{this.renderMarketplaceOrdersDaily(data)}
				{this.renderMarketplaceProductsDaily(data)}
			</BasePage>
		);
	}

	// </editor-fold> // Render
}

SalesDashboard.propTypes = {
	theme: PropTypes.object
};

export default function SalesDashboardWrapped(props) {
	let theme = useTheme();
	return <SalesDashboard theme={theme} {...props} />;
}

import React from 'react';
import CONSTANTS from 'src/lib/constants.js';
import { logger, api } from 'client-services';
import { Form, Checkbox, messageBox } from 'ui-core';
import { BasePage } from 'app-center-common';
import { schema, uiSchema } from './notification.schema.js';

import clsx from 'clsx';

import './push-notification-page.css';
// @ts-ignore
import logo from '../../../assets/push_logo.png';

class PushNotificationPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false,
			data: {
			}
		};

		this.send = this.send.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
	}

	// #region EventHandlers

	onFormChange(data) {
		this.setState({ data });
	}

	// #endregion EventHandlers

	// #region Methods

	async send(data) {
		if (!data) {
			this.setState({ data: {} });
			return;
		}

		let exp;
		let expFeed;
		if (data.experienceId) {
			exp = await this.validateExperience(data.experienceId);
			if (!exp) { return; }
		}

		if (data.expFeedId) {
			expFeed = await this.validateExpFeed(data.expFeedId);
			if (!expFeed) { return; }
		}

		let msg =
			<div>
				<div>Channel: { data.channelId }</div>
				<div>Title: { data.title }</div>
				<div>Message: { data.message }</div>
				{data.image && <div>Image: <a href={data.image} target="_blank" rel="noreferrer">link</a> </div>}
				{exp && <div><br />Experience: {exp.name} </div>}
				{expFeed && <div><br />Experience Feed: {expFeed.name} </div>}
			</div>;

		let res = await messageBox('Confirm Push Notification', msg, [messageBox.Buttons.Cancel, 'Send']).promise;
		if (res === messageBox.Buttons.Cancel) {
			return;
		}

		await this.sendPushNotification(data);
		this.setState({ data: {} });
	}

	async validateExperience(expId) {
		if (!expId) { return; }
		let mb = messageBox('Validating experience', '', messageBox.Buttons.OK, true);
		try {
			logger.trace('Fetching experience with id', expId);
			let res = await api.experience.getExperience({ id: expId }).send();
			logger.trace('Got response:', res);
			if (!res?.experience) { throw new Error('Experience not found'); }
			await mb.close();

			if (res.experience.status !== CONSTANTS.ExperienceStatus.Public) {
				let ans = await messageBox('Experience is NOT Public', 'Are you sure you want to continue?', [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
				if (ans === messageBox.Buttons.No) {
					return;
				}
			}

			return res.experience;
		} catch (ex) {
			logger.error('Error fetching experience with id: %s, error:', expId, ex);
			mb.setTitle('Error validating experience', false);
			mb.setBody(ex.message);
			return false;
		}
	}

	async validateExpFeed(expFeedId) {
		if (!expFeedId) { return; }
		let mb = messageBox('Validating experience feed', '', messageBox.Buttons.OK, true);
		try {
			logger.trace('Fetching experience feed with id', expFeedId);
			let res = await api.experience.getExperienceFeed({ id: expFeedId }).send();
			logger.trace('Got response:', res);
			if (!res?.experienceFeed) { throw new Error('Experience feed not found'); }
			await mb.close();

			if (res.experienceFeed.forEvent) {
				let ans = await messageBox('Experience feed is marked as for event', 'Are you sure you want to continue?', [messageBox.Buttons.No, messageBox.Buttons.Yes]).promise;
				if (ans === messageBox.Buttons.No) {
					return;
				}
			}

			return res.experienceFeed;
		} catch (ex) {
			logger.error('Error fetching experience feed with id: %s, error:', expFeedId, ex);
			mb.setTitle('Error validating experience feed', false);
			mb.setBody(ex.message);
			return false;
		}
	}

	async sendPushNotification(data) {
		let params = {
			topic: 'GENERAL',
			channelId: data.channelId,
			title: data.title,
			message: data.message,
			data: {
				image: data.image
			}
		};

		if (data.experienceId) {
			params.data.experienceId = data.experienceId;
		}

		if (data.expFeedId) {
			params.data.expFeedId = data.expFeedId;
		}

		logger.info('Sending push notification with params:', params);
		let mb = messageBox('Sending...', '', [messageBox.Buttons.OK], true);
		try {
			let res = await api.notification.sendPushNotification(params).send();
			if (!Array.isArray(res?.ids)) {
				logger.error('Got invalid server response, no `ids` nor error. Response', res);
				mb.setTitle('Something is not right', false);
				mb.setBody(<div>
					<div>Got invalid response from the server but NOT an error</div>
					<div>It is not clear whether the message has been sent or not.</div>
					<div><b>DO NOT</b> send the message again</div>
					<div>Check console in developer tools for more details</div>
				</div>);

				return;
			}

			mb.setTitle('Success', false);
			mb.setBody(`Message id: ${res.ids.join(',')}`);
		} catch (ex) {
			logger.error('Error sending push notification, error:', ex);
		}
	}

	// #endregion Methods

	render() {
		let { data, opened } = this.state;
		return (
			<BasePage title="Push Notifications" className="push-notification-page">
				<div className="form-data">
					<Form
						formData={this.state.data}
						schema={schema}
						uiSchema={uiSchema}
						submitText="Send"
						cancelText="Clear"
						submit={this.send}
						onChange={this.onFormChange}
						showErrorMessageBox
					/>
				</div>
				<div className="preview">
					<Checkbox label="Opened" checked={opened} onCheck={(e, opened) => this.setState({opened})} />
					<div className="notification">
						<div className="logo">
							<img src={logo} />
						</div>
						<div className="content">
							<div className="header">Cupixel • Now</div>
							<div className="title">{data.title}</div>
							<div className={clsx('message', {opened})}>{data.message}</div>
						</div>
						<div className="image">
							{data.image && <img src={data.image} />}
						</div>
					</div>
				</div>
			</BasePage>
		);
	}
}

export default PushNotificationPage;

import React from 'react';
import { Button } from '@mui/material';
import { messageBox, Checkbox } from 'ui-core';
import { BasePage } from 'app-center-common';
import CSVUtils from '../../../lib/csv-utils.js';
import { api, logger, } from 'client-services';

import './import-shipments-page.css';
import CONSTANTS from '../../../lib/constants.js';

class ImportShipmentsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			orders: {},
			missingOrderId: 0,
			missingTracking: 0,
			missingCarrier: 0,
			multipleTracking: 0,
			multipleCarrier: 0,
			wrongCarrier: 0,
			ordersCount: 0,
			status: '',
			sendEmail: true
		};

		this.onFileLoad = this.onFileLoad.bind(this);
		this.updateShipment = this.updateShipment.bind(this);
	}

	// #region Methods

	async loadOrders(file) {
		var mb = messageBox('Loading...');
		var readingOrders = await CSVUtils.readCSVFile(file);

		var missingOrderId = 0;
		var missingTracking = 0;
		let multipleTracking = 0;
		let missingCarrier = 0;
		let multipleCarrier = 0;
		let wrongCarrier = 0;

		let orders = readingOrders.reduce((result, order) => {
			if (!order['order id']) { ++missingOrderId; return result;}

			let orderId = order['order id'];
			let carrier = order['shipping carrier'];
			if (result.hasOwnProperty(orderId)) {
				if (typeof (order.tracking) !== 'undefined' && order.tracking !== null) {
					if (!result[orderId].tracking) {
						result[orderId].tracking = order.tracking.toString();
					} else if (result[orderId].tracking !== order.tracking.toString()) {
						++multipleTracking;
					}
				}

				if (typeof (carrier) !== 'undefined' && carrier !== null) {
					if (!result[orderId].carrier) {
						result[orderId].carrier = carrier.toString().toUpperCase();
					} else if (result[orderId].carrier !== carrier.toString().toUpperCase()) {
						++multipleCarrier;
					}
				}
			} else {
				result[orderId] = {id: orderId, tracking: order.tracking?.toString() || '', carrier: carrier?.toString()?.toUpperCase() || ''};
			}

			return result;
		}, {});

		let ordersCount = missingOrderId;
		Object.values(orders).forEach(order => {
			ordersCount++;
			if (!order.tracking) {
				++missingTracking;
			}

			if (!order.carrier) {
				++missingCarrier;
			} else if (!Object.values(CONSTANTS.Carrier).includes(order.carrier.toUpperCase())) {
				++wrongCarrier;
			}
		});

		this.setState({ orders, missingOrderId, missingTracking, missingCarrier, multipleTracking, multipleCarrier, wrongCarrier, ordersCount });
		mb.close(20);
	}

	// #endregion Methods

	// #region EventHandlers

	async onFileLoad(e) {
		if (e.target.files.length < 1) {
			messageBox('please select file');
			return;
		}

		var file = e.target.files[0];
		if (!(/.*\.(xlsx|csv)$/i).test(file.name)) {
			messageBox('Invalid file name, must have xlsx/csv extension');
			return;
		}

		this.loadOrders(file);
	}

	async updateShipment() {
		if (this.state.ordersCount < 1) { return; }
		if (this.state.missingOrderId > 0) {
			messageBox('Can\'t update if there are orders with missing IDs');
			return;
		}

		if (this.state.missingTracking > 0) {
			var res = await messageBox('There are orders with no tracking, continue with update?', null, [messageBox.Buttons.Cancel, 'Update']).promise;
			if (res === messageBox.Buttons.Cancel) { return; }
		}

		if (this.state.multipleTracking > 0) {
			messageBox('Can\'t update if there are orders with multiple tracking');
			return;
		}

		if (this.state.multipleCarrier > 0) {
			messageBox('Can\'t update if there are orders with multiple shipping carrier');
			return;
		}

		var errors = [];
		var mb = messageBox('', '', [messageBox.Buttons.OK], true);

		let i = 0;
		for (const orderId in this.state.orders) {
			let order = this.state.orders[orderId];
			mb.setBody(`Updating order: ${++i}`);
			try {
				logger.trace('Updating order %s with tracking %s', order.id, order.tracking);
				await api.store.updateOrderShipment({ id: order.id, sendEmail: this.state.sendEmail, tracking: order.tracking, carrier: order.carrier }).send();
			} catch (e) {
				logger.error('Error updating order `%s`, error:', order.id, e);
				errors.push({ id: order.id, message: e.message });
			}
		}

		if (errors.length > 0) {
			mb.setTitle('Error', false);
			mb.setBody(errors.map((e, i) => <div key={i}>{e.id} - {e.message}</div>));
		} else {
			mb.setTitle('Success', false);
			mb.setBody('');
		}
	}

	// #endregion EventHandlers

	// #region render

	renderOrdersTable() {
		if (this.state.ordersCount < 1) { return; }
		return (
			<div className="orders">
				<table style={{border: '1px solid'}}>
					<tbody>
						<tr><td>Order Id</td><td>Tracking</td></tr>
						{Object.keys(this.state.orders).map((o, i) => (
							<tr key={i}>
								<td>{this.state.orders[o].id}</td>
								<td>{this.state.orders[o].tracking}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	renderStatus() {
		var msg = '';
		if (this.state.missingOrderId > 0) {
			msg += `There are ${this.state.missingOrderId}/${this.state.ordersCount} orders with no ID.`;
		}

		if (this.state.missingTracking > 0) {
			msg += ` There are ${this.state.missingTracking}/${this.state.ordersCount} orders with no tracking.`;
		}

		if (this.state.missingCarrier > 0) {
			msg += ` There are ${this.state.missingCarrier}/${this.state.ordersCount} orders with no shipping carrier.`;
		}

		if (this.state.multipleTracking > 0) {
			msg += ` There are ${this.state.multipleTracking}/${this.state.ordersCount} orders with multiple tracking.`;
		}

		if (this.state.multipleCarrier > 0) {
			msg += ` There are ${this.state.multipleCarrier}/${this.state.ordersCount} orders with multiple shipping carrier.`;
		}

		if (this.state.wrongCarrier > 0) {
			msg += ` There are ${this.state.wrongCarrier}/${this.state.ordersCount} orders with wrong shipping carrier.`;
		}

		return <span className="status">{msg}</span>;
	}

	render() {
		return (
			<BasePage className="import-shipments-page">
				<div className="title">Import Shipments from Paper House</div>
				<div>
					<div>
						<input type="file" onInput={this.onFileLoad} accept=".csv,.xlsx" />
					</div>
					<div>
						<Checkbox checked={this.state.sendEmail} label="Send email to user?" onCheck={(e, checked) => this.setState({sendEmail: checked})} />
					</div>
					<div>
						<Button variant="contained" color="primary" disabled={this.state.ordersCount < 1 || this.state.missingOrderId > 0 || this.state.multipleTracking > 0 || this.state.multipleCarrier > 0 || this.state.wrongCarrier > 0} onClick={this.updateShipment}>Update as Shipped</Button>
						{this.renderStatus()}
					</div>
				</div>
				<div>
					{this.renderOrdersTable()}
				</div>
			</BasePage>
		);
	}

	// #endregion render
}

export default ImportShipmentsPage;
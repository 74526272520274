import React from 'react';
import CONSTANTS from '../../../lib/constants.js';
import { Button } from '@mui/material';
import { messageBox, snackbar, compileSchema, Form, Grid } from 'ui-core';
import {BasePage} from 'app-center-common';
import {api, logger, auth, utils} from 'client-services';
import OrdersGridHelper from './orders-grid-helpers.jsx';
import { readOrdersFromFile } from './csv-utils.js';

import './import-orders-page.css';

class ImportOrdersPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			extraData: {
				email: auth.session().user.email || '',
				marketplace: CONSTANTS.Marketplace.BO,
				pos: '',
				dispatch: false,
				service: 'Default'
			},
			summary: {},
			orders: [],
			batchId: '',
			importErrors: [],
			importedOrders: [],
			canImport: false
		};

		this.form = null;
		this.catalog = null;
		this.pos = null;
		this.onFileLoad = this.onFileLoad.bind(this);
		this.onImport = this.onImport.bind(this);
		this.onCopySummary = this.onCopySummary.bind(this);
	}

	// #region Methods

	async loadCatalog() {
		logger.trace('Loading catalog...');
		var res = await api.store.getCatalog().send();
		if (!res || !res.catalog || typeof res.catalog !== 'object') {
			throw new Error('Invalid catalog response');
		}

		var skus = Object.keys(res.catalog);
		if (skus.length < 1) {
			throw new Error('Catalog has no SKUs defined...');
		}

		this.catalog = res.catalog;
	}

	async loadPOS() {
		logger.trace('Loading POS...');
		var res = await api.store.getPointsOfSale().send();
		if (res && Array.isArray(res.pointsOfSale)) {
			this.pos = res.pointsOfSale;
		}
	}

	validateOrders(orders) {
		var errs = orders.map(o => o.errors.length).reduce((x, y) => x + y, 0);
		if (errs > 0) {
			this.setState({ orders });
			return false;
		}

		var summary = {
			orders: 0,
			skus: {},
			amount: 0,
			discount: 0,
			shipping: 0,
			total: 0
		};

		var uniqueOrders = {};
		orders.forEach(o => {
			summary.discount += o.invoice.discount;
			summary.amount += o.invoice.amount;
			summary.shipping += o.invoice.shipping;
			summary.total += o.invoice.total;

			// Count skus
			o.items.forEach(i => {
				summary.skus[i.sku] = summary.skus[i.sku] || 0;
				++summary.skus[i.sku];
			});

			// Try to merge orders for the EXACT same user
			var key = ['name', 'address1', 'address2', 'city', 'state', 'zip', 'country'].map(p => o.shipping.address[p].toLowerCase());
			if (!uniqueOrders[key]) {
				uniqueOrders[key] = o;
			} else {
				// Merge the orders
				var o1 = uniqueOrders[key];

				o1.invoice.amount = utils.toMoney(o1.invoice.amount + o.invoice.amount);
				o1.invoice.discount = utils.toMoney(o1.invoice.discount + o.invoice.discount);
				o1.invoice.shipping = utils.toMoney(o1.invoice.shipping + o.invoice.shipping);
				o1.invoice.total = utils.toMoney(o1.invoice.total + o.invoice.total);

				// merge items
				o.items.forEach(i => {
					// If this sku exists just add qty otherwise add this sku
					var elem = o1.items.find(i1 => i1.sku === i.sku);
					if (elem) {
						elem.quantity += i.quantity;
					} else {
						o1.items.push(i);
					}
				});
			}
		});

		orders = Object.values(uniqueOrders);
		summary.orders = orders.length;
		this.setState({ orders, summary, canImport: true });
		return true;
	}

	getImportParams(extraData, batchId) {
		var params = {
			id: extraData.marketplace,
			dispatch: extraData.dispatch,
			pos: extraData.pos || undefined,
		};

		var orders = [];
		this.state.orders.forEach((o, idx) => {
			if (extraData.service !== 'Default') {
				o.shipping = o.shipping || {};
				o.shipping.carrier = 'UPS';
				o.shipping.service = extraData.service;
			}

			orders.push({
				email: o.email || extraData.email,
				items: o.items,
				shipping: o.shipping,
				invoice: o.invoice,
				marketplace: {
					id: extraData.marketplace,
					batchId: batchId,
					orderId: o.id || `${batchId}-${idx + 0}`
				}
			});
		});

		params.orders = orders;
		return params;
	}

	async sendImportRequest(extraData) {
		var mb = messageBox('Sending import request...', null, [messageBox.Buttons.OK], true);
		var batchId = Date.now().toString(); // This is unique enough
		var params = this.getImportParams(extraData, batchId);
		logger.trace('Sending import orders request with params:', params);
		try {
			var res = await api.store.importMarketplaceOrders(params).send();
			logger.trace('import orders response:', res);
			if (!Array.isArray(res.orders)) {
				throw new Error('Invalid response, no orders array');
			}

			var importErrors = [];
			var importedOrders = [];
			var allDispatched = true;
			res.orders.forEach((o, i) => {
				if (o === null) {
					importErrors.push(`Order@${i} was already in DB and not re-created`);
				} else if (o.error) {
					importErrors.push(`Error importing order@${i}: ${o.message}`);
				} else {
					importedOrders.push(`order@${o.order.index} - id: ${o.order.id} dispatched: ${o.order.dispatched}`);
					allDispatched = allDispatched && o.order.dispatched;
				}
			});

			if (!allDispatched && this.state.extraData.dispatch) {
				importErrors.push('Not all orders were dispatched, see details on right side of the page');
			}

			mb.setTitle(importErrors.length > 0 ? 'Import had some errors, see details on right side of the page' : 'Success', false);
			this.setState({ importErrors, importedOrders, batchId, canImport: false });
		} catch (ex) {
			logger.error('Error importing orders:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// #endregion Methods

	// #region EventHandlers

	async onFileLoad(e) {
		if (e.target.files.length < 1) {
			messageBox('please select file');
			return;
		}

		var file = e.target.files[0];
		if (!(/.*\.(xlsx|csv)$/i).test(file.name)) {
			messageBox('Invalid file name, must have xlsx/csv extension');
			return;
		}

		var mb = messageBox('Loading...');
		var mp = this.form.getData()?.marketplace;
		var orders = await readOrdersFromFile(file, mp, this.catalog);
		var noErrors = this.validateOrders(orders);
		if (noErrors) {
			mb.close();
		} else {
			mb.setTitle('Some orders have errors, please fix');
		}
	}

	async onImport(extraData) {
		if (!extraData) { return; }

		if (!extraData.dispatch) {
			var res = await messageBox('Skip dispatch to warehouse ?', 'If you continue orders will NOT be sent to the users!', [messageBox.Buttons.Cancel, 'Continue Without dispatch']).promise;
			if (res === messageBox.Buttons.Cancel) {return;}
		}

		this.sendImportRequest(extraData);
	}

	onCopySummary() {
		var res = utils.copyElementToClipboard(document.getElementById('importSummary'));
		snackbar(res ? 'Copied to clipboard' : 'Error copying to clipboard');
	}

	// #endregion EventHandlers

	// #region Lifecycle

	async componentDidMount() {
		var mb = messageBox('Loading data...', null, null, true);
		try {
			await this.loadCatalog();
			await this.loadPOS();
			this.setState({ready: true});
			mb.close();
		} catch (ex) {
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// #endregion Lifecycle

	// #region Render

	renderSummary() {
		var { summary, batchId, importErrors, importedOrders } = this.state;
		return (
			<React.Fragment>
				<div>
					<i className="material-icons" title="Copy" data-oper="CopyID" onClick={this.onCopySummary}>content_copy</i>
				</div>
				<div id="importSummary">
					<div className="section">
						<div># Orders: {summary.orders}</div>
						<div>Amount: {summary.amount}</div>
						<div>Discount: {summary.discount}</div>
						<div>Shipping: {summary.shipping}</div>
						<div className="bold">Total: {summary.total}</div>
					</div>
					<div className="section">
						{Object.keys((summary.skus || {})).map(sku => <div key={sku}>sku: {sku} qty: {summary.skus[sku]}</div>)}
					</div>

					{batchId &&
						<div className="section">
							<div className="bold">Batch: {batchId}</div>
						</div>
					}

					{importErrors.length > 0 &&
						<div className="section">
							<div>Errors:</div>
							{importErrors.map((e, i) => <div key={i}>{e}</div>)}
						</div>
					}

					{importedOrders.length > 0 &&
						<div className="section">
							<div>Imported Orders:</div>
							{importedOrders.map((e, i) => <div key={i}>{e}</div>)}
						</div>
					}
				</div>
			</React.Fragment>
		);
	}

	renderForm() {
		var posEnums = [{const: '', title: '---'}].concat(this.pos.map(p => ({ const: p.id, title: p.name })));
		var mpEnums = Object.keys(CONSTANTS.Marketplace).map(mp => ({ const: CONSTANTS.Marketplace[mp], title: mp }));
		var schema = compileSchema({
			type: 'object',
			required: ['email'],
			properties: {
				email: { type: 'string', format: 'email', minLength: 1 },
				marketplace: { type: 'string', oneOf: mpEnums },
				pos: { type: 'string', oneOf: posEnums },
				dispatch: { type: 'boolean' },
				service: {
					type: 'string',
					oneOf: [
						{ enum: ['Default'], title: 'Default' },
						{ enum: ['Surepost'], title: 'UPS - Surepost' },
						{ enum: ['Ground'], title: 'UPS - Ground' },
						{ enum: ['SecondDay'], title: 'UPS - 2nd Day Air' },
						{ enum: ['NextDay'], title: 'UPS - Next Day Air' }
					],
					default: 'Default'
				}
			}
		});

		return (
			<Form
				ref={f => { this.form = f; }}
				schema={schema}
				formData={this.state.extraData}
				showButtons={false}
				fullWidth={false}
				onChange={formData => { this.setState({ extraData: formData}); }}
				submit={this.onImport}
			/>
		);
	}

	render() {
		var { ready, orders } = this.state;
		if (!ready) { return ''; }
		return (
			<BasePage title="Import Orders" className="import-orders-page">
				<div className="tools">
					{this.renderForm()}
					<div>
						<input type="file" onInput={this.onFileLoad} accept=".xlsx,.csv" />
						<Button variant="contained" color="primary" disabled={!this.state.canImport} onClick={() => this.form && this.form.submit()}>Import</Button>
					</div>
				</div>
				<div className="orders">
					<div className="grid">
						<Grid
							columns={OrdersGridHelper.getColumns()}
							rows={orders}
							rowHeight={OrdersGridHelper.rowHeight}
							headerRowHeight={OrdersGridHelper.headerRowHeight}
						/>
					</div>
					<div className="summary">
						{this.renderSummary()}
					</div>
				</div>
			</BasePage>
		);
	}

	// #endregion Render
}

export default ImportOrdersPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { BasePage } from 'app-center-common';
import { messageBox, ToolbarIcon, JSONModal } from 'ui-core';
import { logger, api } from 'client-services';
import MembershipsGrid from './memberships-grid.jsx';
import EventDetails from './event-details.jsx';

import './member-page.css';

const UUID_REGEX = /^UUID\("([0-9a-f-]+)"\)$/;

class MemberPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			memberships: null,
			events: null,
			sortDir: -1,
			memsSortDir: 1,
			selectedEvent: null
		};

		// this.membershipColumns = MembershipGridHelper.getColumns();
	}

	// #region Methods

	sortByCreatedAt(events, dir) {
		events.sort((a, b) => {
			a = new Date(a.createdAt).getTime();
			b = new Date(b.createdAt).getTime();
			return dir * (a - b);
		});
	}

	// #endregion Methods

	// #region Lifecycle

	async componentDidMount() {
		var id = this.props.params.userId;
		if (!id) {
			return;
		}

		// we want to also support urls like /member/UUID("xxx")
		let m = id.match(UUID_REGEX);
		if (Array.isArray(m) && m.length > 1) {
			id = m[1];
		}

		logger.trace('Fetching member info, user:', id);
		let mb = messageBox('Fetching user memberships...', '', null, true);
		try {
			let res = await api.membership.getMembershipReport({q: `owner:${id}`}).send();
			logger.trace('Got memberships:', res);
			let memberships = res.memberships;

			if (!Array.isArray(memberships) || memberships.length < 1) {
				mb.setTitle('Oops', false);
				mb.setBody('This user has no memberships...');
				return;
			}

			this.sortByCreatedAt(memberships, this.state.memsSortDir);

			res = await api.membership.getMemberHistory({user: id}).send();
			logger.trace('Got membership history:', res);
			let events = res.events;
			events = events || [];
			this.sortByCreatedAt(events, this.state.sortDir);

			let email = memberships[0].email;

			this.setState({ email, memberships, events });

			mb.close();
		} catch (ex) {
			logger.error('Error fetching order:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	// #endregion Lifecycle

	// #region Render

	renderMemberships(memberships) {
		// show X rows + header + scrollbar height
		let rows = Math.min(3, memberships.length);
		// let height = MembershipGridHelper.rowHeight * rows + MembershipGridHelper.headerRowHeight + 30;
		return (
			<div className="section">
				<div className="title">Memberships</div>
				<MembershipsGrid
					rows={rows}
					memberships={memberships}
				/>
			</div>
		);
	}

	renderMembershipEvents(events) {
		return (
			<div className="section">
				<div className="title">
					<span>Events</span>
					<ToolbarIcon name={this.state.sortDir === 1 ? 'north' : 'south'} onClick={() => {
						let sortDir = -1 * this.state.sortDir;
						let events = [...this.state.events];
						this.sortByCreatedAt(events, sortDir);
						this.setState({ events, sortDir });
					}} />
				</div>
				<div>
					{events.map(e => <EventDetails key={e.id} event={e} onClick={() => this.setState({selectedEvent: e})} />)}
				</div>
			</div>
		);
	}

	render() {
		let { email, memberships, events } = this.state;
		if (!memberships) { return 'Loading...'; }

		return (
			<BasePage
				className="member-page"
				title="Member Page"
				subTitle={`(${email})`}
			>
				<div className="title">{ email }</div>
				{this.renderMemberships(memberships)}
				{this.renderMembershipEvents(events)}
				<JSONModal
					open={!!this.state.selectedEvent}
					data={this.state.selectedEvent}
					onClose={() => this.setState({selectedEvent: null})}
				/>
			</BasePage>
		);
	}

	// #endregion Render
}

MemberPage.propTypes = {
	params: PropTypes.object
};

export default function WrappedMemberPage(props) {
	let params = useParams();
	return <MemberPage params={params} {...props} />;
};

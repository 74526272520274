import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'ui-core';
import MembershipGridHelper from './membership-grid-helpers.jsx';
import arraySort from '../../lib/arraySort.js';

function MembershipsGrid(props) {
	let { style = {}, rows = 0, ignoreCols } = props;
	let [memberships, setMemberships] = useState(props.memberships);
	let [sortDir, setSortDir] = useState(1);

	if (rows > 0) {
		let height = MembershipGridHelper.rowHeight * rows + MembershipGridHelper.headerRowHeight + 30;
		style.height = style.height || height;
	}

	return (
		<div style={style}>
			<Grid
				columns={MembershipGridHelper.getColumns(ignoreCols)}
				rows={memberships}
				rowHeight={MembershipGridHelper.rowHeight}
				headerRowHeight={MembershipGridHelper.headerRowHeight}
				sortColumn="startDate"
				sortDirection={sortDir}
				onSort={(col, dir) => {
					arraySort(memberships, dir, col);
					setMemberships([...memberships]);
					setSortDir(dir);
				}}
			/>
		</div>
	);
}

MembershipsGrid.propTypes = {
	style: PropTypes.object,
	rows: PropTypes.number,
	memberships: PropTypes.arrayOf(PropTypes.object),
	ignoreCols: PropTypes.arrayOf(PropTypes.string)
};

export default MembershipsGrid;

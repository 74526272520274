.membership-info-page {
	.title {
		font-size: 1.5em;
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
	}

	.section {
		padding: 10px;
		background-color: #f7f6f6;
		border-radius: 10px;
		margin-top: 20px;

		.title {
			margin-bottom: 10px;
			font-size: 1.2em;
			color: #605f5f;
		}
	}
}

/* .member-page .section .title > i {
    vertical-align: middle;
    font-size: 16px;
    margin-left: 10px;
} */

import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { Link } from 'react-router-dom';
import { GridFormatters } from 'ui-core';
import { AmplitudeLink, UserSpotlightLink } from '../common-grid-links.jsx';

var UsersGridHelper = {
	rowHeight: 45,
	headerRowHeight: 35
};

UsersGridHelper.getColumns = function getColumns() {
	return [
		{
			key: 'id@80',
			formatter: idFormatter
		},
		{
			key: 'email@s',
			formatter: emailFormatter
		},
		'username',
		{
			key: 'status@80',
			formatter: GridFormatters.lookup(CONSTANTS.UserStatus, true)
		},
		'name@80',
		{
			key: 'lastLoginDate@120s',
			name: 'Last Login',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'ug@100',
			name: 'UG',
			formatter: GridFormatters.lookup(CONSTANTS.UserGroup, true)
		},
		{
			key: 'membership@100',
			formatter: membershipFormatterWrap,
		},
		{
			key: 'createdAt@120s',
			name: 'Created',
			formatter: GridFormatters.datetime(true, false, true)
		},
		{
			key: 'avatar@50-r',
			name: '',
			formatter: GridFormatters.image({maxHeight: UsersGridHelper.rowHeight})
		},
		{
			key: 'spotlight@50-r',
			name: '',
			formatter: userSpotlightRenderer,
		},
		{
			key: 'amp@50-r',
			name: '',
			formatter: amplitudeRenderer,
		},
	];
};

function amplitudeRenderer({ row }) {
	return <AmplitudeLink userGUID={row.id} />;
}

function userSpotlightRenderer({ row }) {
	return <UserSpotlightLink userGUID={row.id} />;
}

function membershipFormatterWrap({row}) {
	let { membership, membershipAccess, id } = row;

	let invited = (typeof membershipAccess?.isOwner !== 'undefined' && !membershipAccess.isOwner);
	let invitedLink = <Link to={`/membership/${membershipAccess?.membershipId}`} target="_blank" onClick={e => e.stopPropagation()}>Invited</Link>;

	let ownerLink = <Link to={`/member/${id}`} target="_blank" onClick={e => e.stopPropagation()}>{membership?.state}</Link>;
	return (
		<div style={{ textAlign: 'center' }}>
			{invited && <div>{invitedLink}</div>}
			{!invited && membership?.provider && <div>{ownerLink}</div>}
			{!invited && membership?.provider && <div style={{fontSize: '0.8em'}}>{membership.provider}</div>}
		</div>
	);
}

function emailFormatter(data) {
	var style = null;
	let emailVerified = data.row?.emailVerified;
	if (typeof emailVerified !== 'undefined' && !emailVerified) {
		style = {color: 'grey'};
	}

	return GridFormatters.email(style)(data);
}

function idFormatter(data) {
	var style = null;
	let ug = data.row?.ug;
	if (typeof ug !== 'undefined' && ug === 0) {
		style = {color: 'grey'};
	}

	return GridFormatters.copyToClipboard(style)(data);
}

export default UsersGridHelper;

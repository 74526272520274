var uiSchema = {
	marketplaceOrderId: {
		'ui:title': 'Marketplace Order ID (if applicable)',
		'ui:placeholder': '112-115598-98785'
	},
	pos: {
		'ui:title': 'Point of Sale'
	},
	invoice: {
		discount: {
			'ui:placeholder': 'Discount amount',
			'ui:help': 'The TOTAL discount amount per the ENTIRE order'
		}
	}
};

export default uiSchema;

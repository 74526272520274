import React from 'react';
import { Link } from 'react-router-dom';
import { GridFormatters } from 'ui-core';
import { AmplitudeLink } from '../common-grid-links.jsx';
import CreationViews from './creation-views.jsx';

import { config, utils } from 'client-services';

var ProjectsGridHelper = {
	rowHeight: 75,
	headerRowHeight: 35
};

ProjectsGridHelper.getColumns = function getColumns() {
	var ccUrl = config.get('data:ccBaseUrl').replace(/\/$/, '');
	return [
		{
			key: 'thumbnail',
			width: ProjectsGridHelper.rowHeight + 16, // compensate for cell padding
			formatter: imageRender
		},
		{
			key: 'email',
			formatter: GridFormatters.email()
		},
		{
			key: 'membership@100',
			formatter: membershipFormatterWrap,
		},
		{
			key: 'experience',
			formatter: GridFormatters.link(`${ccUrl}/experience/%s`, {displayProp: 'name', target: '_blank'}),
		},
		{
			key: 'progress',
			name: 'Last Pos',
			formatter: completionRender
		},
		{
			key: 'creationTime',
			formatter: creationTimeRender
		},
		{
			key: 'createdAt@s',
			formatter: GridFormatters.datetime()
		},
		{
			key: 'lastUpdate@s',
			formatter: GridFormatters.datetime()
		},
		'status@',
		{
			key: 'id@80',
			formatter: GridFormatters.copyToClipboard(),
		},
		{
			key: 'amp@50-r',
			name: 'Amplitude',
			formatter: amplitudeRenderer,
		},
	];
};

function amplitudeRenderer({ row }) {
	return <AmplitudeLink userGUID={row.owner} />;
}

function membershipFormatterWrap({ row }) {
	let membership = row.membership || {};
	if (Object.keys(membership).length < 1) {
		return null;
	}

	return (
		<div style={{textAlign: 'center'}}>
			<div>{ membership.isOwner ? 'Owner' : 'Invited' }</div>
		</div>
	);
}

function completionRender({ row }) {
	var completed = row.currentSessionPosition;
	var duration = row.currSessionDuration;
	var completion = Math.min(100, Math.floor(completed / duration * 100)) + '%';
	var viewMins = Math.round(completed / 60);
	var durMins = Math.round(duration / 60);
	return (
		<div className="progress-wrap">
			<div className="progress" style={{marginTop: '2px'}}>
				<div className="progress-bar" role="progressbar" style={{width: completion}} />
				<span>{viewMins} / {durMins} Mins</span>
			</div>
		</div>
	);
}

function imageRender({ row }) {
	var { id, thumbnail } = row;
	return (
		<Link to={'/project/' + id} target="_blank" >
			<img src={thumbnail} style={{maxHeight: ProjectsGridHelper.rowHeight, maxWidth: ProjectsGridHelper.rowHeight, verticalAlign: 'middle', padding: '2px'}} />
		</Link>
	);
}

function creationTimeRender({ row }) {
	var { id, totalCreationTime, creationViews } = row;
	let duration = '';
	if (typeof totalCreationTime === 'number' && !isNaN(totalCreationTime)) {
		duration = utils.secondsToString(totalCreationTime);
	}

	return (
		<div>
			{duration && <div>{duration}</div>}
			<div onClick={() => CreationViews.show(id)} style={{textDecoration: 'underline', cursor: 'pointer'}}>
				({creationViews || 0} sessions)
			</div>
		</div>
	);
};

export default ProjectsGridHelper;

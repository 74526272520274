import { utils } from 'client-services';
import CONSTANTS from '../../../lib/constants.js';
import CSVUtils from '../../../lib/csv-utils.js';

// Convert the order we get from Grommet to a standard csv order we expect.
function parseGrommetCSVOrder(gmtCSVOrder) {
	var res = {
		id: (gmtCSVOrder['shipment#'] || '').toString(),
		name: gmtCSVOrder.customer || '',
		address1: gmtCSVOrder.street || '',
		city: gmtCSVOrder.city || '',
		state: gmtCSVOrder.state || '',
		country: gmtCSVOrder.country || 'US',
		zip: gmtCSVOrder.zip || '',
		quantity: gmtCSVOrder.quantity
	};

	var sku = gmtCSVOrder['supplier sku'] || '';
	if (sku.startsWith('CUPIXEL-')) {
		sku = sku.replace('CUPIXEL-', '');
	}

	res.sku = sku;
	return res;
}

function csvOrderToCPXOrder(csvOrder, marketplace, catalog) {
	var res = {
		errors: [],
		id: csvOrder.id || '',
		items: [],
		shipping: { address: {} },
		invoice: {}
	};

	// email
	var email = (csvOrder.email || '');
	if (email !== '' && !utils.isEmail(email)) {
		res.errors.push('Invalid email: ' + email);
		email = '';
	}

	// SKU
	var sku = (csvOrder.sku || '').toString();
	if (typeof catalog[csvOrder.sku] === 'undefined') {
		res.errors.push('Invalid SKU');
		sku = '';
	}

	// Quantity
	var quantity = parseInt(csvOrder.quantity);
	if (isNaN(quantity) || quantity !== parseFloat(csvOrder.quantity) || quantity < 1) {
		res.errors.push('Invalid quantity');
		sku = '';
	}

	if (sku) {
		res.items = [{ sku, quantity, price: catalog[csvOrder.sku].price, description: catalog[csvOrder.sku].desc }];
	}

	// Address
	['name', 'address1', 'city', 'state', 'zip', 'country'].forEach(f => {
		if ((typeof csvOrder[f] !== 'string' && typeof csvOrder[f] !== 'number') || !csvOrder[f]) {
			res.errors.push(`Invalid ${f}`);
			return;
		}

		csvOrder[f] = csvOrder[f].toString().trim();

		if (f === 'country') {
			if (csvOrder[f].length !== 2) {
				res.errors.push('Invalid Country');
				return;
			}

			csvOrder[f] = csvOrder[f].toUpperCase();
		}

		res.shipping.address[f] = csvOrder[f];
	});

	res.shipping.address.address2 = (typeof csvOrder.address2 === 'string' || typeof csvOrder.address2 === 'number') ? csvOrder.address2.toString() : '';

	// Invoice
	res.invoice.amount = utils.toMoney(res.items.map(i => i.price * i.quantity).reduce((x, y) => x + y, 0));

	// shipping cost
	res.invoice.shipping = csvOrder.shipping ? utils.toMoney(parseFloat(csvOrder.shipping)) : 0;
	if (isNaN(res.invoice.shipping) || res.invoice.shipping < 0) {
		res.errors.push('Invalid shipping cost');
		res.invoice.shipping = 0;
	}

	// Discount
	res.invoice.discount = csvOrder.discount ? utils.toMoney(parseFloat(csvOrder.discount)) : 0;
	if (isNaN(res.invoice.discount) || res.invoice.discount < 0) {
		res.errors.push('Invalid discount');
		res.invoice.discount = 0;
	}

	res.invoice.total = utils.toMoney(res.invoice.amount + res.invoice.shipping - res.invoice.discount);
	return res;
}

async function readOrdersFromFile(file, marketplace, catalog) {
	var csvOrders = await CSVUtils.readCSVFile(file);
	if (marketplace === CONSTANTS.Marketplace.Grommet) {
		csvOrders = csvOrders.map(parseGrommetCSVOrder);
	}

	csvOrders = csvOrders.map(o => csvOrderToCPXOrder(o, marketplace, catalog));
	return csvOrders;
}

export { readOrdersFromFile };

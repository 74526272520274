.member-page > .title {
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.member-page .section {
    padding: 10px;
    background-color: #f7f6f6;
    border-radius: 10px;
    margin-top: 20px;
}

.member-page .section .title {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #605f5f;
}
.member-page .section .title > i {
    vertical-align: middle;
    font-size: 16px;
    margin-left: 10px;
}

.member-page .section .event {
    margin: 15px 0px;
    display: flex;
    flex-direction: row;
}

.member-page .section .event .icon {
    margin-right: 10px;
}

.member-page .section .event .icon > i {
    cursor: pointer;
}

.member-page .section .event .icon.good > i {
    color: #11d483;
}

.member-page .section .event .icon.warn > i {
    color: orange;
}

.member-page .section .event .icon.bad > i {
    color: #f2545b;
}

.member-page .section .event .details {
    color: #767676;
    font-size: 0.95em;
    padding-left: 5px;
}

.member-page .section .event .details > div {
    margin-top: 2px;
}

.member-page .section .event .processed-on {
    font-size: 0.95em;
}

.member-page .section .event .processed-on .error {
    color: red;
}
.import-orders-page {
	display: flex;
	flex-direction: column;
}

.import-orders-page .tools {
	display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.import-orders-page .orders {
	flex: 1;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	overflow: auto;
}

.import-orders-page .orders .grid {
	flex: 0.7;
}

.import-orders-page .orders .summary {
	flex: 0.3;
	overflow: auto;
	margin-left: 10px;
	white-space: nowrap;
}

.import-orders-page .orders .summary i:hover {
	cursor: pointer;
}

.import-orders-page .orders .summary .section {
	margin-bottom: 10px;
}

.import-orders-page .orders .summary .bold {
	font-weight: bold;
}

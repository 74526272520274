import React from 'react';
import CONSTANTS from '../../lib/constants.js';
import { messageBox, snackbar } from 'ui-core';
import ReportPage from '../report-page/report-page.jsx';
import UsersGridHelper from './users-grid-helpers.jsx';
import { utils, logger, api } from 'client-services';

const ROLES = ['app:su', 'app:instructor'];
let SEARCH_FIELDS = {
	email: {},
	username: {},
	name: {},
	id: { format: 'uuid' },
};

class UsersGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: null
		};

		this.reportPage = null;
		this.columns = UsersGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
	}

	// #region Methods

	async loadMore(count, skip, sort, search, showAllUsers) {
		logger.trace('Fetching users, count: %s, skip: %s:', count, skip);
		var params = {
			count,
			skip,
			sort,
			q: search + (showAllUsers ? '' : ' +ug:1 +ug:2')
		};

		logger.trace('Fetching users, params:', params);
		let res = await api.user.getUsers(params).send();
		logger.trace('Got users:', res);
		return { rows: res.users, total: res.total };
	}

	async registerUser() {
		let schema = {
			type: 'object',
			required: ['email', 'yearOfBirth'],
			properties: {
				email: { type: 'string', format: 'email' },
				yearOfBirth: { type: 'integer', minimum: 1900, maximum: new Date().getUTCFullYear() },
				parentId: {type: 'string', format: 'uuid'}
			}
		};

		let res = await messageBox.promptForm('Register User', {}, schema, [messageBox.Buttons.Cancel, 'Register']);
		if (res.result !== 'Register') { return; }

		logger.trace('Registering new user, params:', res.data);
		let mb = messageBox('Registering user...', '', '', true);
		try {
			await api.auth.registerUser(res.data).send();
			mb.close();
			snackbar('User registered');
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error registering user:', ex);
			mb.setTitle('Error registering user');
			mb.setBody(ex.message, false);
		}
	}

	updateUser(params) {
		var mb = messageBox('Updating user...', '', '', true);
		api.user.updateUser(params).send().then(res => {
			mb.close(10);
			snackbar('User updated');
			this.reportPage.reset();
		}).catch(e => {
			logger.error('Error updating user:', e);
			mb.setTitle('Error updating user');
			mb.setBody(e.message, false);
		});
	}

	updateUserSettings(id, category, settings) {
		var params = {id, cat: category, settings};
		var mb = messageBox('Updating user settings...', '', '', true);
		api.user.setUserSettings(params).send().then(res => {
			mb.close(10);
			snackbar('User settings updated');
			this.reportPage.reset();
		}).catch(e => {
			logger.error('Error updating user settings:', e);
			mb.setTitle('Error updating user settings');
			mb.setBody(e.message, false);
		});
	}

	onToggleMembership(user, action) {
		if (!user) { return; }

		if (action === 'addMembership') {
			this.onAddMembership(user);
		} else if (action === 'unSubscribe') {
			this.onRemoveUserMembership(user);
		}
	}

	async onAddMembership(user) {
		if (!user) { return; }

		let schema = {
			type: 'object',
			properties: {
				expiration: {type: 'string', format: 'date'}
			}
		};

		let res = await messageBox.promptForm('Add Membership', {}, schema, [messageBox.Buttons.Cancel, 'Add']);
		if (res.result !== 'Add') { return; }
		let expirationDate = res.data?.expiration;

		var mb = messageBox('Adding user membership...', '', '', true);
		var params = { userId: user.id, provider: CONSTANTS.SubscriptionProviders.System, expirationDate };
		try {
			await api.membership.addMembership(params).send();
			mb.setTitle('User is now a member...', false);
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error adding user membership:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	async onRemoveUserMembership(user) {
		if (!user) { return; }
		let force = false;
		if (user.membership?.provider && user.membership?.provider !== CONSTANTS.SubscriptionProviders.System) {
			let msg = <div>User is subscribed with {user.membership.provider}.<br />Are you sure you want to remove his membership?<br />NOTE: This will NOT cancel the user subscription on Apple/Google !</div>;
			let res = await messageBox('WARNING', msg, [messageBox.Buttons.Cancel, 'Remove Membership']).promise;
			if (res === messageBox.Buttons.Cancel) {
				return;
			}

			force = true;
		}

		var mb = messageBox('UnSubscribe user...', '', '', true);

		try {
			await api.membership.removeUserMembership({ id: user.id, provider: CONSTANTS.SubscriptionProviders.System, force }).send();
			mb.setTitle('User unsubscribed successfully...', false);
			this.reportPage.reset();
		} catch (ex) {
			logger.error('Error unSubscribe user:', ex);
			mb.setTitle('Error', false);
			mb.setBody(ex.message);
		}
	}

	setUserProp(user, prop, value) {
		if (!user) { return; }

		var params = {
			id: user.id,
			[prop]: value
		};

		this.updateUser(params);
	}

	onSetPincode(user) {
		if (!user || !user.email) {
			messageBox('Invalid user selected or he has no email');
			return;
		}

		var mb = messageBox('Setting pincode...', '', '', true);
		var params = {email: user.email};
		api.auth.setUserPincode(params).send().then(res => {
			mb.setProgress(false);
			if (!res.authPermit || !res.authPermit.extraData || !res.authPermit.extraData.pinCode) {
				mb.setTitle('Invalid response - missing pincode');
			} else {
				mb.setTitle(`Pincode: ${res.authPermit.extraData.pinCode}`);
			}
		}).catch(e => {
			logger.error('Error setting pincode:', e);
			mb.setTitle('Error setting pincode', false);
			mb.setBody(e.message);
		});
	}

	async onUserRoles(user) {
		if (!user || !user.email || !user.id) {
			messageBox('Invalid user selected or he has no email');
			return;
		}

		var schema = {
			type: 'object',
			properties: {
				roles: {
					type: 'array',
					items: { type: 'string', enum: ROLES },
					uniqueItems: true
				}
			}
		};

		let uiSchema = { roles: { 'ui:widget': 'checkboxes' } };

		let formData = { roles: user.roles.filter(r => ROLES.includes(r)) };
		let res = await messageBox.promptForm('User Roles', formData, schema, [messageBox.Buttons.Cancel, 'Save'], uiSchema);
		if (res.result !== 'Save') { return; }

		let roles = res.data.roles;
		logger.trace('updating user roles, params:', roles);
		let mb = messageBox('Updating user...', '', '', true);
		try {
			await api.user.setUserRoles({id: user.id, roles}).send();
			mb.close();
			snackbar('User updated');
			this.reportPage.reset();
		} catch (e) {
			logger.error('Error updating roles:', e);
			mb.setTitle('Error updating roles');
			mb.setBody(e.message, false);
		}
	}

	async onUserSettings(user) {
		if (!user || !user.email) {
			messageBox('Invalid user selected or he has no email');
			return;
		}

		var defaultSettings = {currentProject: '', defaultKitVersion: ''};
		var settingsRes = await api.user.getUserSettings({id: user.id, cat: 'general'}).send();
		var settings = utils.extend(true, {}, defaultSettings, settingsRes.settings);

		var res = await messageBox.prompt('User App Settings', Object.keys(settings), [messageBox.Buttons.Cancel, messageBox.Buttons.OK], Object.values(settings));
		if (res.result === messageBox.Buttons.Cancel) {return;}
		settings = res.values;
		this.updateUserSettings(user.id, 'general', settings);
	}

	// #endregion Methods

	// #region Render

	renderGridToolbar(user) {
		var items = [];
		if (!user) {
			items.push({ key: 'addUser', title: 'Register User', onClick: () => this.registerUser(), icon: 'person_add'});
			return items;
		}

		if (user.status === CONSTANTS.UserStatus.Active) {
			items.push({key: 'block', title: 'Lock', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Locked), icon: 'person_off'});
		} else if (user.status === CONSTANTS.UserStatus.Locked) {
			items.push({key: 'unblock', title: 'UnLock', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Active), icon: 'person'});
		} else if (user.status === CONSTANTS.UserStatus.Deleted) {
			items.push({key: 'undelete', title: 'UnDelete', onClick: () => this.setUserProp(user, 'status', CONSTANTS.UserStatus.Active), icon: 'restore_from_trash'});
		}

		if (user.membershipAccess?.isOwner) {
			items.push({key: 'unsubscribe', title: 'UnSubscribe', onClick: () => this.onToggleMembership(user, 'unSubscribe'), icon: 'credit_card_off'});
		} else {
			items.push({key: 'addMembership', title: 'Add Membership', onClick: () => this.onToggleMembership(user, 'addMembership'), icon: 'add_card'});
		}

		if (user.ug === CONSTANTS.UserGroup.Ignore) {
			items.push({key: 'unignore', title: 'Unignore', onClick: () => this.setUserProp(user, 'ug', CONSTANTS.UserGroup.Regular), icon: 'visibility'});
		} else {
			items.push({key: 'ignore', title: 'Ignore user', onClick: () => this.setUserProp(user, 'ug', CONSTANTS.UserGroup.Ignore), icon: 'visibility_off'});
		}

		items.push({key: 'pincode', title: 'Set Auth Pincode', onClick: () => this.onSetPincode(user), icon: 'fiber_pin'});
		items.push({key: 'roles', title: 'User Roles', onClick: () => this.onUserRoles(user), icon: 'category'});
		// FFU
		// items.push({key: 'settings', title: 'User App Settings', onClick: () => this.onUserSettings(user), icon: 'settings'});

		return items;
	}

	render() {
		return (
			<ReportPage
				ref={e => { this.reportPage = e; }}
				loadMore={this.loadMore}
				columns={this.columns}
				title="Users"
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				renderGridToolbar={(...args) => this.renderGridToolbar(...args)}
				gridProps={{
					rowHeight: UsersGridHelper.rowHeight,
					headerRowHeight: UsersGridHelper.headerRowHeight
				}}
			/>
		);
	}

	// #endregion Render

	// render() {
	// 	return (
	// 		<Grid
	// 			ref={g => {this.grid = g;}}
	// 			columns={this.columns}
	// 			keyCol="id"
	// 			pageSize={30}
	// 			headerRowHeight={UsersGridHelper.headerRowHeight}
	// 			rowHeight={UsersGridHelper.rowHeight}
	// 			selectOnClick={true}
	// 			toolbarItems={this.renderToolbar()}
	// 			onLoadMore={this.props.loadMore}
	// 			onSelectionChange={this.onSelectionChange}
	// 			onSort={this.onSort}
	// 		/>
	// 	);
	// }
}

export default UsersGrid;

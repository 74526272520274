import React, {useState, useEffect} from 'react';
import { BasePage } from 'app-center-common';
import ReactJson from 'react18-json-view';
import { ToolbarIcon } from 'ui-core';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { api } from 'client-services';

import 'react18-json-view/src/style.css';

var useClasses = makeStyles()(theme => ({
	input: {
		padding: '5px'
	}
}));

function filterObject(obj, filter) {
	if (!filter) { return obj; }
	var res = {};
	for (var key in obj) {
		var val = obj[key];
		var keyMatch = (key.toLowerCase().indexOf(filter) >= 0);

		// if key match filter we include it all
		if (keyMatch) {
			res[key] = val;
			continue;
		}

		// Filter subtree, include only if it has kids
		if (typeof val === 'object') {
			var o = filterObject(val, filter);
			if (Object.keys(o).length > 0) {
				res[key] = o;
			}
		}
	}

	return res;
}

function AppConfigPage() {
	var [origConfig, setOrigConfig] = useState({});
	var [appConfig, setAppConfig] = useState({});
	var [collapsed, setCollapsed] = useState(2);
	var [filter, setFilter] = useState('');
	var { classes } = useClasses();

	useEffect(() => {
		api.app.config().send().then(res => {
			setAppConfig(res);
			setOrigConfig(res);
		});
	}, []);

	function onFilter(event) {
		var val = event.target.value;
		setFilter(val);
		var newConf = filterObject(origConfig, val);
		setAppConfig(newConf);
	}

	function renderToolbar() {
		return (
			<div>
				<ToolbarIcon name="expand_less" size="medium" title="Collapse All" onClick={() => setCollapsed(1)} />
				<ToolbarIcon name="expand_more" size="medium" title="Expand All" onClick={() => setCollapsed(-1)} />
				<TextField value={filter} onChange={onFilter} variant="outlined" size="small" placeholder="Filter..." InputProps={{classes: {input: classes.input}}} />
			</div>
		);
	}

	return (
		<BasePage title="App Config" toolbar={renderToolbar()} toolbarOpen={true}>
			<ReactJson src={appConfig} collapsed={collapsed < 0 ? false : collapsed} displaySize={true} />
		</BasePage>
	);
}

export default AppConfigPage;

export {default as Orders} from './orders/orders-grid.jsx';
export {default as CouponsPage} from './coupons/coupons-page.jsx';
export {default as OrderPage} from './orders/order-page.jsx';
export {default as AddOrderPage} from './orders/add-order-page.jsx';
export {default as POSPage} from './pos/pos-page.jsx';
export {default as SalesReport} from './sales-report/sales-report.jsx';
export {default as ShippingReport} from './shipping-report/shipping-report.jsx';
export {default as BuyersReport} from './buyers-report/buyers-report.jsx';
export {default as ImportOrdersPage} from './import-orders/import-orders-page.jsx';
export {default as ImportShipmentsPage} from './import-shipments/import-shipments-page.jsx';

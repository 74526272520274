import React from 'react';
import ReportPage from '../report-page/report-page.jsx';
import ProjectsGridHelper from './projects-grid-helpers.jsx';
import { api, logger } from 'client-services';

let SEARCH_FIELDS = {
	experience: {$ref: '#/definitions/MongoObjectID', description: 'experience id'},
	owner: {format: 'uuid', description: 'user id'},
	email: {},
	id: {$ref: '#/definitions/MongoObjectID', description: 'project id'},
};

class ProjectsPage extends React.Component {
	constructor(props) {
		super(props);

		this.columns = ProjectsGridHelper.getColumns();

		this.loadMore = this.loadMore.bind(this);
	}

	async loadMore(count, skip, sort, search, showAllUsers) {
		var params = {
			count,
			skip,
			sort,
			q: search + (showAllUsers ? '' : ' +ug:1 +ug:2')
		};

		logger.trace('Fetching projects, params:', params);
		let res = await api.project.getProjectsReport(params).send();
		logger.trace('Got projects:', res);
		return { rows: res.projects, total: res.total };
	}

	render() {
		return (
			<ReportPage
				loadMore={this.loadMore}
				columns={this.columns}
				title="Projects"
				showSearch={true}
				searchFields={SEARCH_FIELDS}
				gridProps={{
					rowHeight: ProjectsGridHelper.rowHeight,
					headerRowHeight: ProjectsGridHelper.headerRowHeight
				}}
			/>
		);
	}
}

export default ProjectsPage;
